"use strict";

import React from "react";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import SubscriptionResource from './../../Tean/Subscription/Subscription';


/**
 * 
 * - Start polling to make initial subscription
 * - Recieve list resource with lists and end poll
 * - End poll and make subscription to DEFAULT list if there is no active Task subscriptions
 *   and try to dedect which lists should be selected on UI side
 * - after that user seleted lists are accepted
 * 
 * If there is only one list and it's market as DEFAULT - hide selection
 * 
 */
class TicketListSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      "initialPossibleOptionsRecieved" : false,
      "ticketListOptions" : this.getCurrentOptions(),
      "ticketListShow" : false,
      "selectedTicketListKeys" : this.props.selectedTicketListKeys
    };
    
    this.initialSubscriptionRequested = false;
    this.initialSubscriptionFinished = false;

    this.timerFirstListResourceRecieved = null;
    this.timerRequestOptionsUpdate = null;

    this.handleTestClick = this.handleTestClick.bind( this );
    this.handleListSelectToggle = this.handleListSelectToggle.bind( this );
    this.handleShowDialog = this.handleShowDialog.bind( this );
  }


  componentDidMount() {
    this._isMounted = true;
    var that = this;

    var updateTicketLists = function (eventPayLoad) {
      if (that._isMounted) {
        that.requestUpdatePossibleOptions();
      }
    };

    this.startFirstListRecievedPoll();
    
    window.appEventsEmitter.addListener("ServerCommunicationHandeled", updateTicketLists);
    this.listenerUpdateTicketLists = updateTicketLists;
  }


  componentWillUnmount() {
    window.appEventsEmitter.removeListener(
      "ServerCommunicationHandeled",
      this.listenerUpdateTicketLists
    );
    if( this.timerFirstListResourceRecieved != null ){
      clearTimeout( this.timerFirstListResourceRecieved );
      this.timerFirstListResourceRecieved = null;
    }
  }


  handleTestClick(){
    return;

    console.log('SUBS:ACTIVE:');
    console.log( this._findTaskSubscriptions( "active" ) );
    let currentSubcriptions = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "Subscription"
    } );

    //for( var j in currentSubcriptions ){
    //  currentSubcriptions[ j ].setStatus( 'off' );
    //  window.appEntityStore.saveResource( currentSubcriptions[ j ] );
    //}

    console.log('SUBS:ALL:');
    console.log( currentSubcriptions );
  }


  handleShowDialog( title, message ){
    if( typeof this.props.onMessageDialogShowRequest != 'undefined' ){
      this.props.onMessageDialogShowRequest( title, message );
      return;
    }

    alert( message );
  }


  /**
   * 
   *  
   * @returns 
   */
  startFirstListRecievedPoll(){
    var that = this;
    
    if( this.timerFirstListResourceRecieved != null ){
      clearTimeout( this.timerFirstListResourceRecieved );
      this.timerFirstListResourceRecieved = null;
    }

    if( this.state.initialPossibleOptionsRecieved ){
      this.initialSubscription();
      return;
    }

    this.requestUpdatePossibleOptions();

    this.timerFirstListResourceRecieved = setTimeout(function(){
      that.startFirstListRecievedPoll();
    }, 300);
  }


  handleListSelectToggle( clickedItem ){
    if(
      !this.state.initialPossibleOptionsRecieved || 
      !this.initialSubscriptionRequested || 
      !this.initialSubscriptionFinished
    ){
      this.handleShowDialog( 'Probleem', 'Ei saa veel valida' );
      return;
    }

    if( !this.props.allowListChange ){
      this.handleShowDialog( 'Probleem', 'Palun sulgege eelnevalt avatud pilet' );
      return;
    }
    
    let newMethodsList = [];
    
    let useList = this.state.selectedTicketListKeys;

    for( var n in this.state.ticketListOptions ){
      let currentlyPresent = false;

      for( var k in useList ){
        if( this.state.ticketListOptions[ n ].id == useList[ k ].id ){
          currentlyPresent = true;
        }
      }

      let add = currentlyPresent;

      if( this.state.ticketListOptions[ n ].id == clickedItem.id ){
        if( currentlyPresent ){
          add = false;
        } else {
          add = true;
        }
      }
      
      if( add ){
        newMethodsList.push( this.state.ticketListOptions[ n ] );
      }

    }

    // this.setState({
    //  "selectedTicketListKeys" : newMethodsList
    //});

    this.updateSubscriptions( newMethodsList );
  }

  
  /**
   * If inital, look for list marked as "DEFAULT". 
   * If not found don't make any subscriptions. 
   * 
   * If found- apply only if there is no active subscriptions already set
   **/
  initialSubscription(){
    let defaultFound = null;

    for( var n in this.state.ticketListOptions ){
      if( this.state.ticketListOptions[n].code == 'DEFAULT' ){
        defaultFound = this.state.ticketListOptions[n];
      }
    }
    
    if( defaultFound === null ){
      // disable everything active at moment- just in case
      //let currentSubcriptions = this._findTaskSubscriptions( "any" );
      //for( var j in currentSubcriptions ){
      //  currentSubcriptions[ j ].setStatus( 'off' );
      //  window.appEntityStore.saveResource( currentSubcriptions[ j ] );
      //}

      this.setState( {
        'ticketListShow' : true
      } );

      return;
    }

    console.log( "Default subscription found" );
    
    let currentSubcription = this._findTaskSubscription( 'Task?_list=' + defaultFound.reference, "active" );
    
    // disable everything active at moment- except currently updated one
    let currentSubcriptions = this._findTaskSubscriptions( "any" );
    for( var j in currentSubcriptions ){
      if( currentSubcription !== null && currentSubcription.getId().isEqual( currentSubcriptions[ j ].getId() ) ){
        // skip 
      } else {
        currentSubcriptions[ j ].setStatus( 'off' );
        window.appEntityStore.saveResource( currentSubcriptions[ j ] );
      }
    }

    if( currentSubcription == null ){
      let newId = window.appEntityStore.createLocalResourceId( 'Subscription' );
      currentSubcription = new SubscriptionResource( newId );
      currentSubcription.setResourceMeta( window.appEntityStore.getResourceMetaWithOnlyNewSourceUrl( 'Subscription' ) );
    }

    // remove all already lodaded tasks
    window.appEntityStore.clearAllResourcesBySearchParams( {
      "resourceType" : "Task"
    } );
    

    currentSubcription.setCriteria( "Task?_list=" + defaultFound.reference );
    currentSubcription.setStatus( 'active' );
    
    window.appEntityStore.saveResource( currentSubcription );
    
    this.initialSubscriptionRequested = true;
    this.initialSubscriptionFinished = true;
    
    if( this.state.ticketListOptions.length > 1 ){
      this.setState( {
        'ticketListShow' : true
      } );
    }

  }


  updateSubscriptions( newMethodsList ){

    // remove all current subscriptions
    let currentSubcriptions = this._findTaskSubscriptions( "any" );
    for( var j in currentSubcriptions ){
      if( currentSubcriptions[ j ].getStatus() == "active" ){
        currentSubcriptions[ j ].setStatus( 'off' );
        window.appEntityStore.saveResource( currentSubcriptions[ j ] );
      }
    }
    
    // remove all currently lodaded tasks
    window.appEntityStore.clearAllResourcesBySearchParams( {
      "resourceType" : "Task"
    } );
    
    for( var i in newMethodsList ){
      //let currentSubcription = this._findTaskSubscription( "Task?_list=" + newMethodsList[ i ].reference, "active" );
      //console.log( currentSubcription ); 
      let newId = window.appEntityStore.createLocalResourceId( 'Subscription' );
      let currentSubcription = new SubscriptionResource( newId );
      currentSubcription.setResourceMeta( window.appEntityStore.getResourceMetaWithOnlyNewSourceUrl( 'Subscription' ) );
      currentSubcription.setCriteria( "Task?_list=" + newMethodsList[ i ].reference );
      currentSubcription.setStatus( 'active' );
      
      window.appEntityStore.saveResource( currentSubcription );
    }
    
    window.appEventsEmitter.emit( 'ServerCommunicationHandeled', {
      'conponent' : 'TicketListSlector'
    } );
    
    /** 
    let currentSubcription = this._findTaskSubscription( null, "active" );

    if( currentSubcription == null ){
      let newId = window.appEntityStore.createLocalResourceId( 'Subscription' );
      currentSubcription = new SubscriptionResource( newId );
      currentSubcription.setResourceMeta( window.appEntityStore.getResourceMetaWithOnlyNewSourceUrl( 'Subscription' ) );
    }
    
    
    currentSubcription.setStatus( 'active' );
    
    window.appEntityStore.saveResource( currentSubcription );
    */
  }

  
  /**
   * Find first Task subscription
   * 
   * @return void
   */
  _findTaskSubscription( criteria, status ) {
    let subscriptions = window.appEntityStore.getAllResourcesBySearchParams( {
      'resourceType' : 'Subscription'
    } );
    
    for( var i in subscriptions ){
      let test = subscriptions[ i ].getCriteria();

      let isCorrectStatus = true;
      if( status != "any" ){
        if( status != subscriptions[ i ].getStatus() ){
          isCorrectStatus = false;
        }
      }

      if( isCorrectStatus && ( criteria !== null  && criteria == test ) ){
        return subscriptions[ i ];
      }

      if( isCorrectStatus && test.indexOf( 'Task' ) > -1 ){
        return subscriptions[ i ];
      }
    }

    return null;
  }

  
  /**
   * There should only be one for Tasks(tickets))
   * 
   * @return void
   */
  _findTaskSubscriptions( status ) {
    let subscriptions = window.appEntityStore.getAllResourcesBySearchParams( {
      'resourceType' : 'Subscription'
    } );

    let found = [];
    
    for( var i in subscriptions ){
      let test = subscriptions[ i ].getCriteria();
      
      let isCorrectStatus = true;
      if( status != "any" ){
        if( status != subscriptions[ i ].getStatus() ){
          isCorrectStatus = false;
        }
      }

      if( isCorrectStatus && test.indexOf( 'Task' ) > -1 ){
        found.push( subscriptions[ i ] );
      }
    }

    return found;
  }
  

  /**
   * 
   * 
   * @returns void
   */
  requestUpdatePossibleOptions(){
    if( this.timerRequestOptionsUpdate != null ){
      clearTimeout( this.timerRequestOptionsUpdate );
      this.timerRequestOptionsUpdate = null;
    }
    
    var that = this;
    
    this.timerRequestOptionsUpdate = setTimeout(function(){
      that.updatePossibleOptions();
    }, 250);
  }
  

  /**
   * 
   * 
   * @returns void
   */
  updatePossibleOptions(){
    let item = window.appEntityStore.getResourceById( "menu" );
    if( item == null || !item ){
      return;
    }

    let newOptions = this.getCurrentOptions();
    
    let defaultFound = null;

    for( var n in newOptions ){
      if( newOptions[n].code == 'DEFAULT' ){
        defaultFound = true;
      }
    }

    let ticketListShow = false;
    
    if( this.state.ticketListOptions.length > 1 ){
      ticketListShow = true;
    } else {
      if( defaultFound ){
        ticketListShow = false;
      }
    }

    // get all subscriptions that are marked as "active" and update this also
    // selectedTicketListKeys to mirror active subscriptions
    let subscriptions = window.appEntityStore.getAllResourcesBySearchParams( {
      'resourceType' : 'Subscription'
    } );

    let newSelectedTicketListKeys = [];
    
    for( var i in subscriptions ){
      if( subscriptions[ i ].getStatus() == "active" ){
        let test = subscriptions[ i ].getCriteria();
        let foundMatch = null;
        for( var n in newOptions ){
          if( test.indexOf( newOptions[ n ].reference ) > -1 ){
            foundMatch = newOptions[ n ];
          }
        }
        if( foundMatch != null ){
          newSelectedTicketListKeys.push( foundMatch );
        }
      }
    }
    
    this.setState({
      "initialPossibleOptionsRecieved" : true,
      "ticketListOptions" : newOptions,
      "ticketListShow" : ticketListShow,
      "selectedTicketListKeys" : newSelectedTicketListKeys
    });
  }


  getCurrentOptions(){
    let options = [];

    let item = window.appEntityStore.getResourceById( "menu" );
    if( item != null && item ){
      let json = item.toObject();
      if( typeof json.entry != 'undefined' ){
        for( var i in json.entry ){
          let option = this.extractOptionFromEntry( json.entry[ i ] );
          
          if( option != null ){
            options.push( option );
          }
        }
      }
    }
    
    return options;
  }


  /**
   * Example output: 
   * 
   * {
   *   code: "OPTIONAL"
   *   display: "string"
   *   reference: "List/one"
   * }
   */
  extractOptionFromEntry( entry ){
    let code = '';
    let result = null;

    if( 
      typeof entry.flag != 'undefined' && 
      typeof entry.flag.coding != 'undefined' && 
      typeof entry.flag.coding[ 0 ] != 'undefined' && 
      typeof entry.flag.coding[ 0 ].system != 'undefined' && 
      entry.flag.coding[ 0 ].system == 'https://tean.abtram.eu/fhir/codesystem/list/menu-entry-flag' && 
      typeof entry.flag.coding[ 0 ].code != 'undefined'
    ){
      code = entry.flag.coding[ 0 ].code;
    }

    if(
      typeof entry.item != 'undefined' && 
      typeof entry.item.display != 'undefined'  && 
      typeof entry.item.reference != 'undefined' 
    ){
      result = {
        "id" : entry.item.reference, // use list reference url as id
        "code" : code,
        "display" : entry.item.display,
        "reference" : entry.item.reference
      }
    }
    
    return result;
  }


  render() {
    var that = this;

    let inlineStyle = {
      "display" : "none"
    };

    if( this.state.ticketListShow ){
      inlineStyle = {};
    }

    if ( this.state.ticketListOptions ) {
      return (
        <div className="inline-selector tickets-list-selector" style={inlineStyle}>
          <span>
            Teen tööd piletitega:&nbsp;
          </span>
          {this.state.ticketListOptions.map( function(item, index){
            let classNames = "";
            
            let itemClasses = ' selection';
            
            for( var m in that.state.selectedTicketListKeys ){
              if( that.state.selectedTicketListKeys[ m ].id == item.id  ){
                itemClasses = ' selection selection-selected';
              }
            }
            
            return (
              <a className={itemClasses} onClick={() => that.handleListSelectToggle(item)} key={item.reference} >
                <Chip className={classNames} label={item.display} />
              </a>
            );
          })}
          <a href="#" style={{"opacity" : "0", "cursor" : "default"}} onClick={() => that.handleTestClick()} >O</a>
        </div>
      );
    }

  }
}

export default TicketListSelector;
