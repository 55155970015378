/**
 * Value object describing resource ID and some 
 * additional info
 * 
 */


class ResourceId {

  /**
   * 
   */
  constructor(
    id,
    resourceType,
    fullUrl
  ) {
    /**
     * 
     */
    this._resourceType = resourceType;

    /**
     * Resource id inside (JSON) resource
     */
    this._resourceId = id;

    /**
     * 
     */
    this._fullUrl = fullUrl;
  }


  /**
   * Get ID used inside resource object. Used in resource.id field. 
   * For example: 
   * 
   * {
   *   "resourceType" : "Patient",
   *    "id" : {ID}
   * }
   * 
   * @return string
   */
  getResourceId() {
    return this._resourceId;
  }


  /**
   * - 
   * 
   * @return string
   */
  getResourceType() {
    return this._resourceType;
  }


  /**
   * - 
   * 
   * @return string
   */
  getFullUrl() {
    return this._fullUrl;
  }


  /**
   * 
   * 
   * @pram ResourceId testId
   * @return void
   */
  isEqual( testId ) {
    let isEqual = true;
    
    if( testId.getResourceType() != this.getResourceType() ){
      return false;
    }

    if( testId.getResourceId() != this.getResourceId() ){
      return false;
    }

    if( testId.getFullUrl() != this.getFullUrl() ){
      // return false;
    }

    return true;
  }
  
}


export { ResourceId };