"use strict";

/**
 * Represents one Subscription resource 
 * 
 *
 */

import { DomainResource } from './../DomainResource/DomainResource';
import { ResourceId } from './../DomainResource/ResourceId';


class Subscription extends DomainResource {

  /**
   * Subscription
   * 
   * @param ResourceId resourceId
   * @return void
   */
  constructor(
    resourceId : ResourceId
  ) {
    super( 'Subscription' );

    this._id = resourceId;
    this._criteria = '';
  }

  
  /**
   * Set subscription criteria like "Patient/{patient-id-here}/$everything". Tell the server 
   * what we want to subsribe to and what messages we are interested in
   * 
   * @param string criteria 
   */
  setCriteria( criteria ){

    this._criteria = criteria;
    
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.criteria = criteria;
    }
  }


  /**
   * get telecom by system code like phone or email
   * 
   * @return string
   */
  getCriteria() {
    return this._criteria;
  }


  /**
   * Overwrite parent method 
   * 
   * Set local variables based on input JSON
   * 
   * @param object json
   * @return boolean
   */
  setRawResourceObject( json ) {
    super.setRawResourceObject( json );
    
    if( typeof json.criteria != 'undefined' ){
      this._criteria = json.criteria;
    }

  }


  /**
   * Overwrite parent method 
   * 
   * Build basic resource literal
   * This needs to be overwritten by actual resource
   * 
   * @return object
   */
  buidResourceLiteral() {
    super.buidResourceLiteral();

    //Dont build- it's already as json
    if( this._resourceSetByLiteral ){
      return;
    }
    
    if( this._criteria != null ){
      this._resourceLiteral.criteria = this._criteria;
    }
  }

}

export default Subscription;
