"use strict";

import React from "react";

import ContentLoading from "./../ContentLoading.jsx";
import LoginText from "./LoginText.jsx";
import VerificationCode from "./VerificationCode.jsx";
import SubscriptionManager from './../../Tean/Subscription/Manager.js';
import AuthToken from './../../Tean/User/Authentication/AuthToken.js';
import AppAuthStatus  from './../../Tean/User/Authentication/AppAuthStatus.js';
import SessionCookie  from   './../../Tean/Utils/SessionCookie.js';
import StateMachine from "./../../../node_modules/react-simple-state/dist/";
import Card from '@material-ui/core/Card';

const appState = new StateMachine();


class LoginClientForm extends React.Component {
  constructor(props) {
    super(props);

    this.loginTypes = [
      {
        'key' : 'ee-smart-id',
        'label' : 'Smart-ID',
        'methodCode' : 'SID',
        'imgSrc' : "/smart-id.svg"
      },
      {
        'key' : 'ee-mobile-id',
        'label' : 'Mobiil-ID',
        'methodCode' : 'MID',
        'imgSrc' : "/mobiil-id.svg"
      },
      {
        'key' : 'ee-id-card',
        'label' : 'ID-card',
        'methodCode' : 'IDC',
        'imgSrc' : "/id-kaart.svg"
      }
    ];

    this.state = {
      identifierCode: "",
      lastError: "",
      attemptingToLogin: false,
      verificationCode: "",
      practitionerId: "",
      selectedLoginMethodKey: ''
    };

    this.handleIdentityNumberChange = this.handleIdentityNumberChange.bind(
      this
    );
    this.handleIdentityNumberKeyPress = this.handleIdentityNumberKeyPress.bind(
      this
    );
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind( this );
    this.handleLoginMethodSelect = this.handleLoginMethodSelect.bind( this );
    

    const { t } = this.props;

    this.addFakeLoginData();
    this.loginCount = 0;
    this.loginResponseTimeout = null;
  }

  componentDidMount() {
    this._isMounted = true;
    var that = this;

    var handleServerResponseCb = function (eventPayLoad) {
      if (that._isMounted) {
        that.handleServerResponse(eventPayLoad);
      }
    };

    window.appEventsEmitter.addListener("ServerCommunicationInRaw", handleServerResponseCb);
    this.handleServerResponseListener = handleServerResponseCb;
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.appEventsEmitter.removeListener(
      "ServerCommunicationInRaw",
      this.handleServerResponseListener
    );
  }

  handleLoginMethodSelect ( methodObj ){
    var that = this;
    this.setState({
      'selectedLoginMethodKey' : methodObj.key
    });
    
    var timer1 = setTimeout(function () {
      that.attemptLogin();
    }, 100 );
    
  }

  handleLogoClick ( event ){
    
  }

  /**
   * Let this component handle login by itself at moment
   * Let's look for successful login data
   *
   * @param object eventIn
   */
  handleServerResponse(eventIn) {
    if (!this.state.attemptingToLogin) {
      return;
    }

    if( typeof eventIn.rawJson.resourceType === "undefined" ){
      return;
    }

    /**
     * Before login results- check that we do have PractitionerRole returned from request 
       {
        "method": "POST",
        "url": "Person"
       }
       Keep it's ID for logging in
     */
    var foundPractitionerRoleIdString = null;
    if (
      typeof eventIn.rawJson.resourceType !== "undefined" && 
      eventIn.rawJson.resourceType === "Bundle"
    ) {
      for (var m in eventIn.rawJson.entry ) {
        if( 
          typeof eventIn.rawJson.entry[ m ].request !== "undefined" && 
          typeof eventIn.rawJson.entry[ m ].request.method !== "undefined" && 
          typeof eventIn.rawJson.entry[ m ].request.url !== "undefined" && 
          eventIn.rawJson.entry[ m ].request.method === "POST" && 
          eventIn.rawJson.entry[ m ].request.url === "Person" && 
          typeof  eventIn.rawJson.entry[ m ].resource !== "undefined" && 
          typeof  eventIn.rawJson.entry[ m ].resource.resourceType !== "undefined" && 
          eventIn.rawJson.entry[ m ].resource.resourceType === "PractitionerRole"
        ){
          foundPractitionerRoleIdString = eventIn.rawJson.entry[ m ].resource.id;
          // console.log( eventIn.rawJson.entry[ m ].resource );
        }
      }
    }


    if (eventIn.rawJson.resourceType === "Bundle") {
      for (var i in eventIn.rawJson.entry) {
        var entry = eventIn.rawJson.entry[i];
        const status = entry.response.status.substring(0, 3);

        if (typeof entry.resource != "undefined" && entry.fullUrl !== "undefined") {
          var resource = entry.resource;

          if (entry.fullUrl.indexOf("Parameters/verification") > -1 && entry.resource.parameter[0].name === "verificationCode") {
            const eventVerificationCode = entry.resource.parameter[0].valueString

            this.setState({
              verificationCode: eventVerificationCode
            });
          }

          if ( entry.fullUrl.indexOf("Parameters/authentication") > -1 && entry.resource.parameter[0].name === "authenticationUrl") {
            let authenticationUrl = entry.resource.parameter[0].valueUrl

            this.setState({
              authenticationUrl: authenticationUrl
            });
          }
          

          if (typeof entry.resource.resourceType != "undefined" && entry.resource.resourceType === "Person") {
            const response = entry.response;


            if (status === "201") {
              var resource = entry.resource;
              var links = resource.link;
              this.setState({
                practitionerId: links[0].target.reference,
              });
            } else if (status === "401") {
              const userRefused = "ee.sk.smartid.exception.UserRefusedException";
              const sessionTimeOut = "ee.sk.smartid.exception.SessionTimeoutException";
              if (response.outcome && response.outcome.issue && response.outcome.issue.length > 0) {
                const smartIdError = response.outcome.issue[0].diagnostics;
                if (smartIdError === userRefused) {
                  this.setState({
                    attemptingToLogin: false,
                    lastError: "You suspended the login. Try again.",
                    verificationCode: ""
                  });
                }
                if (smartIdError === sessionTimeOut) {
                  this.setState({
                    attemptingToLogin: false,
                    lastError: "You did not enter the PIN1 in time. Try again.",
                    verificationCode: ""
                  });
                }
              }
              this.checkLoginStatus();
            }
          } else if ( entry.fullUrl.indexOf("Parameters/token") > -1 ) {
            const parameter = entry.resource.parameter;
            const token = parameter[0].valueString;

            if (token) {
              window.appLoggedInUser = {
                "isLoggedIn" : true,
                "practitionerRoleId" : foundPractitionerRoleIdString
              }
              
              this.loginAttemptResult(new AppAuthStatus(true, new AuthToken(token)));
            } else {
              this.loginAttemptResult(new AppAuthStatus());
            }
          }

        } else {
          let mssage = 'Autentimine ebaõnnestus. Palun proovige uuesti. ';

          if( status !== '403' ) {
          }

          this.setState({
            attemptingToLogin: false,
            lastError: mssage,
            verificationCode: ""
          });
        }

        if (
          typeof entry.link != "undefined" &&
          typeof entry.resource != "undefined"
        ) {
          var resource = entry.resource;
          var links = entry.link;
        }

      }
    }


  }

  handleIdentityNumberKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      // this.attemptLogin();
    }
  }

  handleIdentityNumberChange(event) {
    this.setState({
      identifierCode: event.target.value
    });
  }

  handleLoginClick(event) {
    event.preventDefault();
    this.attemptLogin();
  }


  attemptLogin() {
    this.setState({
      attemptingToLogin: true,
      lastError: ""
    });

    if (this.state.attemptingToLogin) {
      // return;
    }
    
    var that = this;
    this.loginCount++;

    var bundle = {
      "resourceType": "Bundle",
      "type": "batch",
      "entry": [{
        "resource": {
          "resourceType": "Person",
          "id": "null",
          "identifier": [{
            "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
            "value": that.state.identifierCode,
          }],
          "managingOrganization": {
            "reference": "Organization/haigla"
          }
        },
        "request": {
          "method": "POST",
          "url": "Person"
        }
      }]
    };

    let methodCode = '';
    for( var i in this.loginTypes ){
      if( this.loginTypes[ i ].key == this.state.selectedLoginMethodKey ){
        methodCode = this.loginTypes[ i ].methodCode;
      }
    }

    // if( this.state.selectedLoginMethodKey == 'ee-id-card' ){
      bundle.entry[ 0 ].resource.modifierExtension = [ {
        "url": "https://tean.abtram.eu/fhir/extension/person/authentication-modality",
        "valueString": methodCode
      } ];
    // }
    
    window.apiClient.sendBundle(bundle);

    if (this.loginResponseTimeout != null) {
      clearTimeout(this.loginResponseTimeout);
    }

    this.loginResponseTimeout = setTimeout(function () {
      if (that.state.lastError === "") {
        that.checkLoginStatus();
      }
    }, 180000 ); // 3 minutes timeout



    // WebSocket.readyState
    // WebSocket.OPEN

    // if (true) {
    //   that.loginAttemptResult(new AppAuthStatus(true, new AuthToken(found.tokenString)));
    // } else {
    //   that.loginAttemptResult(new AppAuthStatus());
    // }
  }

  checkLoginStatus() {
    var appAuthStatus = appState.getState("app-auth-status");
    if (!appAuthStatus.isAuthenticated()) {
      const { attemptingToLogin, lastError } = this.state;
      if (attemptingToLogin && lastError === "") {
        this.setState({
          attemptingToLogin: false,
          lastError: "Autentimine ebaõnnestus. Server ei vasta. ",
          verificationCode: ""
        });
      }
    }
  }


  loginAttemptResult(result) {
    if (!result.isAuthenticated()) {
      this.setState({
        attemptingToLogin: false,
        lastError: "Autentimine ebaõnnestus. Palun proovige uuesti. ",
        verificationCode: ""
      });
    }

    // remove timeout check- we are already authenticated
    if (this.loginResponseTimeout != null) {
      clearTimeout(this.loginResponseTimeout);
      this.loginResponseTimeout = null;
    }

    this.setState({
      attemptingToLogin: false,
      lastError: "",
      verificationCode: ""
    });

    var sessionCookie = new SessionCookie();
    sessionCookie.createCookie(
      "app-auth-token",
      result.getAuthToken().getTokenAsString(),
      1
    );
    appState.evoke("app-auth-status", result);
    window.appEventsEmitter.emit("authenticated");
  }


  /**
   * add fake data for mocking logins for dev
   *
   * TODO: To be removed after testing
   *
   * @param void
   */
  addFakeLoginData() {
    this.fakeLogins = [];

    return;

    this.fakeLogins = [
      {
        identityNumber: 1111,
        tokenString: "f24e84445c27fdd906c82835c4ce26a692223292"
      }
    ];
  }


  render() {
    var  that = this;

    var cardClasses = "mdl-card mdl-card--auth-card";
    var translatedWaitMessage = "Palun oota";

    const { attemptingToLogin, verificationCode, lastError } = this.state;

    if (attemptingToLogin) {
      cardClasses = cardClasses + " attempting-login";
    }

    let showMethods = this.state.identifierCode.length > 2;

    const showVerificationCode = verificationCode !== "";

    // loginTypes selectedLoginMethodKey

    return (
      <Card className={cardClasses}>

        <div className="mdl-card__title">
          <img className="logo-img" src="/logo192.png" onClick={this.handleLogoClick} />
        </div>


        <div className="mdl-card__supporting-text" style={{"textAlign" : "center", "marginBottom" : "0"}}>
          <div className="padding-top-10"  style={{"textAlign" : "center", "marginBottom" : "0"}}>
            <p>Sisselogimiseks sisestage oma isikukood. </p>
            <div className="mdl-textfield mdl-js-textfield">
              <input
                type="text"
                name="national_identity_number"
                placeholder=""
                value={this.state.identifierCode}
                className="mdl-textfield__input"
                onChange={this.handleIdentityNumberChange}
                onKeyPress={this.handleIdentityNumberKeyPress}
              />
            </div>
          </div>
        </div>
        
        
        
        {showMethods && (
          <div>
            <div className="mdl-card__supporting-text" style={{'textAlign' : 'center'  }}>
              Valige autentimise viis
            </div>

            <div className="auth-method-selector mdl-list" style={{'padding' : '0px 15px 0 10px', 'display' : 'flex' }}>
              {this.loginTypes.map( function( method, index ){
                let title = method.label;
                let btnClasses = 'mdl-button mdl-button mdl-js-button mdl-button--raised';
                if( method.key == that.state.selectedLoginMethodKey ){
                  btnClasses = btnClasses + ' mdl-button--primary';
                }
                return (
                  <div key={method.key} className="mdl-list__item mdl-list__auth-item">
                    <a
                      href="#"
                      onClick={() => that.handleLoginMethodSelect( method ) }
                      className={btnClasses}
                      style={{'width' : '100%'}}
                    > 
                      <span className="img-wrap"><img src={method.imgSrc}  /></span>
                      <br />
                      {title}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        )}

      
        {this.state.selectedLoginMethodKey == 'ee-id-card' && (
          <div>
            {this.state.attemptingToLogin && this.state.authenticationUrl && (
              <div className="mdl-card__supporting-text" data-method="ee-id-card" >
                <iframe src={this.state.authenticationUrl} style={{ "visibility" : "hidden", "height" : "0px" }} />
              </div>
            )}
          </div>
        )}

        {this.state.selectedLoginMethodKey == 'ee-smart-id' && showVerificationCode && (
          <div className="mdl-card__supporting-text">
            <LoginText showVerificationCode={showVerificationCode} />
            <VerificationCode verificationCode={verificationCode} />
          </div>
        )}
        
        {this.state.selectedLoginMethodKey == 'ee-mobile-id' && showVerificationCode && (
          <div className="mdl-card__supporting-text">
            <LoginText showVerificationCode={showVerificationCode} />
            <VerificationCode verificationCode={verificationCode} />
          </div>
        )}


        <div className="mdl-card__actions">
          {this.state.attemptingToLogin && (
            <ContentLoading message={translatedWaitMessage} />
          )}

          {lastError != "" && (
            <div className="mdl-components__warning">{lastError}</div>
          )}
        </div>
      </Card>
    );
  }
}

export default LoginClientForm;