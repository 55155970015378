'use strict';

/**
 * Value object defining name of the Patient. Simplified value objects 
 * based on FHIR HumanName
 * 
 * @see: 
 *   https://www.hl7.org/fhir/datatypes.html#HumanName
 * 
 * @param string useCode Is it official, maiden or nickname
 * @param string firstName 
 * @param string lastName
 * @return void
 */
class HumanName {

  constructor(
    useCode: string,
    firstName: string,
    lastName: string
  ) {
    
    if( !this._validUseCode( useCode ) ){
      throw new Error( 'Name type is not correctly defined' );
    }
    
    if( !this._validNamePartContent( useCode ) ){
      throw new Error( 'Firstname is not correctly defined' );
    }

    this.nameUse    = useCode;
    this.nameGiven  = firstName;
    this.nameFamily = lastName;
  }


  /**
   * 
   * 
   * @return string
   */
  getFirstName(){
    return this.nameGiven;
  }


  /**
   * 
   * 
   * @return array With given names
   */
  getGivenNames(){
    return [ this.nameGiven ];
  }
  

  /**
   * 
   * 
   * @return string
   */
  getLastName(){
    return this.nameFamily;
  }


  /**
   * 
   * 
   * @return string
   */
  getFullDisplayName(){
    return this.nameGiven + ' ' + this.nameFamily;
  }


  /**
   * 
   * 
   * @return string
   */
  getNameUseType(){
    return this.nameUse;
  }


  /**
   * 
   * 
   * @param HumanName test 
   * @return boolean
   */
  equals( test : HumanName ){
    if(
      test.getNameUseType() == this.getNameUseType() &&
      test.getDisplayName() == this.getDisplayName()
    ){
      return true;
    }
    return false;
  }


  /**
   * 
   * 
   * @param string useCode 
   */
  _validUseCode( useCode ){

    var validTypes = [ 'usual', 'official', 'temp', 'nickname', 'anonymous', 'old', 'maiden' ];

    for( var i in validTypes ){
      if( validTypes[ i ] == useCode ){
        return true;
      }
    }

    return false;
  }


  /**
   * 
   * 
   * @param string namePart 
   */
  _validNamePartContent( namePart ){

    namePart = namePart.trim();

    if( !namePart ){
      return false;
    }

    return true;
  }
  
}


export default HumanName;