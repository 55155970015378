'use strict';

/**
 * Setup cookie for requests
 */

function SessionCookie ( ) {
  
}


SessionCookie.prototype.getValue = function( ) {
  var test = this.readCookie( 'docu_session' );
  
  if( test == null ){
    var test = this.generateId();
    this.createCookie( 'docu_session', test, 365 );
  }

  return test;
};


SessionCookie.prototype.readCookieValue = function( cookieName ) {
  var test = this.readCookie( cookieName );
  
  if( test == null ){
  }

  return test;
};


SessionCookie.prototype.createCookie = function( name,value,days ) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};

SessionCookie.prototype.readCookie = function (name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0){
        return c.substring(nameEQ.length,c.length);
      }
    }
    return null;
}

SessionCookie.prototype.eraseCookie = function(name) {
  this.createCookie(name,"",-1);
}


SessionCookie.prototype.generateId = function() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}



export default SessionCookie ; 