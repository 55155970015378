
'use strict';

/**
 * Dealing with extensions related parsing in FHIR
 * 
 * @param Object resourceItem FHIR resource
 * @return void
 */
function ExtensionParser( resource ) {
  this.resource = null;

  if( resource !== null ){
    this.resource = resource;
  }
}


/**
 * This method is bit hackish way to get extension value as string. Basically extension 
 * can have values set with keys like (value as prefix): 
 * 
 *   valueInteger 
 *   valueBase64Binary
 *   valueCode
 *   valueString
 * 
 * ... and many more. 
 * 
 * This method is needed for quick retrieval of one value in extension if we know already that 
 * some value is set and expected. This method does not cover all value prefixed fileds, but 
 * some of the basic ones used by us. 
 * 
 * @see https://www.hl7.org/fhir/extensibility.html
 * 
 * @param string url
 * @return object|null
 */
ExtensionParser.prototype.getExtensionFirstValueConvertedToString = function( extension ) {
  
  if( typeof extension.valueString === 'string' ){
    return extension.valueString
  }

  if( typeof extension.valueCode !== 'undefined' ){
    return extension.valueCode
  }
  
  if( typeof extension.valueCoding !== 'undefined' &&  typeof extension.valueCoding.code !== 'undefined' ){
    return extension.valueCoding.code
  }

  if( typeof extension.valueInteger !== 'undefined' ){
    return ( typeof extension.valueInteger == 'string' ) ? extension.valueInteger : extension.valueInteger.toString();
  }
  
  //... others to be added when need

  return "";
}


/**
 * Find first extension object by it's url'
 * 
 * 
 * @param string url
 * @return object|null
 */
ExtensionParser.prototype.findFirstExtensionObjectByUrl = function( findUrl ) {
  
  var extensions = this.getExtensionsList();

  for( var i in extensions ){
    if( findUrl == extensions[ i ].url ){
      return extensions[ i ];
    }
  }
  
  return null;
}


/**
 * Extract extenison url for first found item from extensions list
 * If nthing found, return empty string
 * 
 * @return string
 */
ExtensionParser.prototype.getFirstExtensionUrl = function( ) {
  
  var extensions = this.getFirstExtensionObject();

  if( extensions === null ){
    return "";
  }

  return extensions.url;
}


/**
 * Extract extenison url for first found item from extensions list
 * If nthing found, return empty string
 * 
 * @return object|null
 */
ExtensionParser.prototype.getFirstExtensionObject = function( ) {
  
  var extensions = this.getExtensionsList();

  for( var i in extensions ){
    return extensions[ i ];
  }

  return null;
}


/**
 * Extract extensions if present
 * 
 * @return array
 */
ExtensionParser.prototype.getExtensionsList = function( ) {
  
  if( typeof this.resource !== 'undefined' && this.resource !== null ){
    if( typeof this.resource.extension !== 'undefined' && this.resource.extension.length > 0 ){
      return this.resource.extension;
    }
  }

  return [];
}


export default ExtensionParser;