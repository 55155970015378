
/**
 * Translates ticket status or other general item into a icon component and render it
 * 
 * Icons https://material-ui.com/components/material-icons/
 * 
 * 
 */

import React from 'react';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons//HelpOutlineOutlined';
import LockIcon from '@material-ui/icons/Lock';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import SecurityOutlined from '@material-ui/icons/SecurityOutlined'; // HealthAndSafety
import FolderSharedOutlined from '@material-ui/icons/FolderSharedOutlined';

import FullscreenExitOutlined from '@material-ui/icons/FullscreenExitOutlined';
import SettingsOverscan from '@material-ui/icons/SettingsOverscan';
import FullscreenOutlined from '@material-ui/icons/FullscreenOutlined';
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined';


class AppIcon extends React.Component {
  
  render() {

    let status = '';
    let taskStatus = '';
    let iconName = '';
    let size = 'medium';
    let useLabel = '';
    
    if( typeof this.props.size !== 'undefined' && this.props.size !== '' ){
      size = this.props.size;
    }

    if( typeof this.props.taskStatus !== 'undefined' && this.props.taskStatus !== '' ){
      taskStatus = this.props.taskStatus; // ready, in-progress, completed, on-hold, refused
    }

    if( typeof this.props.businessStatus !== 'undefined' && this.props.businessStatus !== '' ){
      status = this.props.businessStatus;
    }

    if( typeof this.props.useLabel !== 'undefined' && this.props.useLabel !== '' ){
      useLabel = this.props.useLabel;
    }

    if( typeof this.props.iconName !== 'undefined' && this.props.iconName !== '' ){
      iconName = this.props.iconName;
    }

    if( status === '' && iconName === '' && taskStatus === '' ){
      return null;
    } 
    
    if( iconName === 'viewFulleScreen' ) {
      return (
        <SettingsOverscan fontSize={size} titleAccess={useLabel} />
      );
    }
    
    if( iconName === 'viewContainedScreen' ) {
      return (
        <FullscreenExitOutlined fontSize={size} titleAccess={useLabel} />
      );
    }
    if( iconName === 'openInNewWindow' ) {
      return (
        <OpenInNewOutlined fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'nextTicket' ) {
      return (
        <ForwardOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'history' ) {
      return (
        <ArrowDropDown fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'locked' || taskStatus == 'in-progress' ) {
      return (
        <LockIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'archive' ) {
      return (
        <ArchiveOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'edit' ) {
      return (
        <EditOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'save' ) {
      return (
        <SaveOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'close' ) {
      return (
        <CancelOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'bookingLink' ) {
      return (
        <EventAvailableOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'add' ) {
      return (
        <AddCircleOutlineOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }
    
    if( iconName === 'ticketStack' || status === 'new' || status == 'NEW' ) {
      return (
        <FilterNoneOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'externallyManaged' ) {
      return (
        <SecurityOutlined fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'overWritten' ) {
      return (
        <FolderSharedOutlined fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'postponed' || status === 'postponed' || status === 'POSTPONED' ) {
      return (
        <UpdateOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'inBooking' || status === 'in-booking' || status === 'in-booking' || status == 'IN-BOOKING' || status == 'inbooking' || status == 'INBOOKING' ) {
      return (
        <HourglassEmptyOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'confirmed' || status === 'queued' || status == 'QUEUED' || status == 'referred' || status == 'REFERRED'  ) {
      return (
        <CheckCircleOutlineOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'notice' || status === 'rejected' || status == 'REJECTED' || status === 'refused' || status == 'REFUSED' ) {
      return (
        <ErrorOutlineOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    if( iconName === 'questionMark' || status == 'NULL' || status == 'null' ) {
      return (
        <HelpOutlineOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }
    
    // Other statuses from background processes 
    // statuses that are not giveable by this app health analyst

    // If task marked complete by doctor
    if( status == 'COMPLETED' || status == 'completed' ){
      return (
        <ThumbUpOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }


    if( iconName === 'solved' || status === 'solved' || status == 'SOLVED' ) {
      return (
        <ThumbUpOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }
    

    if(  iconName === 'trash' || status == 'CANCELLED' || status == 'cancelled' ){
      return (
        <DeleteOutlineOutlinedIcon fontSize={size} titleAccess={useLabel} />
      );
    }

    return null;
  }

}

export default AppIcon;
