/**
 * 
 * 
 * 
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContentLoading from './../ContentLoading';
import TicketsTable from './../TicketsTable/TicketsTable';
import BadgeMenu from './../BadgeMenu';
import HealthTicket from './../HealthTicket/HealthTicket';
import DateTimeFormatter from './../../Tean/Core/DateTimeFormatter';
import ExtensionParser from './../../Tean/Utils/ExtensionParser';
import TicketListSelector from './TicketListSelector';
import DateFormatter from './../../Tean/Utils/DateFormatter';
import KeyValueCache from './../../Tean/Utils/KeyValueCache';

// import Modal from '@material-ui/core/Modal';


class HealthAnalystTool extends React.Component {

  constructor( props ) {
    super( props );

    this._isMounted = false;
    this.defaultTimeoutMilliSeconds = 5000;
    this.dateFormatter = new DateFormatter();

    this.bufferNextMenuSelection = {
      "waiting" : false,
      "menuItem" : null
    };
    this.ticketCloseInitaitedByClick = false;
    this.lastTicketStatusBeforeEdit = null;
    this.waitingForTicketOpen = null;
    this.messageTicketAfterAction = '';
    this.currentTicketModifiedTime = '';
    this.childTicket = React.createRef();
    this.timerListLoading = null;
    this.timerRequestTicketViewPoll = null;
    this.timerRequestTicketEditPoll = null;
    this.timerRequestTicketUpdatePoll = null;
    this.timerRequestTicketClosedPoll = null;
    this.timerNextTicketWorkflowPoll = null;
    
    this.requestTicketViewPollTimeoutTs = 0;
    this.requestTicketUpdatePollTimeoutTs = 0;
    this.requestTicketClosedPollTimeoutTs = 0;
    
    let activeMenuLinkId = "1";

    this.state = {
      "forceReRender" : 1,
      "updatingTicketsList" : false,
      "tickets" : [],
      "menuItems" : this.getBadgeMenuItems( activeMenuLinkId, [] ),
      "activeMenuLinkId" : activeMenuLinkId,
      "ticketViewActive" : false,
      "nextTicketWorkflowAllDone" : false,
      "nextTicketWorkflowOpeningTicketId" : null,
      "nextTicketWorkflowOpeningTicketNumber" : '',
      "ticket" : null,
      "ticketMode" : "view",
      "ticketHasPendingUpdates" : false,
      "askTicketCloseAndSave" : false,
      "askTicketTakeOver" : false,
      "messageDialogShow" : false
    }

    this.handleBadgeMenuClick = this.handleBadgeMenuClick.bind( this );
    this.handleHealthTicketRequestOpen = this.handleHealthTicketRequestOpen.bind( this );
    this.handleTicketEditRequestClick = this.handleTicketEditRequestClick.bind( this );
    this.handleSetTicketModified = this.handleSetTicketModified.bind( this );
    this.handleTicketExitRequest = this.handleTicketExitRequest.bind( this );
    this.handleTicketUpdate = this.handleTicketUpdate.bind( this );

    this.handleDialogAskTicketSaveClose = this.handleDialogAskTicketSaveClose.bind( this );
    this.handleDialogAskTicketSaveCloseDisAgree = this.handleDialogAskTicketSaveCloseDisAgree.bind( this );
    this.handleDialogAskTicketSaveCloseAgree = this.handleDialogAskTicketSaveCloseAgree.bind( this );

    this.handleDialogAskTicketTakeOverDisAgree = this.handleDialogAskTicketTakeOverDisAgree.bind( this );
    this.handleDialogAskTicketTakeOverAgree = this.handleDialogAskTicketTakeOverAgree.bind( this );
    
    this.handleDialogMessageAgree = this.handleDialogMessageAgree.bind( this );
    this.showMessageDialog = this.showMessageDialog.bind( this );
    this.handleAfterBookingPartDone = this.handleAfterBookingPartDone.bind( this );
  }


  componentDidMount() {
    this._isMounted = true;
    var that = this;

    var updateTicketsList = function (eventPayLoad) {
      if (that._isMounted) {
        that.requestTicketsRender();
      }
    };
    
    window.appEventsEmitter.addListener("ServerCommunicationHandeled", updateTicketsList);
    this.listenerUpdateTickets = updateTicketsList;
    
    window.appEventsEmitter.emit( 'rendered', { "eventSource" : "MyTasksBoardContainer" } );
    // ServerCommunicationHandeled
    // this.demoPoll();
  }


  componentWillUnmount() {
    if( this.timerListLoading !== null ){
      clearTimeout( this.timerListLoading );
      this.timerListLoading = null;
    }
    if( this.timerRequestTicketViewPoll != null ){
      clearTimeout( this.timerRequestTicketViewPoll );
      this.timerRequestTicketViewPoll = null;
    }
    if( this.timerRequestTicketEditPoll != null ){
      clearTimeout( this.timerRequestTicketEditPoll );
      this.timerRequestTicketEditPoll = null;
    }
    if( this.timerRequestTicketClosedPoll != null ){
      clearTimeout( this.timerRequestTicketClosedPoll );
      this.timerRequestTicketClosedPoll = null;
    }
    if( this.timerRequestTicketUpdatePoll != null ){
      clearTimeout( this.timerRequestTicketUpdatePoll );
      this.timerRequestTicketUpdatePoll = null;
    }
    if( this.timerNextTicketWorkflowPoll != null ){
      clearTimeout( this.timerNextTicketWorkflowPoll );
      this.timerNextTicketWorkflowPoll = null;
    }
    window.appEventsEmitter.removeListener(
      "ServerCommunicationHandeled",
      this.listenerUpdateTickets
    );
  }

  /**
   * Dont immidately update tickets list
   * Wait 300 ms to be sure that there is no new tickets sent by 
   * server. We don't need to rerender each time ticket arrives via websocket
   * 
   * Show loading overlay while waiting
   */
   requestTicketsRender(){
    var that = this;

    if( this.timerListLoading !== null ){
      clearTimeout( this.timerListLoading );
      this.timerListLoading = null;
    }

    this.setState({
      'updatingTicketsList' : true
    });
    
    this.timerListLoading = setTimeout(function(){
      that.demoPoll();
      that.checkIsCurrentlyOpenedTicketChanged();
    }, 500);
  }
  
  demoPoll() {
    var that = this;
    let tickets = this.getTickets();
    
    this.setState({
      'tickets' : tickets,
      'updatingTicketsList' : false,
      "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets )
    });

    setTimeout(function(){
      //that.demoPoll();
    }, 2500);

    /* PractitionerRole
    console.log( "All resources: " );
    let all = window.appEntityStore.getAllResourcesBySearchParams( {
    } );
    console.log( all );

    let item = window.appEntityStore.getResourceById( "111" );
    console.log( item );
    */
  }


  /**
   * Switch menu item to selected one (user initiated)
   *  
   * If some ticket is in "edit" state- it must be closed before hand 
   * so we buffer menu item while waiting ticket to be closed
   * 
   * If ticket has no pending updates ( ticketHasPendingUpdates )
   * we can close immediately. If it does- we have to ask about clsoing before hand. 
   * 
   * Dont do anything if there is pending action like opening or closing a ticket
   *  
   * @param object menuItem 
   * @returns 
   */
  handleBadgeMenuClick( menuItem ){
    if( this.state.ticketViewActive && this.state.ticketMode === 'edit' ){
      
      this.bufferNextMenuSelection = {
        "waiting" : true,
        "menuItem" : menuItem
      };
      
      if( this.state.ticketHasPendingUpdates ){
        this.setState({
          "askTicketCloseAndSave" : true
        });
        return false;
      } else {
        this.childTicket.current.handleCanceledEditRequestByParent();
        this.childTicket.current.handleShowLoaderByParent();
        this.requestTicketClose( this.state.ticket.ticketId );
        return false;
      }
    }

    if( this.timerRequestTicketEditPoll !== null || this.timerRequestTicketUpdatePoll !== null || this.timerRequestTicketClosedPoll !== null ){
      console.log( "Skipped menu click- ticket is closing, opening or saving. " );
      console.log( this.timerRequestTicketEditPoll );
      console.log( this.timerRequestTicketUpdatePoll );
      console.log( this.timerRequestTicketClosedPoll );
      return;
    }
    if( this.timerRequestTicketViewPoll !== null ){
      console.log( "Skipped menu click- ticket is opened for view. " );
      console.log( this.timerRequestTicketViewPoll );
      return;
    }

    this.switchBadgeMenuTo( menuItem );
  }


  /**
   * Switch to menu by system andy controls over ticket states
   * 
   * @param string menuId 
   */
  switchToMenuById( menuId ){
    
    if( menuId === '' || menuId === null ){
      return;
    }

    let menuItems = this.state.menuItems;

    for( var i in menuItems ){
      menuItems[ i ].menuSelected = false;
      if( menuId === menuItems[ i ].menuLinkId ){
        this.switchBadgeMenuTo( menuItems[ i ] );
      }
    }
    
  }


  handleHealthTicketRequestOpen( ticket ){
    var that = this;

    if( this.state.updatingTicketsList ){
      console.log( "Ticke opening rejected- list is loading" );
      return;
    }

    window.appEntityStore.requestResource( {
      "resourceType" : "Task",
      "url" : "Task/" + ticket.ticketId,
      "id" : ticket.ticketId
    } );
    
    // and wait until all data is here for clicked ticket before actualy opening it
    this.waitingForTicketOpen = ticket;
    let tickets = this.getTickets();
    
    this.setState({
      'tickets' : tickets,
      "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets )
    });

    let dt = new Date();
    this.requestTicketViewPollTimeoutTs = ( dt.getTime() + this.defaultTimeoutMilliSeconds );
    
    setTimeout(function(){
      that.pollHealthTicketOpen();
    }, 200);
  }


  pollHealthTicketOpen(){
    var that = this;

    if( this.timerRequestTicketViewPoll != null ){
      clearTimeout( this.timerRequestTicketViewPoll );
      this.timerRequestTicketViewPoll = null;
    }

    let isAllResourcesLoaded = false;

    // check that bundle is arrived!
    if( this.waitingForTicketOpen === null ){
      return;
    }
    
    let taskResources = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : this.waitingForTicketOpen.ticketId,
      'resourceType' : 'Task'
    });
    let taskResource = ( typeof taskResources[0] != 'undefined' ? taskResources[0] : null );// let taskResource = window.appEntityStore.getResourceById( this.waitingForTicketOpen.ticketId );

    if( taskResource === null ){
      alert( 'Ticket not found. Try again. ' );
      return;
    }
    
    console.log( "pollHealthTicketOpen::"  );
    console.log( this.waitingForTicketOpen.ticketId  );
    console.log( taskResource );
    let bundleReferenceUrl = taskResource.getFocusReferenceUrl();
    let focusBundleResource = window.appEntityStore.getEntityByResourceReference( { "resReference" : bundleReferenceUrl } );

    let patientReferenceUrl = taskResource.getPatientReferenceUrl();
    let patientResource = window.appEntityStore.getEntityByResourceReference( { "resReference" : patientReferenceUrl } );
    
    // Also wait for List resources with patient as subject?
    let lists = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "List",
      "subjectReferenceUrl" : patientReferenceUrl 
    } );

    if( focusBundleResource && patientResource && lists.length > 0 ){
      isAllResourcesLoaded = true;
    }
    
    if( !isAllResourcesLoaded ){

      // if timeout reached - dont poll anymore. 
      let dt = new Date();
      if( this.requestTicketViewPollTimeoutTs < dt.getTime() ){
        this.requestTicketViewPollTimeoutTs = 0;
        this.waitingForTicketOpen = null;
        let tickets = this.getTickets();
        this.setState( {
          'tickets' : tickets,
          "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets ),
          "ticketViewActive" : false,
          "ticketHasPendingUpdates" : false
        } );
        console.log( 'Timeout reached' );
        this.showMessageDialog( 'Avamine ebaõnnestus', 'Pileti avamine ebaõnnestus. Palun proovige uuesti. ' );
        return;
      }
      
      this.timerRequestTicketViewPoll = setTimeout(function(){
        that.pollHealthTicketOpen();
      }, 300);
      return;
    }

    // reload tickets and get current ticket data to be forwarded to ticket component
    let ticketId = this.waitingForTicketOpen.ticketId;
    this.waitingForTicketOpen = null;
    let tickets = this.getTickets();

    let ticketData = null;
    for( var jj in tickets ){
      if( ticketId == tickets[ jj ].ticketId ){
        ticketData = tickets[ jj ];
      }
    }

    this.requestTicketViewPollTimeoutTs = 0;

    if( ticketData === null ){
      alert('Piletit ei leitud');
      return;
    }

    /**
     * If ticket was opened while being in the "next-ticket" - open it for editing as a next step 
     * instead for viewing
     * 
    console.log( nextTicket );
    this.requestTicketEdit( nextTicket.ticketId );

    this.setState({
      "nextTicketWorkflowAllDone" : false,
      "nextTicketWorkflowOpeningTicketId" : nextTicket.ticketId,
      "nextTicketWorkflowOpeningTicketNumber" : nextTicket.ticketNumber
    });

     */
    if( this.state.activeMenuLinkId === "next-ticket" ){
      console.log( 'Working on next-ticket workflow- go straight to opening ticket' );
      this.setState( {
        'tickets' : tickets,
        "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets ),
        "ticketViewActive" : true,
        "ticketHasPendingUpdates" : false,
        "ticket" : ticketData
      } );
      this.requestTicketEdit( ticketData.ticketId );
      return;
    }
    
    this.setState( {
      'tickets' : tickets,
      "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets ),
      "ticketViewActive" : true,
      "ticketHasPendingUpdates" : false,
      "ticket" : ticketData
    } );
  }


  handleTicketEditRequestClick( ticket ){
    var that = this;
    
    if( ticket.isLocked ){
      that.setState( {
        "askTicketTakeOver" : true
      } );
      return;
    }

    that.requestTicketEdit( ticket.ticketId );
    // setTimeout(function(){}, 1000);
  }
  

  /**
   * Let current component know that ticket has some 
   * pending updates set
   * 
   * @returns void
   */
  handleSetTicketModified( pendingUpdates ){
    this.setState( {
      "ticketHasPendingUpdates" : true
    } );
  }

  /**
   * If closing ticket in EDIT mode (locked to health analyst) we need to 
   * clear locking first. If in view mode - can just close the ticket view
   * 
   * @returns void
   */
  handleTicketExitRequest(  ){
    this.ticketCloseInitaitedByClick = true;

    if( this.state.ticketMode === 'edit' ){
      this.requestTicketClose( this.state.ticket.ticketId );
      return;
    }
    
    this.ticketExit();
  }


  ticketExit(){
    let tickets = this.getTickets();
    
    this.setState({
      'forceReRender' : ( this.state.forceReRender + 1 ),
      'tickets' : tickets,
      'ticketMode' : "view",
      'ticketViewActive' : false,
      "ticketHasPendingUpdates" : false,
      'forceUpdate' : new Date().getMilliseconds(),
      "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets )
    });
    
    /* 
    this.setState( {
      ticketViewActive : false,
      ticketMode : "view"
    } );
    */
    
    if( this.state.activeMenuLinkId === 'next-ticket' ){
      if( this.ticketCloseInitaitedByClick ){
        this.switchToMenuById( '1' ); // move to tähelepanu ootavad
      } else {
        this.requestMoveToNextTcket( false );
      }
    }
  }


  /**
   * Try to unlock/remove ownership on given ticket 
   * 
   * @param string ticketId 
   */
  requestTicketClose( ticketId ) {
    var that = this;
    let ticketIdTmp = ticketId;
    
    let items = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : ticketId,
      'resourceType' : 'Task'
    });
    let item = ( typeof items[0] != 'undefined' ? items[0] : null );//let item = window.appEntityStore.getResourceById( ticketId );

    if( item === null ){
      alert( 'Ticket not found. Try again. ' );
      return;
    }

    item.setProcessStatus( 'task-close-with-no-edits' );
    item.removeOwner( this.lastTicketStatusBeforeEdit );
    
    var df = new DateTimeFormatter();
    let dateTime = df.defaultFormatWithTimezone( new Date() );
    item.setModifiedDate( dateTime );
    this.currentTicketModifiedTime = dateTime;
    window.appEntityStore.saveResource( item );
    
    setTimeout(function(){
      that.pollTicketCloseDone( ticketIdTmp );
    }, 800 );
  }


  pollTicketCloseDone( ticketId ){
    let ticketIdTmp = ticketId;
    var that = this;

    if( this.timerRequestTicketClosedPoll != null ){
      clearTimeout( this.timerRequestTicketClosedPoll );
      this.timerRequestTicketClosedPoll = null;
    }

    let items = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : ticketId,
      'resourceType' : 'Task'
    });
    let item = ( typeof items[0] != 'undefined' ? items[0] : null );//let item = window.appEntityStore.getResourceById( ticketId );
    if( item === null ){
      alert( 'Ticket not found. Try again. ' );
      return;
    }

    let syncing = window.appEntityStore.isRecourceSyncing( item );
    
    if( syncing ){
      this.timerRequestTicketClosedPoll = setTimeout(function(){
        that.pollTicketCloseDone( ticketIdTmp );
      }, 300);
      return;
    }

    if( this.bufferNextMenuSelection.waiting ){
      this.switchBadgeMenuTo( this.bufferNextMenuSelection.menuItem );
      this.bufferNextMenuSelection = {
        "waiting" : false,
        "menuItem" : null
      };
    }

    this.lastTicketStatusBeforeEdit = null;
    
    this.ticketExit();
  }


  /**
   * Try to obtain lock/ownership on given ticket 
   * 
   * @param string ticketId 
   */
  requestTicketEdit( ticketId ) {
    let prResource = this.getCurrentlyLoggedInPracticionerRole();
    let items = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : ticketId,
      'resourceType' : 'Task'
    });
    let item = ( typeof items[0] != 'undefined' ? items[0] : null );// window.appEntityStore.getResourceById( ticketId );
    this.ticketCloseInitaitedByClick = false;

    if( item === null ){
      console.log( 'ticketId: ' + ticketId );
      alert( 'Ticket not found. Try again. ' );
    }
    
    console.log( "Before: setOwnerByPractitionerRoleResource" );
    console.log( ticketId );
    console.log( item );
    
    if( prResource ){
      console.log( 'requestTicketEditPoll start::' );
      this.lastTicketUpdateTime = item.getResourceMeta().getLastUpdatedAsString();
      item.setOwnerByPractitionerRoleResource( prResource );

      var df = new DateTimeFormatter();
      let dateTime = df.defaultFormatWithTimezone( new Date() );
      item.setModifiedDate( dateTime );
      this.currentTicketModifiedTime = dateTime;
      window.appEntityStore.saveResource( item );
      this.requestTicketEditPoll( ticketId );
    } else {
      alert( 'Cant open ticket for editing since logged in PracticionerRole not found' );
    }

  }


  /**
   * Try to obtain lock/ownership on given ticket 
   * 
   * @param string ticketId 
   */
  requestTicketEditPoll( ticketId ) {
    let that = this;
    let ticketIdTmp = ticketId;

    if( this.timerRequestTicketEditPoll != null ){
      clearTimeout( this.timerRequestTicketEditPoll );
      this.timerRequestTicketEditPoll = null;
    }
    
    let taskResources = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : ticketId,
      'resourceType' : 'Task'
    });
    let item = ( typeof taskResources[0] != 'undefined' ? taskResources[0] : null );// let item = window.appEntityStore.getResourceById( ticketId );
    
    let syncing = window.appEntityStore.isRecourceSyncing( item );

    // check also that owner is current user?
    if( !syncing ){
      let tickets = this.getTickets();

      let ticketData = null;
      for( var jj in tickets ){
        if( ticketId == tickets[ jj ].ticketId ){
          ticketData = tickets[ jj ];
        }
      }

      console.log( 'ticketData after poll:' );
      console.log( ticketData );

      // overwrite ticketViewActive and ticket states - even thoughthey are already set on 
      // ticket open. This is because edit poll can happen in background (next-ticket workflow)

      this.lastTicketStatusBeforeEdit = ticketData.ticketStatus;

      /** 
      // temporary fix - TODO: REMOVE 
      */
      if( ticketData.businessStatus == 'NEW' ){
        this.lastTicketStatusBeforeEdit = 'ready';
      }
      if( ticketData.businessStatus == 'IN-BOOKING' || ticketData.businessStatus == 'INBOOKING' || ticketData.businessStatus == 'REFERRED' || ticketData.businessStatus == 'POSTPONED'  ){
        this.lastTicketStatusBeforeEdit = 'on-hold';
      }
      if( ticketData.businessStatus == 'REJECTED' ){
        this.lastTicketStatusBeforeEdit = 'rejected';
      }
      if( ticketData.businessStatus == 'SOLVED' ){
        this.lastTicketStatusBeforeEdit = 'completed';
      }
      
      this.setState({
        'ticket' : ticketData,
        
        'tickets' : tickets,
        'ticketMode' : "edit",
        'ticketViewActive' : true,
        'forceUpdate' : new Date().getMilliseconds(),
        "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets ),
        
        "nextTicketWorkflowOpeningTicketId" : '',
        "nextTicketWorkflowOpeningTicketNumber" : ''
      });

      return;
    }
    
    this.timerRequestTicketEditPoll = setTimeout(function(){
      that.requestTicketEditPoll( ticketIdTmp );
    }, 2000);
  }

  /**
   * 
   * 
   * @return void
   */
  checkIsCurrentlyOpenedTicketChanged(){

    if( !this.state.ticketViewActive || this.state.ticketMode != 'edit' ){
      return;
    }
    
    let taskResources = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : this.state.ticket.ticketId,
      'resourceType' : 'Task'
    });
    let taskResource = ( typeof taskResources[0] != 'undefined' ? taskResources[0] : null );// let taskResource = window.appEntityStore.getResourceById( this.state.ticket.ticketId );

    if( taskResource === null ){
      return;
    }

    console.log( 'checkIsCurrentlyOpenedTicketChanged time: ' );
    console.log( taskResource.getModifiedDate()  );
    console.log( this.currentTicketModifiedTime );

    // use modifiedTime to check a update
    if( taskResource.getModifiedDate() != this.currentTicketModifiedTime ){
      this.showMessageDialog( 'Ülevõetud', 'Keegi võttis teie pileti üle või muudeti muul põhjuse. Sulgeme käesoleva pileti ja te peate pileti uuesti avama. ' );
      this.messageTicketAfterAction = 'closeTicketWithoutSaving';
    }
    
    
    /** 
    let ref = taskResource.getRestrictionReference();
    let restrictionReference = taskResource.getRestrictionReference();
    let restrictionPracticioner = null;
    if( restrictionReference != null ){
      restrictionPracticioner = window.appEntityStore.getEntityByResourceReference( { "resReference" : restrictionReference.reference } );
    }

    let current = this.getCurrentlyLoggedInPracticionerRole();
    
    if( current === null || restrictionReference === null ){
      return;
    }

    console.log( 'checkIsCurrentlyOpenedTicketChanged' );
    console.log( current );
    console.log( restrictionPracticioner );
    if( current.getId().isEqual( restrictionPracticioner.getId() ) ){
      
    } else {
      console.log( 'Someone took over' );
      this.showMessageDialog( 'Ülevõetud', 'Keegi võttis teie pileti üle või muudeti muul põhjuse. Sulgeme käesoleva pileti ja te peate pileti uuesti avama. ' );
      this.messageTicketAfterAction = 'closeTicketWithoutSaving';
    }
    */
  }


  /**
   * 
   * Look for PractitionerRole with following code set: 
   * 
   * "code": [
      {
        "coding": [
          {
            "system": "https://tean.abtram.eu/fhir/codesystem/practitioner-role/code",
            "code": "HEALTH_ANALYST"
          }
        ]
      }
   * 
   * @return PractitionerRole|null
   */
  getCurrentlyLoggedInPracticionerRole(){
    if( typeof window.appLoggedInUser === 'undefined' || !window.appLoggedInUser.isLoggedIn ){
      return null;
    }
    
    return window.appEntityStore.getResourceById( window.appLoggedInUser.practitionerRoleId );
  }


  handleTicketUpdate( ticketId, pendingUpdates ){
    var that = this;
    this.lastPendingUpdates = pendingUpdates;
    
    let taskResources = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : ticketId,
      'resourceType' : 'Task'
    });
    let item = ( typeof taskResources[0] != 'undefined' ? taskResources[0] : null );// let item = window.appEntityStore.getResourceById( ticketId );

    var df = new DateTimeFormatter();
    
    console.log( item );
    console.log( "pendingUpdates for " + ticketId );
    console.log( pendingUpdates );
    
    let validationResult = item.validatePendingUpdate( pendingUpdates );
    if( validationResult.isError ){
      this.showMessageDialog( "Probleem", validationResult.message );
      return false;
    }

    if( typeof pendingUpdates.priority != 'undefined' ){
      item.setPriorityCode( pendingUpdates.priority );
    }
    
    if( typeof pendingUpdates.communicationMethods != 'undefined' ){
      item.replaceCommunicationMethods( pendingUpdates.communicationMethods );
    }

    if( typeof pendingUpdates.practicionerNote != 'undefined' ){
      let dateTime = df.defaultFormatWithTimezone( new Date() );
      item.setPracticionerNoteByPracticionerRole( pendingUpdates.practicionerNote, this.getCurrentlyLoggedInPracticionerRole(), dateTime );
    }
    
    if( typeof pendingUpdates.restrictionPracticionerRoleId != 'undefined' ){
      if( pendingUpdates.restrictionPracticionerRoleId != null ){
        let pr = window.appEntityStore.getResourceById( pendingUpdates.restrictionPracticionerRoleId );
        item.setRestrictionByPractitionerRoleResource( pr );
      } else {
        item.removeRestrictionPractitionerRole();
      }
    }

    if( typeof pendingUpdates.processCommand != 'undefined' ){
      item.setProcessStatus( pendingUpdates.processCommand );
    }

    let dateTime = df.defaultFormatWithTimezone( new Date() );
    item.setModifiedDate( dateTime );

    this.currentTicketModifiedTime = dateTime;

    // after update remove ownership right away
    item.removeOwner();

    window.appEntityStore.saveResource( item );
    
    let ticketIdTmp = ticketId;
    setTimeout(function(){
      that.pollTicketUpdateDone( ticketIdTmp );
    }, 1000);

    return true;
  }


  pollTicketUpdateDone( ticketId ){
    let ticketIdTmp = ticketId;
    var that = this;

    if( this.timerRequestTicketUpdatePoll != null ){
      clearTimeout( this.timerRequestTicketUpdatePoll );
      this.timerRequestTicketUpdatePoll = null;
    }

    let taskResources = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceId' : ticketId,
      'resourceType' : 'Task'
    });
    let item = ( typeof taskResources[0] != 'undefined' ? taskResources[0] : null );// let item = window.appEntityStore.getResourceById( ticketId );
    
    if( item === null ){
      alert( 'Ticket not found. Try again. ' );
      return;
    }

    let syncing = window.appEntityStore.isRecourceSyncing( item );
    
    if( syncing ){
      this.timerRequestTicketUpdatePoll = setTimeout(function(){
        that.pollTicketUpdateDone( ticketIdTmp );
      }, 300);
      return;
    }

    // If booking url is given - dont move on instantly
    // message Ticket component about this url and let the user close ticket there
    if( this.lastPendingUpdates.processCommand === 'task-forward-booking' ){
      let url = item.getWebBookingUrl();
      if( url !== null && url !== '' ){
        // Before moving on - show booking part in ticket form
        this.childTicket.current.moveToBookingPart( url );
        return;
      }
    }

    this.ticketUpdateDone();
    
    /** old way of showing dialog
    if( this.lastPendingUpdates.processCommand === 'task-forward-booking' ){
      let url = item.getWebBookingUrl();
      if( url !== null && url !== '' ){
        this.showMessageDialog( 'Link broneerimiseks', 'Pilet on salvestatud. <br />Link veebiregistratuuri avaneb <a target="_blank" href="' + url + '" class="link-open-web-external-booking">SIIT</a>. <br /><br /> Vajadusel leiab lingi uuesti ka pileti andmetest. ' );
      }
    }
    */
  }

  
  handleAfterBookingPartDone(){
    this.ticketUpdateDone();
  }


  ticketUpdateDone(){
    let tickets = this.getTickets();
    
    this.setState({
      'forceReRender' : ( this.state.forceReRender + 1 ),
      'tickets' : tickets,
      "ticket" : null,
      "ticketMode" : "view",
      "ticketViewActive" : false,
      "ticketHasPendingUpdates" : false,
      'forceUpdate' : new Date().getMilliseconds(),
      "menuItems" : this.getBadgeMenuItems( this.state.activeMenuLinkId, tickets )
    });
    
    if( this.state.activeMenuLinkId === 'next-ticket' ){
      this.requestMoveToNextTcket( false );
    }
  }


  /**
   * 
   * 
   * @param string message 
   */
  showMessageDialog( title, message ){
    
    this.setState( {
      "messageDialogShow" : true,
      "messageDialogContent" : message,
      "messageDialogTitle" : title
    });
  }
  

  handleDialogMessageAgree(){
    
    this.setState({
      "messageDialogShow" : false,
      "messageDialogContent" : '',
      "messageDialogTitle" : ''
    });

    if( this.messageTicketAfterAction == 'closeTicketWithoutSaving' ){
      this.ticketExit();
    }
  }

  
  handleDialogAskTicketSaveClose(){
    this.bufferNextMenuSelection = {
      "waiting" : false,
      "menuItem" : null
    };
    this.setState({
      "askTicketCloseAndSave" : false
    });
  }

  
  handleDialogAskTicketSaveCloseDisAgree(){
    this.bufferNextMenuSelection = {
      "waiting" : false,
      "menuItem" : null
    };
    this.setState({
      "askTicketCloseAndSave" : false
    });
  }

  
  handleDialogAskTicketSaveCloseAgree(){
    this.childTicket.current.handleCanceledEditRequestByParent();
    this.childTicket.current.handleShowLoaderByParent();
    this.requestTicketClose( this.state.ticket.ticketId );
    this.setState({
      "askTicketCloseAndSave" : false
    });
  }
  
  
  handleDialogAskTicketTakeOverDisAgree(){
    this.childTicket.current.handleCanceledEditRequestByParent();

    this.setState({
      "askTicketTakeOver" : false
    });
  }

  
  handleDialogAskTicketTakeOverAgree(){
    this.setState({
      "askTicketTakeOver" : false
    });

    var that = this;

    setTimeout(function(){
      that.requestTicketEdit( that.state.ticket.ticketId );
    }, 1000);
  }
  
  switchBadgeMenuTo( menuItem ){

    let menuItems = this.state.menuItems;
    for( var i in menuItems ){
      menuItems[ i ].menuSelected = false;
      if( menuItem.menuLinkId === menuItems[ i ].menuLinkId ){
        menuItems[ i ].menuSelected = true;
      }
    }
    
    this.setState( {
      "menuItems" : menuItems,
      "activeMenuLinkId" : menuItem.menuLinkId,
      
      "ticketViewActive" : false,
      "ticketHasPendingUpdates" : false,
      "ticket" : null,
      "ticketMode" : "view"
    } );

    if( menuItem.menuLinkId === "next-ticket" ) {
      this.requestMoveToNextTcket( false );
    }
  }

  /**
   * This method expect that this.ticketViewActive is false
   * set in caller. If already should be in taht state set checkMenuLinkId to true
   * 
   * @param checkMenuLinkId 
   * @returns 
   */
  requestMoveToNextTcket( checkMenuLinkId ){
    var that = this;

    if( checkMenuLinkId ){
      if( this.state.activeMenuLinkId != "next-ticket" ){
        // if user moved away, terminate timer
        if( this.timerNextTicketWorkflowPoll != null ){
          clearTimeout( this.timerNextTicketWorkflowPoll );
        }
        return;
      }
    }
    
    let nextTicket = this.findNextTicketToOpen();
    
    console.log( 'requestMoveToNextTcket poll' );

    if( nextTicket === null ){
      this.setState({
        "nextTicketWorkflowOpeningTicketId" : '',
        "nextTicketWorkflowAllDone" : true,
        "nextTicketWorkflowOpeningTicketNumber" : ''
      });

      // wait for some active tickets 
      this.timerNextTicketWorkflowPoll = setTimeout(function(){
        that.requestMoveToNextTcket( true );
      }, 700);
      return;
    }
    
    // cant open for edit right away- make also opening process before
    //this.requestTicketEdit( nextTicket.ticketId );
    this.handleHealthTicketRequestOpen( nextTicket )

    this.setState({
      "nextTicketWorkflowAllDone" : false,
      "nextTicketWorkflowOpeningTicketId" : nextTicket.ticketId,
      "nextTicketWorkflowOpeningTicketNumber" : nextTicket.ticketNumber
    });
  }
  
  /**
   * find ticket to open when in healt analyst is in "next-ticket" workflow
   * 
   * @return object
   */
  findNextTicketToOpen(){
    let tickets = this.getTickets();
    
    tickets.sort(function( a, b ){
      if( a.priorityNumber == b.priorityNumber ){
        return ( a.executionPeriodEndTS < b.executionPeriodEndTS ) ? -1 : 1;
      }
      return ( a.priorityNumber < b.priorityNumber ) ? -1 : 1;
    });

    let tmp = new Date();
    var currentTs = tmp.getTime();
    // isLocked
    for( var j in tickets ){
      // || tickets[ j ].ticketStatus == 'in-progress'
      if( 
        ( tickets[ j ].ticketStatus == 'ready' || tickets[ j ].ticketStatus == 'on-hold' ) && 
        ( tickets[ j ].executionPeriodStartTS > 0 && tickets[ j ].executionPeriodStartTS < currentTs)  
      ){
          return tickets[ j ];
      }
    }

    return null;
  }

  /**
   * Counts are fixed at moment for demo, later will be based on real counts
   * 
   * @param string activeMenuLinkId
   * @returns array
   */
  getBadgeMenuItems( activeMenuLinkId, tickets ){

    let items = [
      {
        'menuLinkId' : "next-ticket",
        'label' : 'Järgmine pilet',
        'iconName' : "nextTicket",
        'menuSelected' : true,
        'ticketStatusReason' : 'none',
        'count' : null
      }, {
        'menuLinkId' : "1",
        'label' : 'Tähelepanu ootavad',
        'iconName' : "ticketStack",
        'ticketStatusReason' : 'all',
        'count' : 0
      }, {
        'menuLinkId' : "3",
        'label' : 'Edasi lükatud',
        'iconName' : "postponed",
        'ticketStatusReason' : 'POSTPONED',
        'count' : 0
      }, {
        'menuLinkId' : "4",
        'label' : 'Broneeringu ootel',
        'iconName' : "inBooking",
        'ticketStatusReason' : 'INBOOKING',
        'count' : 0
      }, {
        'menuLinkId' : "5",
        'label' : 'Kinnituse ootel',
        'iconName' : "confirmed",
        'ticketStatusReason' : 'REFERRED',
        'count' : 0
      }, {
        'menuLinkId' : "6",
        'label' : 'Tagastatud',
        'iconName' : "notice",
        'ticketStatusReason' : 'REJECTED',
        'count' : 0
      }, {
        'menuLinkId' : "archive",
        'label' : 'Kõik',
        'iconName' : "archive",
        'ticketStatusReason' : 'none',
        'count' : null
      }
    ];

    let tmp = new Date();
    var currentTs = tmp.getTime();

    for( var i in items ){
      items[ i ].menuSelected = false;
      if( activeMenuLinkId === items[ i ].menuLinkId ){
        items[ i ].menuSelected = true;
      }

      if( items[ i ].ticketStatusReason !== 'none' && items[ i ].ticketStatusReason !== 'all' ){
        let ticketCount = 0;
        for( var j in tickets ){
          if( 
            tickets[ j ].businessStatus == items[ i ].ticketStatusReason &&
            ( tickets[ j ].ticketStatus == 'ready' || tickets[ j ].ticketStatus == 'in-progress' || tickets[ j ].ticketStatus == 'on-hold' ) && 
            ( tickets[ j ].executionPeriodStartTS > 0 && tickets[ j ].executionPeriodStartTS < currentTs )
          ){
            ticketCount++;
          }
        }
        items[ i ].count = ticketCount;
      }

      if( items[ i ].ticketStatusReason !== 'none' && items[ i ].ticketStatusReason === 'all' ){
        let ticketCount = 0;
        for( var j in tickets ){
          if( 
            ( tickets[ j ].ticketStatus == 'ready' || tickets[ j ].ticketStatus == 'in-progress' || tickets[ j ].ticketStatus == 'on-hold' ) && 
            ( tickets[ j ].executionPeriodStartTS > 0 && tickets[ j ].executionPeriodStartTS < currentTs)  
          ){
            ticketCount++;
          }
        }
        items[ i ].count = ticketCount;
      }
    }

    

    return items;
  }


  /**
   * Before creating tickets- create HealthcareService cache since Tasks use that
   * 
   * @returns array
   */
  getTickets(){
    this.cacheHealthcareCodeToResourceId = new KeyValueCache();
    let tmpList = window.appEntityStore.getAllResourcesBySearchParams({
      'resourceType' : 'HealthcareService'
    });
    for( var ii2 in tmpList ){
      let test = tmpList[ ii2 ].toObject();

      if( typeof test.identifier !== "undefined" ) {
        for( var ii3 in test.identifier ){
          if(
            typeof test.identifier[ ii3 ].system !== "undefined" && 
            typeof test.identifier[ ii3 ].value !== "undefined" && 
            test.identifier[ ii3 ].system === "https://tean.abtram.eu/fhir/namingsystem/gp-patient-listing-estonia"
          ){
            this.cacheHealthcareCodeToResourceId.set(
              test.identifier[ ii3 ].value,
              test.id
            );
          }
        }
      }
    }
    
    let allTasks = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "Task"
    } );

    let tickets = [];
    
    for( var i in allTasks ){
      let ticket = this.createTicketFromTaskResource( allTasks[ i ] );

      if( ticket !== null ){
        tickets.push( ticket );
      }
    }
    
    return tickets;
  }


  getFilteredTickets( tickets ){
    let ticketsFiltered = [];
    
    let filterStatusReason = '';
    for( var i in this.state.menuItems ){
      if( this.state.activeMenuLinkId === this.state.menuItems[ i ].menuLinkId ){
        filterStatusReason = this.state.menuItems[ i ].ticketStatusReason;
      }
    }

    let tmp = new Date();
    var currentTs = tmp.getTime();
    
    for( var j in tickets ){
      if( 
        tickets[ j ].businessStatus === filterStatusReason || 
        filterStatusReason == 'all'
      ){
        if( 
          tickets[ j ].ticketStatus == 'ready' || tickets[ j ].ticketStatus == 'in-progress' || tickets[ j ].ticketStatus == 'on-hold'
        ){
          if(  tickets[ j ].executionPeriodStartTS > 0 && tickets[ j ].executionPeriodStartTS < currentTs ){
            ticketsFiltered.push( tickets[ j ] );
          }
        }
      } else if ( this.state.activeMenuLinkId == 'archive' ){
        ticketsFiltered.push( tickets[ j ] );
      }
    }


    // order by execution period and priority in filters and via authoredOn date when in archieve
    if( this.state.activeMenuLinkId === 'archive' ){
      ticketsFiltered.sort(function( a, b ){
        return ( a.authoredOnTS > b.authoredOnTS ) ? -1 : 1;
      });
    } else {
      ticketsFiltered.sort(function( a, b ){
        if( a.priorityNumber == b.priorityNumber ){
          return ( a.executionPeriodEndTS < b.executionPeriodEndTS ) ? -1 : 1;
        }
        return ( a.priorityNumber < b.priorityNumber ) ? -1 : 1;
      });
    }

    return ticketsFiltered;
  }


  getFilteredTicketsHeading( ticketCount ){
    let label = '';
    for( var i in this.state.menuItems ){
      if( this.state.activeMenuLinkId === this.state.menuItems[ i ].menuLinkId ){
        label = this.state.menuItems[ i ].label;
      }
    }
    return label + ' (' + ticketCount + ')';
  }


  createTicketFromTaskResource( 
    taskResource
  ){

    if( taskResource == null ){
      return null;
    }
    
    let literal = taskResource.toObject();
    
    let ticketId = literal.id;
    let ticketNumber = taskResource.getEncounterNumber();
    let personDisplayName = this.getDisplayOrCodeFrom( literal.requester, '-' );
    let priority = taskResource.getPriorityCode();
    let priorityNumber = taskResource.getPriorityNumber();
    let priorityPreferred = taskResource.getPreferredPriority();

    let lockedStatus = taskResource.getLockedStatus();
    let isLocked = lockedStatus.isLocked;
    let isLockedTo = lockedStatus.lockedToDisplayName;
    
    let timestampStart = 0;
    let dateTimeStartFormatted = '-';
    let dateTimeStartFormattedLong = '-';
    if( taskResource.getExecutionPeriodStart() !== null ){
      //dateTimeFormatted = taskResource.getExecutionPeriodStart().substr( 0, 10 );
      //dateTimeStartFormattedLong = taskResource.getExecutionPeriodStart().substr( 0, 16 ).split( 'T' ).join(' ');
      dateTimeStartFormatted          = this.dateFormatter.defaultDateFormat( taskResource.getExecutionPeriodStart() );
      dateTimeStartFormattedLong = this.dateFormatter.defaultDateFormat( taskResource.getExecutionPeriodStart(), true );
      let tmp1 = new Date( taskResource.getExecutionPeriodStart() );
      timestampStart = tmp1.getTime();
    }

    let timestampEnd = 0;
    let dateTimeEndFormatted = '-';
    let dateTimeEndFormattedLong = '-';
    if( taskResource.getExecutionPeriodEndDate() !== null ){
      //dateTimeEndFormatted = taskResource.getExecutionPeriodEndDate().substr( 0, 10 );
      //dateTimeEndFormattedLong = taskResource.getExecutionPeriodEndDate().substr( 0, 16 ).split( 'T' ).join(' ');
      dateTimeEndFormatted     = this.dateFormatter.defaultDateFormat( taskResource.getExecutionPeriodEndDate() );
      dateTimeEndFormattedLong = this.dateFormatter.defaultDateFormat( taskResource.getExecutionPeriodEndDate(), true );
      let tmp2 = new Date( taskResource.getExecutionPeriodEndDate() );
      timestampEnd = tmp2.getTime();
    }

    let authoredOnTS = 0;
    let authoredOnDateFormatted = '-';
    let authoredOnDateFormattedLong = '-';
    if( taskResource.getAuthoredOnDate() !== null ){
      //authoredOnDateFormattedLong = taskResource.getAuthoredOnDate().substr( 0, 16 ).split( 'T' ).join(' ');
      authoredOnDateFormatted = this.dateFormatter.defaultDateFormat( taskResource.getAuthoredOnDate() );
      authoredOnDateFormattedLong = this.dateFormatter.defaultDateFormat( taskResource.getAuthoredOnDate(), true );
      let tmp3 = new Date( taskResource.getAuthoredOnDate() );
      authoredOnTS = tmp3.getTime();
    }
    
    
    let statusReason = taskResource.getReasonStatusCode();
    
    var observations = [];
    let questionnaireResponse = null;
    let bundleReferenceUrl = taskResource.getFocusReferenceUrl();
    let focusBundleResource = window.appEntityStore.getEntityByResourceReference( { "resReference" : bundleReferenceUrl } );
    if( focusBundleResource ){
      // let compositions = focusBundleResource.getContainedResources( 'Composition' );
      observations = focusBundleResource.getContainedResources( 'Observation' );
      let qrs = focusBundleResource.getContainedResources( 'QuestionnaireResponse' );
      if( qrs.length > 0 && typeof qrs[0] ){
        questionnaireResponse = qrs[0];
      }
    }

    let ticketHistory = [];
    if( focusBundleResource ){
      let resources = focusBundleResource.getContainedResources( 'Provenance' );
      for( var k3 in resources ){
        let pJson = resources[ k3 ].toObject();
        let h = {
          "dateTime" : '',
          "dateTimeFormatted" : '',
          "timestamp" : 0,
          "actionDisplay" : "",
          "agentType" : "",
          "agentDisplay" : "",
          "historyRecordDisplay" : "",
          "agents" : []
        }
        
        if( typeof pJson.occurredDateTime != 'undefined' && pJson.occurredDateTime != '' ){
          h.dateTime = pJson.occurredDateTime;
          let dateFormatter = new DateFormatter();
          h.dateTimeFormatted = dateFormatter.defaultDateFormat( pJson.occurredDateTime, true );
          let tmp2 = new Date( pJson.occurredDateTime );
          h.timestamp = tmp2.getTime();
        }

        // there could be more than one agents. concat them as one. 
        if( typeof pJson.agent != 'undefined' ){
          let agentTypeCode = '';
          let agentTypeDisplay = '';
          let whoDisplay = '';
          for( var k4 in pJson.agent ){
            if( 
              typeof  pJson.agent[ k4 ].type != 'undefined' && 
              typeof  pJson.agent[ k4 ].type.coding != 'undefined'  && 
              typeof  pJson.agent[ k4 ].type.coding[ 0 ] != 'undefined'
            ){
              if( typeof  pJson.agent[ k4 ].type.coding[ 0 ].code != 'undefined'  ){
                agentTypeCode = pJson.agent[ k4 ].type.coding[ 0 ].code;
              }
              if( typeof  pJson.agent[ k4 ].type.coding[ 0 ].display != 'undefined'  ){
                agentTypeDisplay = pJson.agent[ k4 ].type.coding[ 0 ].display;
              }
            }
            
            if( 
              typeof  pJson.agent[ k4 ].who != 'undefined' && 
              typeof  pJson.agent[ k4 ].who.display != 'undefined' 
            ){
              whoDisplay =  pJson.agent[ k4 ].who.display;
            }

            // console.log( pJson.agent[ k4 ] );
            
            let oneAgentString = '';
            if( agentTypeDisplay != '' ){
              oneAgentString = agentTypeDisplay;
            } else {
              oneAgentString = agentTypeCode;
            }

            if( whoDisplay != '' ){
              if( oneAgentString == '' ){
                oneAgentString = whoDisplay;
              } else {
                oneAgentString = oneAgentString + ":" + whoDisplay;
              }
            }

            h.agents.push( oneAgentString );


          }
        }

        if( 
          typeof pJson.activity != 'undefined' && 
          typeof pJson.activity.coding != 'undefined' && 
          typeof pJson.activity.coding[ 0 ] != 'undefined'
        ){
          if( typeof pJson.activity.coding[ 0 ].display != 'undefined' ){
            h.actionDisplay = pJson.activity.coding[ 0 ].display;
          } else if ( typeof pJson.activity.coding[ 0 ].code != 'undefined' ){
            h.actionDisplay = pJson.activity.coding[ 0 ].code;
          }
        }

        if( h.dateTime != ''  ){
          h.historyRecordDisplay = h.dateTimeFormatted + ' ';
        }

        if( h.actionDisplay != '' ){
          h.historyRecordDisplay = h.historyRecordDisplay + h.actionDisplay + ' ';
        }
        /** 
        h.historyRecordDisplay = h.historyRecordDisplay + h.agentType;
        if( h.agentDisplay != '' ){
          h.historyRecordDisplay = h.historyRecordDisplay + ":" + h.agentDisplay;
        }
        */
        if( h.agents.length > 0 ){
          h.historyRecordDisplay = h.historyRecordDisplay + " " + h.agents.join( ', ' );
        }

        ticketHistory.push( h );
        h = null;
      }
    }
    
    // ticketHistory.sort(function( a, b ){
    //  return ( a.timestamp < b.timestamp ) ? -1 : 1;
    //});
    
    let patientReferenceUrl = taskResource.getPatientReferenceUrl();
    let patient = window.appEntityStore.getEntityByResourceReference( { "resReference" : patientReferenceUrl } );
    let patientGP = null; 

    let patientEmail = '';
    let patientPhone = '';
    let patientAddress = '';
    let patientIdentifierCode = taskResource.getPatientIdentifierCodeAsString();
    let patientGeneralPracticionerOrganizationCode = '';
    let patientGeneralPracticionerListCode = ''; // Practicioner organization list code starting with N
    let patientGPOrganizationDisplayName = '-';
    let patientGPDisplayName = '-';
    
    // fill in from Task resource as default
    let gpObj = taskResource.getPatienGeneralPracticionerData();
    patientGeneralPracticionerListCode = gpObj.gpListCode;
    patientGPDisplayName = gpObj.gpDisplayName;

    if( patient !=  null ){
      let tmpFullName = patient.getFullName();
      if( tmpFullName ){
        personDisplayName = tmpFullName;
      }
      let patientLiteral = patient.toObject();
      patientIdentifierCode = patient.getIdentifierCodeAsString();
      patientEmail = patient.getFirstTelecomBySystemCode( 'email' );
      patientPhone = patient.getFirstTelecomBySystemCode( 'phone' );
      patientEmail = patientEmail != null ? patientEmail.getValue() : '';
      patientPhone = patientPhone != null ? patientPhone.getValue() : '';
      patientGPDisplayName = this.getDisplayOrCodeFrom( patientLiteral.generalPractitioner[ 0 ], '-' );
      patientAddress = patient.getFirstAddressAsString();
      patientGP = window.appEntityStore.getEntityByResourceReference( { "resReference" : patientLiteral.generalPractitioner[ 0 ].reference } );
    }

    if( patientGP != null ){
      let patientGPJson = patientGP.toObject();
      if( typeof patientGPJson.organization != 'undefined' ){
        patientGPOrganizationDisplayName = this.getDisplayOrCodeFrom( patientGPJson.organization, patientGPOrganizationDisplayName );
        // "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia"
        if( 
          typeof patientGPJson.organization.identifier != 'undefined' && 
          typeof patientGPJson.organization.identifier.value != 'undefined' 
        ){
          patientGeneralPracticionerOrganizationCode = patientGPJson.organization.identifier.value;
        }
      }
    }

    let restrictionReference = taskResource.getRestrictionReference();
    let restrictionPracticioner = null;
    if( restrictionReference != null ){
      restrictionPracticioner = window.appEntityStore.getEntityByResourceReference( { "resReference" : restrictionReference.reference } );
    }
    let restrictionPracticionerId = null;
    if( restrictionPracticioner != null ){
      restrictionPracticionerId = restrictionPracticioner.getId();
    }
    
    let isOpening = false;
    if( this.waitingForTicketOpen != null ){
      if( taskResource.getId().isEqual( this.waitingForTicketOpen.taskResource.getId() ) ){
        isOpening = true;
      }
    }

    //Find ID for patientGeneralPracticionerListCode via store/cache
    let preferredRegistryIdString = "";
    if( patientGeneralPracticionerListCode !== "" ){
      let testId = this.cacheHealthcareCodeToResourceId.get( patientGeneralPracticionerListCode );
      if( testId !== null ){
        preferredRegistryIdString = testId;
      }
    }

    return {
      "ticketId" : ticketId,
      "ticketStatus" : taskResource.getStatus(),
      "ticketNumber" : ticketNumber,
      "personDisplayName" : personDisplayName, 
      "personCode" : patientIdentifierCode,
      "patientEmail" : patientEmail,
      "patientPhone" : patientPhone,
      "patientAddress" : patientAddress,
      "preferredRegistryIdString" : preferredRegistryIdString,
      "gpCode" : "-",
      "gpFullName" : "-",
      "patientGeneralPracticionerOrganizationCode" : patientGeneralPracticionerOrganizationCode,
      "patientGeneralPracticionerListCode" : patientGeneralPracticionerListCode,
      "patientGeneralPracticionerOrganizationDisplayName" : patientGPOrganizationDisplayName,
      "patientGeneralPracticionerDisplayName" : patientGPDisplayName,
      "practicionerNoteText" : taskResource.getFirstPracticionerNoteAsString(),
      "patientNoteText" : taskResource.getFirstPatientNoteAsString(),
      "serviceName" : taskResource.getTicketCategoryAsString(), 
      "additionalInfo" : taskResource.createAdditionalInfo(),
      "ticketHistory" : ticketHistory,
      "communicationMethods" : taskResource.getCommunicationMethods(),
      "communicationMethodPreferred" : taskResource.getCommunicationMethodPreferred(),
      "businessStatus" : statusReason,
      "businessStatusDisplayText" : taskResource.getReasonStatusDisplayText(),
      "authoredOnTS" : authoredOnTS,
      "authoredOnDateFormattedLong" : authoredOnDateFormattedLong,
      "executionPeriodStartTS" : timestampStart,
      "executionPeriodEndTS" : timestampEnd,
      "dateTimeFormatted" : authoredOnDateFormatted,// use authored date for ticket date
      "dateTimeFormattedLong" : authoredOnDateFormattedLong,// use authored date for ticket date
      "dateTimeStartFormatted" : dateTimeStartFormatted,
      "dateTimeStartFormattedLong" : dateTimeStartFormattedLong,
      "dateTimeEndFormatted" : dateTimeEndFormatted,
      "dateTimeEndFormattedLong" : dateTimeEndFormattedLong,
      "executionPeriodEnd" : taskResource.getExecutionPeriodEndDate(),
      "webBookingUrl" : taskResource.getWebBookingUrl(),
      "restrictionPracticionerId" : restrictionPracticionerId,
      "priority" : priority,
      "priorityCode" : priority,
      "priorityNumber" : priorityNumber,
      "priorityPreferred" : priorityPreferred,
      "isLocked" : isLocked,
      "isLockedTo" : isLockedTo,
      "taskResource" : taskResource,
      "observations" : observations,
      "questionnaireResponse" : questionnaireResponse,
      "isOpening" : isOpening,
      "previousTickets" : this.getExtractPreviousTicketsByPatient( patientReferenceUrl, ticketId )
    };
  }

  getExtractPreviousTicketsByPatient( patientReferenceUrl, currentTicketId ){
    if( !patientReferenceUrl || patientReferenceUrl === null ){
      return [];
    }

    let lists = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "List",
      "subjectReferenceUrl" : patientReferenceUrl 
    } );

    let dateFormatter = new DateFormatter();

    let previous = [];
    let parser = null;
    let taskReason  = null;
    let taskReasonText  = '';
    let ticketNumber = '-';

    for( var i1 in lists ){
      let json = lists[ i1 ].toObject();
      if(
        typeof json.code != 'undefined' && 
        typeof json.code.coding != 'undefined' &&
        typeof json.code.coding[ 0 ] != 'undefined' &&
        typeof json.code.coding[ 0 ].code != 'undefined' &&
        json.code.coding[ 0 ].code == "PATIENT-TASKS"
      ){
        for( var i2 in json.entry ){
          let entry = json.entry[ i2 ];
          
          taskReason = null;
          taskReasonText = '-';
          if(  
            typeof entry.extension != 'undefined' 
          ){
            parser = new ExtensionParser( entry );
            let taskReason = parser.findFirstExtensionObjectByUrl( 'https://tean.abtram.eu/fhir/extension/list-entry/task-code' );
            if( 
              taskReason && typeof taskReason.valueCodeableConcept != 'undefined' && 
              typeof taskReason.valueCodeableConcept.coding != 'undefined' && 
              typeof taskReason.valueCodeableConcept.coding[ 0 ] != 'undefined'
            ){
              taskReasonText = this.getDisplayOrCodeFrom( taskReason.valueCodeableConcept.coding[ 0 ], '-' );
            }

            let ticketNumberExt = parser.findFirstExtensionObjectByUrl( 'https://tean.abtram.eu/fhir/extension/list-entry/task-encounter' );
            if( 
              ticketNumberExt && typeof ticketNumberExt.valueReference != 'undefined' && 
              typeof ticketNumberExt.valueReference.identifier != 'undefined' && 
              typeof ticketNumberExt.valueReference.identifier.value != 'undefined'
            ){
              ticketNumber = ticketNumberExt.valueReference.identifier.value;
            }
          }

          let dateTimeFormatted = '-';
          if( entry.date !== null && entry.date != '' ){
            // dateTimeFormatted = entry.date.substr( 0, 10 );
            dateTimeFormatted = dateFormatter.defaultDateFormat( entry.date );
          }

          let entryTicketId = '';
          if(  
            typeof entry.item != 'undefined' && 
            typeof entry.item.reference != 'undefined' 
          ){
            let tmp = entry.item.reference;
            tmp = tmp.replace( "Task/", '' );
            entryTicketId = tmp;
          }
          
          if( currentTicketId != entryTicketId ){
            previous.push( {
              'ticketId' : entryTicketId,
              'ticketNumber' : ticketNumber,
              'serviceName' : taskReasonText,
              'dateTimeFormatted' : dateTimeFormatted
            } );
          }

        }
      }
    }

    return previous;
  }


  getDisplayOrCodeFrom( jsonObject, defaultDisplay  ){
    if( typeof jsonObject == 'undefined' ){
      return defaultDisplay;
    }

    let extractedDisplay = '';
    let extractedCode = '';

    if( typeof jsonObject.display != 'undefined' ){
      extractedDisplay = jsonObject.display;
    }
    
    if( typeof jsonObject.code != 'undefined' ){
      extractedCode = jsonObject.code;
    }

    if( extractedDisplay != '' ){
      return extractedDisplay;
    }

    if( extractedCode != '' ){
      return extractedCode;
    }
    
    return defaultDisplay;
  }


  canChangeListSubscription(){
    if( this.state.ticketMode == 'edit' ){
      return false;
    }

    if( this.state.ticketViewActive ){
      return false;
    }

    //if( this.state.ticket !== null || this.state.ticket ){
    //  console.log( 'canChangeListSubscription: 3' );
    //  console.log( this.state.ticket );
    //  return false;
    //}

    if( this.state.ticketHasPendingUpdates ){
      return false;
    }

    return true;
  }


  render() {
    var that = this;
    
    let filteredTickets = this.getFilteredTickets( this.state.tickets );
    let ticketsHeadingText = this.getFilteredTicketsHeading( filteredTickets.length );
    let ticketKey = new Date().getTime();
    
    let allowListChange = this.canChangeListSubscription();
    
    // is next-ticket workflow in loading state, opened state or no tickets to show mode? 
    let nextTicketOpeningMessage = '';
    if( this.state.activeMenuLinkId === "next-ticket" ){
      if( this.state.nextTicketWorkflowOpeningTicketId != null && this.state.nextTicketWorkflowOpeningTicketId != '' ){
        nextTicketOpeningMessage = 'Avan piletit nr ' + this.state.nextTicketWorkflowOpeningTicketNumber;
      }
    }
    
    // show only if list is loading and user is not in ticket view (has not opened any tickets)
    let showListLoading = false;

    let anyTicketsSelfOpening = false;
    for( var n1 in filteredTickets ){
      if( filteredTickets[ n1 ].isOpening ){
        anyTicketsSelfOpening = true;
      }
    }

    if( this.state.updatingTicketsList && !this.state.ticketViewActive && !anyTicketsSelfOpening ){
      showListLoading = true;
    }

    let wrapperClasses = '';
    if ( showListLoading ) {
      wrapperClasses = 'state-list-loading';
    }
    
    return (
      <Grid container className={wrapperClasses} spacing={3} key="health-analyst-tool-container" data-force={this.state.forceUpdate}>
        
        <div className="general-spin"></div>

        <Grid item xs={12}>
          <TicketListSelector 
            data-render-cnt={this.state.forceReRender}
            onMessageDialogShowRequest={this.showMessageDialog}
            allowListChange={allowListChange} 
          />
        </Grid>

        <Grid item xs={12} key="health-analyst-tool-grid-item">
          
          <BadgeMenu menuItems={this.state.menuItems} onBadgeClick={this.handleBadgeMenuClick} />
          <br />

          {( !this.state.ticketViewActive && this.state.activeMenuLinkId !== "next-ticket"  ) && (
            <div>
              <div className="tickets-table-top">
                <h3 className="pull-left">{ticketsHeadingText}</h3>
              </div>
              <br />
              <TicketsTable tickets={filteredTickets} onTicketclick={this.handleHealthTicketRequestOpen} key="health-analyst-tool-tickets-table" />
            </div>
          )}

          {( !this.state.ticketViewActive && this.state.activeMenuLinkId === "next-ticket" ) && (
            <div>
              <div className="tickets-table-top" style={{"overflow" : "hidden" }}>
                <h3 className="pull-left">Järgmine pilet</h3>
              </div>
              <Paper elevation={3} className="next-ticket-dashboard" key="health-analyst-tool-next-ticket-dashboard">
                <br />
                <br />
                {( nextTicketOpeningMessage ) && (
                  <ContentLoading message={nextTicketOpeningMessage} />
                )}

                {( that.state.nextTicketWorkflowAllDone ) && (
                  <div className="all-tickets-done">
                    Tähelepanu ootavad piletid puuduvad
                  </div>
                )}

              </Paper>
            </div>
          )}
          
          {(this.state.ticketViewActive && this.state.ticket ) && (
            <Paper elevation={3} className="inline-ticket" key="health-analyst-tool-inline-ticket">
              <HealthTicket 
                key={this.state.ticketId} 
                ticket={this.state.ticket} 
                mode={this.state.ticketMode}
                previousTickets={this.state.ticket.previousTickets}
                onTicketEditRequest = {this.handleTicketEditRequestClick}
                onTicketExit={this.handleTicketExitRequest}
                onSetTicketModified={this.handleSetTicketModified}
                onTicketUpdate={this.handleTicketUpdate}
                onAfterBookingPartDone={this.handleAfterBookingPartDone}
                ref={this.childTicket} 
              />
            </Paper>
          )}

          <Dialog
            key="health-analyst-tool-dialog"
            open={this.state.askTicketCloseAndSave}
            onClose={this.handleDialogAskTicketSaveClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Sulge lahtiolev pilet?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Kas olete kindel, et soovite avatud piletiga töö lõpetada. 
                Kõik senised muudatused lähevad kaduma. 
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogAskTicketSaveCloseDisAgree} color="primary">
                Ära sulge
              </Button>
              <Button onClick={this.handleDialogAskTicketSaveCloseAgree} color="secondary" autoFocus>
                Sulge pilet
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            key="health-analyst-tool-dialog-take-over"
            open={this.state.askTicketTakeOver}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Kas soovite töö üle võtta?"}</DialogTitle>
            <DialogContent>
              {(this.state.ticketViewActive && this.state.ticket != null ) && (
                <DialogContentText id="alert-dialog-description">
                  See pilet on hetkel "{this.state.ticket.isLockedTo}" käes. Kas soovite 
                  selle üle võtta? 
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogAskTicketTakeOverDisAgree} color="primary">
                Katkesta
              </Button>
              <Button onClick={this.handleDialogAskTicketTakeOverAgree} color="secondary" autoFocus>
                Võta üle
              </Button>
            </DialogActions>
          </Dialog>


          
          <Dialog
            key="health-analyst-tool-dialog-message"
            open={this.state.messageDialogShow}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.messageDialogTitle}</DialogTitle>
            <DialogContent>
              <div dangerouslySetInnerHTML={{__html: this.state.messageDialogContent }} /> 
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogMessageAgree} color="primary">
                Selge
              </Button>
            </DialogActions>
          </Dialog>
          
        </Grid>


        {/*
        <Modal
          open={this.state.ticketViewActive}
          onClose={this.handleTicketExit}
        >
          <Paper elevation={3} className="modal-ticket">
            <HealthTicket 
              ticket={this.state.ticket} 
              previousTickets={prevTickets} 
            />
          </Paper>
        </Modal>
        */}
      </Grid>
    );
  }

}

export default HealthAnalystTool;
