
'use strict';

import AuthToken from './AuthToken.js';

/**
 * Container/ for holding and passing around authenticated user 
 * basic info for app context
 * 
 *   - auth token
 *   - identification code
 *   - who issued that code. 
 * 
 * That is enough to initiate API calls behalf given user. 
 * 
 * @param boolean isAuthenticated
 * @param AuthToken token
 * @return this
 */
function AppAuthStatus ( authenticated, token ) {
  
  var authenticated = typeof authenticated != 'undefined' ? authenticated : false;

  this.isUserAuthenticated = authenticated;

  try {
    // cant be logged in without token value
    if( !token.hasValue() ){
      this.isUserAuthenticated = false;
    }
  } catch( err ) {
    //console.log( 'Needed AuthToken objects- none given. Error: ' + err  );
    this.isUserAuthenticated = false;
    var token = new AuthToken();
  }
  
  this.authToken = token;
  
  return this;
}


/**
 * 
 * 
 * @return AuthToken
 */
AppAuthStatus.prototype.getAuthToken = function( ) {
  return this.authToken;
};


/**
 * 
 * 
 * @return boolean
 */
AppAuthStatus.prototype.isAuthenticated = function( ) {
  
  if( this.authToken.hasValue() && this.isUserAuthenticated ){
    return true;
  }

  return false;
};


export default AppAuthStatus;