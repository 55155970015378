/**
 * Value object describing entity related meta data in store
 * 
 * "lastUpdated": "2020-01-09T19:57:23.089+00:00"
 * 
 * 
 * @see: 
 *   https://www.hl7.org/fhir/resource.html#Meta
 * 
 */


class ResourceMeta {

  /**
   * 
   */
  constructor() {
    this._sourceUrl = null;
    this._lastUpdated = null;
  }


  /**
   * 
   * @pram string source Source URL
   * @return void
   */
  setSourceUrl( sourceUrl ) {
    this._sourceUrl = sourceUrl;
  }


  /**
   * 
   * @pram string dateTime
   * @return void
   */
  setLastUpdatedFromString( dateTime ) {
    this._lastUpdated = dateTime;
  }


  /**
   * 
   * 
   * @return string
   */
  getSourceUrl() {
    return this._sourceUrl;
  }


  /**
   * 
   * 
   * @return string
   */
  getLastUpdatedAsString() {
    return this._lastUpdated;
  }


  /**
   * 
   * @param object
   * @return 
   */
  setFromObject( obj ) {
    if( obj && typeof obj != "undefined" && typeof obj.source != "undefined" && obj.source ){
      this.setSourceUrl( obj.source );
    }
    if( obj && typeof obj != "undefined" && typeof obj.lastUpdated != "undefined" && obj.lastUpdated ){
      this.setLastUpdatedFromString( obj.lastUpdated );
    }
  }


  /**
   * 
   * 
   * @return object
   */
  toObject() {
    let obj = {};

    if( this.getSourceUrl() != null ){
      obj.source = this.getSourceUrl();
    }

    if( this.getLastUpdatedAsString() != null ){
      obj.lastUpdated = this.getLastUpdatedAsString();
    }

    return obj;
  }
  
}


export { ResourceMeta };