"use strict";

import React from "react";

class LoginText extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showVerificationCode } = this.props;

    if (showVerificationCode) {
      return (
        <p className="padding-top-10">
          Sõnum on saadetud Teie mobiiltelefoni. Palun kontrollige, et kontrollkood oleks sama, mis on ekraanil. Sisestage PIN1 kood. 
        </p>
      );
    }

    return null;

    return (
      <p className="padding-top-10">
        Sisselogimiseks Smart-ID-ga sisestage oma isikukood. Seejärel saadetakse Teie mobiiltelefonile kontrollsõnum.
      </p>
    );
  }
}

export default LoginText;
