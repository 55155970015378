"use strict";

/**
 * Represents one Bundle resource (DomainResource)
 * 
 *
 */

import { DomainResource } from './../DomainResource/DomainResource';
import BundleParser from './../Utils/BundleParser.js';
import { DomainResourceStore } from './../DomainResourceStore/DomainResourceStore.js';


class Bundle extends DomainResource {

  /**
   * Bundle
   * 
   * @param ResourceId resourceId
   * @return void
   */
  constructor(
    resourceId
  ) {
    super( 'Bundle' );

    this._id = resourceId;
    this._subResourcesParsed = false;
    this._bundleParser = new BundleParser( {
      "tryExtractIdFromFullUrl" : true
    } );
    this._store = new DomainResourceStore();
  }


  /**
   * Overwrite parent method 
   * 
   * Set local variables based on input JSON
   * 
   * @param object json
   * @return boolean
   */
  setRawResourceObject( json ) {
    super.setRawResourceObject( json );
    this._subResourcesParsed = false;
  }


  /**
   * -
   * 
   * @param string resourceType
   * @return array
   */
  getContainedResources( resourceType ) {
    this.parseSubResources();

    if( resourceType == 'all' ){
      return this._store.getAllResourcesBySearchParams( {
      } );
    }

    return this._store.getAllResourcesBySearchParams( {
      "resourceType" : resourceType
    } );
  }


  /**
   * -
   * 
   * @return array
   */
   getContainedResourceByReferenceUrl( url ) {
    this.parseSubResources();
    return this._store.getEntityByResourceReference( { "resReference" :  url } );
  }


  
  parseSubResources() {
    if( this._subResourcesParsed ){
      return;
    }

    this._subResourcesParsed = true;

    this._bundleParser.handleJson(
      this._resourceLiteral, this._store
    );
  }


  /**
   * Overwrite parent method 
   * 
   * Build basic resource literal
   * This needs to be overwritten by actual resource
   * 
   * @return object
   */
  buidResourceLiteral() {
    super.buidResourceLiteral();

    //Dont build- it's already as json
    if( this._resourceSetByLiteral ){
      return;
    }
    
  }

}

export default Bundle;
