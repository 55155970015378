"use strict";

/**
 * Temporary fake data for TEAN project
 *
 * @return void
 */

 import { ResourceMeta } from './DomainResource/ResourceMeta';
 import { DomainResource } from './DomainResource/DomainResource';
 import SubscriptionResource from './Subscription/Subscription.js';
 import TaskResource from './Task/Task.js';
 import BundleResource from './Bundle/Bundle.js';
 import BundleParser from './Utils/BundleParser.js';
 

/**
 * -
 *
 * @return void
 */
function FakeResources() {
  this.timer = null;
}


FakeResources.prototype.init = function( ) {
  var that = this;

  this.parser = new BundleParser();
  
  let tempTimerTI = setTimeout( function(){
    that.parser.handleJson(
      that.getJsonTiBundle(), window.appEntityStore
    );
  } , 800 );

  let tempTimerCI = setTimeout( function(){
    that.parser.handleJson(
      that.getJsonCiBundle(), window.appEntityStore
    );
  } , 1200 );
  
  let tempTimerPI = setTimeout( function(){
    that.parser.handleJson(
      that.getJsonPiBundle(), window.appEntityStore
    );
  } , 1600 );
  
  let tempTimer3 = setTimeout( function(){
    that.parser.handleJson(
      that.getJsonPracticionersBundle(), window.appEntityStore
    );
  } , 100 );
  
};



FakeResources.prototype.getJsonTiBundle = function( ) {

  let d = new Date();
  d.setTime( d.getTime() + (78*60*1000) );
  let dateEnd = d.toISOString();

  return {
    "resourceType": "Bundle",
    "type": "searchset",
    "timestamp": "2021-08-03T22:22:59.334+03:00",
    "entry": [
      {
        "fullUrl": "https://tean.abtram.eu/fhir/Task/83944f43-236d-45f2-9cb1-52dec401dde0",
        "resource": {
          "resourceType": "Task",
          "id": "83944f43-236d-45f2-9cb1-52dec401dde0",
          "meta": {
            "versionId": "9e86ae32-a7ed-4894-8e68-4def847a0dbc",
            "lastUpdated": "2021-08-03T22:22:59.322+03:00"
          },
          "status": "ready",
          "statusReason": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codesystem/task-status-reason",
                "code": "NEW",
                "display": "New task from patient"
              }
            ]
          },
          "intent": "?",
          "priority": "routine",
          "code": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-category",
                "code": "HEALTH-ISSUE",
                "display": "Tervisemure"
              }
            ]
          },
          "focus": {
            "reference": "https://tean.abtram.eu/fhir/Bundle/7debd759-4326-423c-960b-3252f43f926c"
          },
          "encounter": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
              "value": "661"
            }
          },
          "executionPeriod": {
            "start": "2021-08-03T22:22:59+03:00",
            "end": dateEnd
          },
          "authoredOn": "2021-08-03T22:22:59+03:00",
          "lastModified": "2021-08-03T22:22:59+03:00",
          "requester": {
            "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
            "display": "Mart Tamm"
          },
          "owner": {
            "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
            "display": "Nelli Mänd"
          },
          "reasonCode": {
            "coding": [
              {
                "system": "http://hl7.org/fhir/sid/icd-10",
                "code": "M43.6",
                "display": "Kõverkaelsus"
              },
              {
                "system": "http://hl7.org/fhir/sid/icd-10",
                "code": "M43.6",
                "display": "Kaela muude ja täpsustamata osade liigeste ja sidemete distorsioon ja distensioon"
              }
            ]
          },
          "note": [
            {
              "authorReference": {
                "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                "display": "Mart Tamm"
              },
              "time": "2021-08-03T22:22:59+03:00",
              "text": "patsiendi mure tekst"
            },
            {
              "authorReference": {
                "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
                "display": "Nelli Mänd"
              },
              "time": "2021-08-03T22:22:59+03:00",
              "text": "terviseanalüütiku täpsustav teks"
            }
          ],
          "relevantHistory": [
            {
              "reference": "https://tean.abtram.eu/fhir/Provenance/provenance-1",
              "display": "The issue arrived"
            },
            {
              "reference": "https://tean.abtram.eu/fhir/Provenance/provenance-2",
              "display": "The issue was postponed"
            }
          ],
          "restriction": {
          },
          "output": [
            {
              "type": {
                "coding": [
                  {
                    "system": "https://tean.abtram.eu/fhir/codingsystem/random-other-codesystem",
                    "code": "random-test-1"
                  }
                ]
              },
              "valueCode": "random-test-1"
            },
            {
              "type": {
                "coding": [
                  {
                    "system": "https://tean.abtram.eu/fhir/codingsystem/task-communication-method",
                    "code": "phone"
                  }
                ]
              },
              "valueCode": "phone"
            },
            {
              "type": {
                "coding": [
                  {
                    "system": "https://tean.abtram.eu/fhir/codingsystem/task-communication-method",
                    "code": "email"
                  }
                ]
              },
              "valueCode": "email"
            },
            {
              "type": {
                "coding": [
                  {
                    "system": "https://tean.abtram.eu/fhir/codingsystem/random-other-codesystem",
                    "code": "random-test-2"
                  }
                ]
              },
              "valueCode": "random-test-2"
            },
            {
              "type": {
                "coding": [
                  {
                    "system": "https://tean.abtram.eu/fhir/codingsystem/random-other-codesystem",
                    "code": "random-test-3"
                  }
                ]
              },
              "valueCode": "random-test-3"
            }
          ]
        },
        "search": {
          "mode": "match"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/Patient/1",
        "resource": {
          "resourceType": "Patient",
          "id": "1",
          "implicitRules": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
              "value": "34567890123"
            }
          ],
          "name": [
            {
              "family": "Tamm",
              "given": [
                "Mart"
              ]
            }
          ],
          "telecom": [
            {
              "system": "email",
              "value": "minuasi@gmail.com",
              "rank": 1
            },
            {
              "system": "phone",
              "value": "+3725555001",
              "rank": 0
            }
          ],
          "address": [
            {
              "text": "Metsa 3 Uugametsa 34234"
            }
          ],
          "generalPractitioner": [
            {
              "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/mk1",
              "display": "Marta Kask"
            }
          ]
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/mk1",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "mk1",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
              "value": "N2345"
            }
          ],
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D123456"
            },
            "display": "Marta Kask"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L03853"
            },
            "display": "Terviseagentuur OÜ"
          }
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "2",
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D234567"
            },
            "display": "Nelli Mänd"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L042144"
            },
            "display": "Terviseagentuur OÜ"
          }
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/Bundle/7debd759-4326-423c-960b-3252f43f926c",
        "resource": {
          "resourceType": "Bundle",
          "id": "7debd759-4326-423c-960b-3252f43f926c",
          "identifier": {
            "system": "https://tean.abtram.eu/fhir/namingsystem/bundle-identifier",
            "value": "minudoc-health-issue-345"
          },
          "type": "document",
          "timestamp": "2021-08-03T22:22:59.160+03:00",
          "entry": [
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Composition/cc1",
              "resource": {
                "resourceType": "Composition",
                "id": "cc1",
                "identifier": {
                  "system": "https://tean.abtram.eu/fhir/namingsystem/ticket",
                  "value": "234-1"
                },
                "status": "final",
                "type": {
                  "coding": [
                    {
                      "system": "https://tean.abtram.eu/fhir/codingsystem/document-type",
                      "code": "TICKET",
                      "display": "Pre-appointment ticket"
                    }
                  ]
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "author": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                    "display": "Mart Tamm"
                  }
                ],
                "title": "Näidissõnum: MinuDoc poolt koostatud tervisemure koos intervjuu vastuste, sümptomite, ja riskihinnanguga",
                "section": [
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/document-section",
                          "code": "INTERVIEW",
                          "display": "Interview questions and answers"
                        }
                      ]
                    },
                    "entry": [
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                      }
                    ]
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/document-section",
                          "code": "EVIDENCE",
                          "display": "Observed factors and symptoms"
                        }
                      ]
                    },
                    "entry": [
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o1"
                      },
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o2"
                      },
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o3"
                      }
                    ]
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/document-section",
                          "code": "ASSESSMENT",
                          "display": "Evaluated conditions"
                        }
                      ]
                    },
                    "entry": [
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/RiskAssessment/ra1"
                      }
                    ]
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/document-section",
                          "code": "ISSUE",
                          "display": "Patient's request"
                        }
                      ]
                    },
                    "entry": [
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/ServiceRequest/sr1"
                      }
                    ]
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
              "resource": {
                "resourceType": "Patient",
                "id": "xxx1",
                "implicitRules": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                "identifier": [
                  {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
                    "value": "34567890123"
                  }
                ],
                "name": [
                  {
                    "family": "Tamm",
                    "given": [
                      "Mart"
                    ]
                  }
                ],
                "telecom": [
                  {
                    "system": "email",
                    "value": "minuasi@gmail.com",
                    "rank": 1
                  },
                  {
                    "system": "phone",
                    "value": "+3725555001",
                    "rank": 0
                  }
                ],
                "address": [
                  {
                    "text": "Metsa 3 Uugametsa 34234"
                  }
                ],
                "generalPractitioner": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/mk1",
                    "display": "Marta Kask"
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/mk1",
              "resource": {
                "resourceType": "PractitionerRole",
                "id": "mk1",
                "identifier": [
                  {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
                    "value": "N2345"
                  }
                ],
                "practitioner": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
                    "value": "D123456"
                  },
                  "display": "Marta Kask"
                },
                "organization": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
                    "value": "L03853"
                  },
                  "display": "Terviseagentuur OÜ"
                }
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy",
              "resource": {
                "resourceType": "QuestionnaireResponse",
                "id": "1yy",
                "identifier": {
                  "system": "https://tean.abtram.eu/fhir/namingsystem/interview-infermedica",
                  "value": "661"
                },
                "status": "completed",
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "authored": "2021-08-03T22:22:58+03:00",
                "author": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "source": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "item": [
                  {
                    "linkId": "l_4871",
                    "text": "How strong is your headache?",
                    "item": [
                      {
                        "linkId": "s_1781",
                        "text": "Moderate",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4872",
                    "text": "Have you often had similar headaches within the last three months?",
                    "item": [
                      {
                        "linkId": "s_1912",
                        "text": "No",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4873",
                    "text": "How long have you had your headache for?",
                    "item": [
                      {
                        "linkId": "s_2189",
                        "text": "Less than 1 hour",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4874",
                    "text": "Did your headache start all of a sudden and reach its peak in a few minutes?",
                    "item": [
                      {
                        "linkId": "s_1905",
                        "text": "Headache, sudden onset",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4875",
                    "text": "Does your throat or nose itch or feel tingly?",
                    "item": [
                      {
                        "linkId": "s_252",
                        "text": "Itchy throat or nose",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "absent",
                              "display": "No"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4876",
                    "text": "Which of the following worsen your headache?",
                    "item": [
                      {
                        "linkId": "s_252",
                        "text": "It is worse in the morning",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "absent",
                              "display": "No"
                            }
                          }
                        ]
                      },
                      {
                        "linkId": "s_1762",
                        "text": "Stress",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "absent",
                              "display": "No"
                            }
                          }
                        ]
                      },
                      {
                        "linkId": "s_625",
                        "text": "Bending down",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "absent",
                              "display": "No"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4877",
                    "text": "Where is your headache located?",
                    "item": [
                      {
                        "linkId": "s_1349",
                        "text": "Front of the head",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4878",
                    "text": "Do you have a runny nose?",
                    "item": [
                      {
                        "linkId": "s_107",
                        "text": "Nasal catarrh",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "absent",
                              "display": "No"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4879",
                    "text": "Is your neck twisted, and does it hurt when you try to straighten it?",
                    "item": [
                      {
                        "linkId": "s_2163",
                        "text": "Twisted neck",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4879",
                    "text": "Is your neck twisted, and does it hurt when you try to straighten it?",
                    "item": [
                      {
                        "linkId": "s_2163",
                        "text": "Twisted neck",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4880",
                    "text": "Have you recently had any trauma or physical injury?",
                    "item": [
                      {
                        "linkId": "p_264",
                        "text": "Recent physical injury",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "absent",
                              "display": "No"
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "linkId": "l_4880",
                    "text": "Do you have muscle pain, aches, or soreness?",
                    "item": [
                      {
                        "linkId": "s_1142",
                        "text": "Myalgia",
                        "answer": [
                          {
                            "valueCoding": {
                              "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                              "code": "present",
                              "display": "Yes"
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Observation/o1",
              "resource": {
                "resourceType": "Observation",
                "id": "o1",
                "basedOn": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                  }
                ],
                "status": "final",
                "code": {
                  "coding": [
                    {
                      "system": "https://tean.abtram.eu/fhir/codingsystem/evidence-source-infermedica",
                      "code": "PREDEFINED-GENERIC",
                      "display": "GENERIC"
                    }
                  ]
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "issued": "2021-08-03T22:22:58.049+03:00",
                "performer": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                    "display": "Mart Tamm"
                  }
                ],
                "derivedFrom": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                  }
                ],
                "component": [
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "p_7",
                          "display": "BMI above 30"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "absent",
                          "display": "No"
                        }
                      ]
                    }
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "p_9",
                          "display": "Hypertension"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "absent",
                          "display": "No"
                        }
                      ]
                    }
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "p_10",
                          "display": "High cholesterol"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "absent",
                          "display": "No"
                        }
                      ]
                    }
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "p_28",
                          "display": "Smoking"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "absent",
                          "display": "No"
                        }
                      ]
                    }
                  },
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "p_8",
                          "display": "Diabetes"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "absent",
                          "display": "No"
                        }
                      ]
                    }
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Observation/o2",
              "resource": {
                "resourceType": "Observation",
                "id": "o2",
                "basedOn": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                  }
                ],
                "status": "final",
                "code": {
                  "coding": [
                    {
                      "system": "https://tean.abtram.eu/fhir/codingsystem/evidence-source-infermedica",
                      "code": "PREDEFINED-LOCATION",
                      "display": "LOCATION"
                    }
                  ]
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "issued": "2021-08-03T22:22:58.050+03:00",
                "performer": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                    "display": "Mart Tamm"
                  }
                ],
                "derivedFrom": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                  }
                ],
                "component": [
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "p_15",
                          "display": "Recent travel to Europe"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "present",
                          "display": "Yes"
                        }
                      ]
                    }
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Observation/o3",
              "resource": {
                "resourceType": "Observation",
                "id": "o3",
                "basedOn": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                  }
                ],
                "status": "final",
                "code": {
                  "coding": [
                    {
                      "system": "https://tean.abtram.eu/fhir/codingsystem/evidence-source-infermedica",
                      "code": "INTERVIEW",
                      "display": "INTERVIEW"
                    }
                  ]
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "issued": "2021-08-03T22:22:58.050+03:00",
                "performer": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                    "display": "Mart Tamm"
                  }
                ],
                "derivedFrom": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/QuestionnaireResponse/1yy"
                  }
                ],
                "component": [
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/observations-infermedica",
                          "code": "s_21",
                          "display": "Headache"
                        }
                      ]
                    },
                    "valueCodeableConcept": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                          "code": "present",
                          "display": "Yes"
                        }
                      ]
                    }
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/RiskAssessment/ra1",
              "resource": {
                "resourceType": "RiskAssessment",
                "id": "ra1",
                "status": "final",
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "performer": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/device-identifier",
                    "value": "INFERMEDICA3"
                  },
                  "display": "Infermedica V3"
                },
                "basis": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o1"
                  },
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o2"
                  },
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o3"
                  }
                ],
                "prediction": [
                  {
                    "outcome": {
                      "coding": [
                        {
                          "system": "http://hl7.org/fhir/sid/icd-10",
                          "code": "M43.6",
                          "display": "Kõverkaelsus"
                        },
                        {
                          "system": "http://hl7.org/fhir/sid/icd-10",
                          "code": "M43.6",
                          "display": "Kaela muude ja täpsustamata osade liigeste ja sidemete distorsioon ja distensioon"
                        },
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/conditions-infermedica",
                          "code": "c_975",
                          "display": "Cervical strain / Neck strain"
                        }
                      ],
                      "text": "Cervical strain / Neck strain"
                    },
                    "probabilityDecimal": 0.9547
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/ServiceRequest/sr1",
              "resource": {
                "resourceType": "ServiceRequest",
                "id": "sr1",
                "status": "active",
                "intent": "proposal",
                "category": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-category",
                        "code": "HEALTH-ISSUE",
                        "display": "Tervisemure"
                      }
                    ]
                  }
                ],
                "priority": "urgent",
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "authoredOn": "2021-08-03T22:22:58+03:00",
                "requester": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                  "display": "Mart Tamm"
                },
                "locationCode": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-location",
                        "code": "ONLINE",
                        "display": "Veebikõne"
                      }
                    ]
                  }
                ],
                "reasonReference": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o1"
                  },
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o2"
                  },
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Observation/o3"
                  }
                ],
                "supportingInfo": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/RiskAssessment/ra1"
                  }
                ],
                "note": [
                  {
                    "authorReference": {
                      "reference": "https://tean.abtram.eu/fhir/bundle/Patient/xxx1",
                      "display": "Mart Tamm"
                    },
                    "time": "2021-08-03T22:22:58+03:00",
                    "text": "Mul on ikka täitsa suur mure selle peavaluga!"
                  }
                ]
              }
            }
          ]
        },
        "search": {
          "mode": "include"
        }
      }
    ]
  }
  
  ;
}



FakeResources.prototype.getJsonCiBundle = function( ) {

  return {
    "resourceType": "Bundle",
    "type": "searchset",
    "timestamp": "2021-08-03T22:22:59.353+03:00",
    "entry": [
      {
        "fullUrl": "https://tean.abtram.eu/fhir/Task/fe8a6273-202b-440b-918b-29091a32b96e",
        "resource": {
          "resourceType": "Task",
          "id": "fe8a6273-202b-440b-918b-29091a32b96e",
          "meta": {
            "versionId": "c6ea8586-a9dc-4ca5-9820-f8e7a3ab51db",
            "lastUpdated": "2021-08-03T22:22:59.326+03:00"
          },
          "status": "on-hold",
          "statusReason": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codesystem/task-status-reason",
                "code": "QUEUED",
                "display": "Task has been referred to a physician"
              }
            ]
          },
          "intent": "?",
          "priority": "routine",
          "code": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-category",
                "code": "MEDICATION-ISSUE",
                "display": "Ravimimure"
              }
            ]
          },
          "focus": {
            "reference": "https://tean.abtram.eu/fhir/Bundle/ea50de82-3d8e-4b43-a95a-e965148fb9c7"
          },
          "encounter": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
              "value": "661"
            }
          },
          "executionPeriod": {
            "start": "2021-08-03T22:22:59+03:00",
            "end": "2021-08-04T22:22:59+03:00"
          },
          "authoredOn": "2021-08-03T22:22:59+03:00",
          "lastModified": "2021-08-03T22:22:59+03:00",
          "requester": {
            "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
            "display": "Mart Tamm"
          },
          "owner": {
          },
          "reasonCode": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codingsystem/medication-package",
                "code": "1738874",
                "display": "AGODEPRIN"
              }
            ]
          },
          "note": [
            {
              "authorReference": {
                "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                "display": "Mart Tamm"
              },
              "time": "2021-08-03T22:22:59+03:00",
              "text": "patsiendi mure tekst"
            },
            {
              "authorReference": {
                "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
                "display": "Nelli Mänd"
              },
              "time": "2021-08-03T22:22:59+03:00",
              "text": "terviseanalüütiku täpsustav teks"
            }
          ],
          "relevantHistory": [
            {
              "reference": "https://tean.abtram.eu/fhir/Provenance/provenance-1",
              "display": "The issue arrived"
            },
            {
              "reference": "https://tean.abtram.eu/fhir/Provenance/provenance-2",
              "display": "The issue was postponed"
            }
          ],
          "restriction": {
            "recipient": [
              {
                "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
                "display": "Marta Kask"
              }
            ]
          }
        },
        "search": {
          "mode": "match"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/Patient/1",
        "resource": {
          "resourceType": "Patient",
          "id": "1",
          "implicitRules": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
              "value": "34567890123"
            }
          ],
          "name": [
            {
              "family": "Tamm",
              "given": [
                "Mart"
              ]
            }
          ],
          "telecom": [
            {
              "system": "email",
              "value": "minuasi@gmail.com",
              "rank": 1
            },
            {
              "system": "phone",
              "value": "+3725555001",
              "rank": 0
            }
          ],
          "address": [
            {
              "text": "Metsa 3 Uugametsa 34234"
            }
          ],
          "generalPractitioner": [
            {
              "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
              "display": "Marta Kask"
            }
          ]
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "1",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
              "value": "N2345"
            }
          ],
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D123456"
            },
            "display": "Marta Kask"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L03853"
            },
            "display": "Terviseagentuur OÜ"
          }
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "2",
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D234567"
            },
            "display": "Nelli Mänd"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L042144"
            },
            "display": "Terviseagentuur OÜ"
          }
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/Bundle/ea50de82-3d8e-4b43-a95a-e965148fb9c7",
        "resource": {
          "resourceType": "Bundle",
          "id": "ea50de82-3d8e-4b43-a95a-e965148fb9c7",
          "identifier": {
            "system": "https://tean.abtram.eu/fhir/namingsystem/bundle-identifier",
            "value": "minudoc-prescription-issue-345"
          },
          "type": "document",
          "timestamp": "2021-08-03T22:22:59.168+03:00",
          "entry": [
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Composition/1",
              "resource": {
                "resourceType": "Composition",
                "id": "1",
                "identifier": {
                  "system": "https://tean.abtram.eu/fhir/namingsystem/ticket",
                  "value": "234-1"
                },
                "status": "final",
                "type": {
                  "coding": [
                    {
                      "system": "https://tean.abtram.eu/fhir/codingsystem/document-type",
                      "code": "TICKET",
                      "display": "Pre-appointment ticket"
                    }
                  ]
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "author": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                    "display": "Mart Tamm"
                  }
                ],
                "title": "Näidissõnum: MinuDoc poolt koostatud retseptimure",
                "section": [
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/document-section",
                          "code": "ISSUE",
                          "display": "Patient's request"
                        }
                      ]
                    },
                    "entry": [
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/ServiceRequest/2"
                      }
                    ]
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Patient/1",
              "resource": {
                "resourceType": "Patient",
                "id": "1",
                "implicitRules": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                "identifier": [
                  {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
                    "value": "34567890123"
                  }
                ],
                "name": [
                  {
                    "family": "Tamm",
                    "given": [
                      "Mart"
                    ]
                  }
                ],
                "telecom": [
                  {
                    "system": "email",
                    "value": "minuasi@gmail.com",
                    "rank": 1
                  },
                  {
                    "system": "phone",
                    "value": "+3725555001",
                    "rank": 0
                  }
                ],
                "address": [
                  {
                    "text": "Metsa 3 Uugametsa 34234"
                  }
                ],
                "generalPractitioner": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
                    "display": "Marta Kask"
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
              "resource": {
                "resourceType": "PractitionerRole",
                "id": "1",
                "identifier": [
                  {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
                    "value": "N2345"
                  }
                ],
                "practitioner": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
                    "value": "D123456"
                  },
                  "display": "Marta Kask"
                },
                "organization": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
                    "value": "L03853"
                  },
                  "display": "Terviseagentuur OÜ"
                }
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/ServiceRequest/2",
              "resource": {
                "resourceType": "ServiceRequest",
                "id": "2",
                "status": "active",
                "intent": "proposal",
                "category": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-category",
                        "code": "MEDICATION-ISSUE",
                        "display": "Ravimimure"
                      }
                    ]
                  }
                ],
                "priority": "routine",
                "orderDetail": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/medication-package",
                        "code": "1738874",
                        "display": "AGODEPRIN"
                      }
                    ]
                  }
                ],
                "quantityQuantity": {
                  "value": 10,
                  "unit": "tbl"
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "authoredOn": "2021-08-03T22:22:58+03:00",
                "requester": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                  "display": "Mart Tamm"
                },
                "locationCode": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-location",
                        "code": "WRITTEN",
                        "display": "Kirjalik vastus"
                      }
                    ]
                  }
                ],
                "note": [
                  {
                    "authorReference": {
                      "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                      "display": "Mart Tamm"
                    },
                    "time": "2021-08-03T22:22:58+03:00",
                    "text": "Kirjutan igaks juhuks sõnadega ka. Vaja on neid sinisetriibulises karbis tablette. Kahest karbist piisab."
                  }
                ]
              }
            }
          ]
        },
        "search": {
          "mode": "include"
        }
      }
    ]
  }
}

FakeResources.prototype.getJsonPiBundle = function( ) {

  let d = new Date();
  d.setTime( d.getTime() + (25*60*1000) );
  let dateEnd = d.toISOString();
  
  return {
    "resourceType": "Bundle",
    "type": "searchset",
    "timestamp": "2021-08-03T22:22:59.357+03:00",
    "entry": [
      {
        "fullUrl": "https://tean.abtram.eu/fhir/Task/e8d723d3-bcb7-4cfc-acf3-240508d2b78c",
        "resource": {
          "resourceType": "Task",
          "id": "e8d723d3-bcb7-4cfc-acf3-240508d2b78c",
          "meta": {
            "versionId": "287d6bdd-0bcf-4b7e-ad4b-83cc7fefb68f",
            "lastUpdated": "2021-08-03T22:22:59.326+03:00"
          },
          "status": "in-progress",
          "statusReason": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codesystem/task-status-reason",
                "code": "REJECTED",
                "display": "Task has been rejected by a physician"
              }
            ]
          },
          "intent": "?",
          "priority": "routine",
          "code": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-category",
                "code": "CERTIFICATE-ISSUE",
                "display": "Tõendimure"
              }
            ]
          },
          "focus": {
            "reference": "https://tean.abtram.eu/fhir/Bundle/9ae8e910-c5c1-42b3-9dbd-ed77f8209b10"
          },
          "encounter": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
              "value": "661"
            }
          },
          "executionPeriod": {
            "start": "2021-08-03T22:22:59+03:00",
            "end": dateEnd
          },
          "authoredOn": "2021-08-03T22:22:59+03:00",
          "lastModified": "2021-08-03T22:22:59+03:00",
          "requester": {
            "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
            "display": "Mart Tamm"
          },
          "owner": {
            "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
            "display": "Nelli Mänd"
          },
          "reasonCode": {
            "coding": [
              {
                "system": "https://tean.abtram.eu/fhir/codingsystem/health-certificate-type",
                "code": "1",
                "display": "I grupi mootorsõidukijuht"
              }
            ]
          },
          "note": [
            {
              "authorReference": {
                "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                "display": "Mart Tamm"
              },
              "time": "2021-08-03T22:22:59+03:00",
              "text": "patsiendi mure tekst"
            },
            {
              "authorReference": {
                "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
                "display": "Nelli Mänd"
              },
              "time": "2021-08-03T22:22:59+03:00",
              "text": "terviseanalüütiku täpsustav teks"
            }
          ],
          "relevantHistory": [
            {
              "reference": "https://tean.abtram.eu/fhir/Provenance/provenance-1",
              "display": "The issue arrived"
            },
            {
              "reference": "https://tean.abtram.eu/fhir/Provenance/provenance-2",
              "display": "The issue was postponed"
            }
          ],
          "restriction": {
            "recipient": [
              {
                "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
                "display": "Marta Kask"
              }
            ]
          }
        },
        "search": {
          "mode": "match"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/Patient/1",
        "resource": {
          "resourceType": "Patient",
          "id": "1",
          "implicitRules": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
              "value": "34567890123"
            }
          ],
          "name": [
            {
              "family": "Tamm",
              "given": [
                "Mart"
              ]
            }
          ],
          "telecom": [
            {
              "system": "email",
              "value": "minuasi@gmail.com",
              "rank": 1
            },
            {
              "system": "phone",
              "value": "+3725555001",
              "rank": 0
            }
          ],
          "address": [
            {
              "text": "Metsa 3 Uugametsa 34234"
            }
          ],
          "generalPractitioner": [
            {
              "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
              "display": "Marta Kask"
            }
          ]
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "1",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
              "value": "N2345"
            }
          ],
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D123456"
            },
            "display": "Marta Kask"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L03853"
            },
            "display": "Terviseagentuur OÜ"
          }
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "2",
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D234567"
            },
            "display": "Nelli Mänd"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L042144"
            },
            "display": "Terviseagentuur OÜ"
          }
        },
        "search": {
          "mode": "include"
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/Bundle/9ae8e910-c5c1-42b3-9dbd-ed77f8209b10",
        "resource": {
          "resourceType": "Bundle",
          "id": "9ae8e910-c5c1-42b3-9dbd-ed77f8209b10",
          "identifier": {
            "system": "https://tean.abtram.eu/fhir/namingsystem/bundle-identifier",
            "value": "minudoc-certificate-issue-345"
          },
          "type": "document",
          "timestamp": "2021-08-03T22:22:59.170+03:00",
          "entry": [
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Composition/1",
              "resource": {
                "resourceType": "Composition",
                "id": "1",
                "identifier": {
                  "system": "https://tean.abtram.eu/fhir/namingsystem/ticket",
                  "value": "234-1"
                },
                "status": "final",
                "type": {
                  "coding": [
                    {
                      "system": "https://tean.abtram.eu/fhir/codingsystem/document-type",
                      "code": "TICKET",
                      "display": "Pre-appointment ticket"
                    }
                  ]
                },
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "author": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                    "display": "Mart Tamm"
                  }
                ],
                "title": "Näidissõnum: MinuDoc poolt koostatud tõendimure",
                "section": [
                  {
                    "code": {
                      "coding": [
                        {
                          "system": "https://tean.abtram.eu/fhir/codingsystem/document-section",
                          "code": "ISSUE",
                          "display": "Patient's request"
                        }
                      ]
                    },
                    "entry": [
                      {
                        "reference": "https://tean.abtram.eu/fhir/bundle/ServiceRequest/3"
                      }
                    ]
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/Patient/1",
              "resource": {
                "resourceType": "Patient",
                "id": "1",
                "implicitRules": "https://tean.abtram.eu/fhir/codingsystem/answer-code-infermedica",
                "identifier": [
                  {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
                    "value": "34567890123"
                  }
                ],
                "name": [
                  {
                    "family": "Tamm",
                    "given": [
                      "Mart"
                    ]
                  }
                ],
                "telecom": [
                  {
                    "system": "email",
                    "value": "minuasi@gmail.com",
                    "rank": 1
                  },
                  {
                    "system": "phone",
                    "value": "+3725555001",
                    "rank": 0
                  }
                ],
                "address": [
                  {
                    "text": "Metsa 3 Uugametsa 34234"
                  }
                ],
                "generalPractitioner": [
                  {
                    "reference": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
                    "display": "Marta Kask"
                  }
                ]
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/1",
              "resource": {
                "resourceType": "PractitionerRole",
                "id": "1",
                "identifier": [
                  {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
                    "value": "N2345"
                  }
                ],
                "practitioner": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
                    "value": "D123456"
                  },
                  "display": "Marta Kask"
                },
                "organization": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
                    "value": "L03853"
                  },
                  "display": "Terviseagentuur OÜ"
                }
              }
            },
            {
              "fullUrl": "https://tean.abtram.eu/fhir/bundle/ServiceRequest/3",
              "resource": {
                "resourceType": "ServiceRequest",
                "id": "2",
                "status": "active",
                "intent": "proposal",
                "category": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-category",
                        "code": "CERTIFICATE-ISSUE",
                        "display": "Tõendimure"
                      }
                    ]
                  }
                ],
                "priority": "asap",
                "orderDetail": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/health-certificate-type",
                        "code": "1",
                        "display": "I grupi mootorsõidukijuht"
                      }
                    ]
                  }
                ],
                "subject": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                  "display": "Mart Tamm"
                },
                "encounter": {
                  "identifier": {
                    "system": "https://tean.abtram.eu/fhir/namingsystem/encounter-minudoc",
                    "value": "661"
                  }
                },
                "authoredOn": "2021-08-03T22:22:58+03:00",
                "requester": {
                  "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                  "display": "Mart Tamm"
                },
                "locationCode": [
                  {
                    "coding": [
                      {
                        "system": "https://tean.abtram.eu/fhir/codingsystem/ticket-location",
                        "code": "WRITTEN",
                        "display": "Kirjalik vastus"
                      }
                    ]
                  }
                ],
                "note": [
                  {
                    "authorReference": {
                      "reference": "https://tean.abtram.eu/fhir/bundle/Patient/1",
                      "display": "Mart Tamm"
                    },
                    "time": "2021-08-03T22:22:58+03:00",
                    "text": "Soovin autojuhilubade jaoks tervisetõendit."
                  }
                ]
              }
            }
          ]
        },
        "search": {
          "mode": "include"
        }
      }
    ]
  };
}


FakeResources.prototype.getJsonPracticionersBundle = function( ) {
  return {
    "resourceType": "Bundle",
    "type": "collection",
    "timestamp": "2021-07-21T12:31:40.551+03:00",
    "entry": [
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/2",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "2",
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D234567"
            },
            "display": "Nelli Mänd"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L042144"
            },
            "display": "Terviseagentuur OÜ"
          }
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/111",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "111",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
              "value": "N2345"
            }
          ],
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D123456"
            },
            "display": "Marta Kask2"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L03853"
            },
            "display": "Terviseagentuur OÜ"
          }
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/113",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "113",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
              "value": "N2345"
            }
          ],
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D123987"
            },
            "display": "Kadri Kask"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L03855"
            },
            "display": "Mustla perearstikeskus OÜ"
          }
        }
      },
      {
        "fullUrl": "https://tean.abtram.eu/fhir/bundle/PractitionerRole/99991111",
        "resource": {
          "resourceType": "PractitionerRole",
          "id": "99991111",
          "identifier": [
            {
              "system": "https://tean.abtram.eu/fhir/namingsystem/patient-list-estonia",
              "value": "N99991111"
            }
          ],
          "practitioner": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
              "value": "D99991111"
            },
            "display": "Tõnu Tamm"
          },
          "organization": {
            "identifier": {
              "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
              "value": "L03853"
            },
            "display": "Terviseagentuur OÜ"
          }
        }
      },
    ]
  };

}

export { FakeResources };
