/**
 * 
 * 
 * 
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import EmployeeTable from './EmployeeTable';
import PersonAddOutlined from '@material-ui/icons/PersonAddOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmployeeForm from './EmployeeForm'


class EmployeeManagement extends React.Component {

  constructor( props ) {
    super( props );

    this._isMounted = false;
    
    this.state = {
      "forceReRender" : 1,
      "updatingList" : false,

      "userAccessibleOrganizationsListLoading" : true,
      "userAccessibleOrganizationsList" : [],
      "currentOrganizationCode" : "",
      "currentOrganizationId" : "",
      "currentOrganizationDisplay" : "",
      
      "employeesList" : [],
      
      "viewMode" : "employeeList", // employeeFormOpen
      "employeeRecord" : null,

      "employeePracticionerRoleId" : "",
      
      "messageDialogShow" : false,
      "messageDialogContext" : "dialog",
      "messageDialogContent" : "",
      "messageDialogTitle" : "",
      "messageYesText" : "",
      "messageNoText" : ""
    }
    
    this.myRef = React.createRef();
    this.timerStoreUpdated = null;

    this.handleEmployeeOpen = this.handleEmployeeOpen.bind( this );
    this.handleOrganizationChangeRequest = this.handleOrganizationChangeRequest.bind( this );
    this.handleEmployeeFormClose = this.handleEmployeeFormClose.bind( this );
    this.handleQuitJobConfirm = this.handleQuitJobConfirm.bind( this );
    
    this.showMessageDialog = this.showMessageDialog.bind( this );
    this.handleDialogMessageAgree = this.handleDialogMessageAgree.bind( this );
    this.handleDialogMessageDisAgree = this.handleDialogMessageDisAgree.bind( this );
  }



  componentDidMount() {
    this._isMounted = true;
    var that = this;

    setTimeout(function(){
      that.updateAccessibleOrganizationsList();
    }, 1000 );
    
    //setTimeout(function(){
    //  that.debugFakeRoleChange();
    //}, 5000 );

    var updateOrganizationsList = function (eventPayLoad) {
      if (that._isMounted) {
        if( that.timerStoreUpdated !== null ){
          clearTimeout( that.timerStoreUpdated );
          that.timerStoreUpdated = null;
        }
        that.timerStoreUpdated = setTimeout(function(){
          console.log("ServerCommunicationHandeled done!");
          that.updateAccessibleOrganizationsList();
        }, 500 );
      }
    };

    window.appEventsEmitter.addListener("ServerCommunicationHandeled", updateOrganizationsList);
    this.listenerUpdateAccessibleRoles = updateOrganizationsList;

    var updateEmployeeList = function (eventPayLoad) {
      if (that._isMounted) {
        
      }
    };
    
    window.appEventsEmitter.emit( 'rendered', { "eventSource" : "EmployeeManagement" } );
  }


  componentWillUnmount() {
    this._isMounted = false;

    window.appEventsEmitter.removeListener(
      "ServerCommunicationHandeled",
      this.listenerUpdateAccessibleRoles
    );
  }


  debugFakeRoleChange(){
    let prs = this.getUserPractitionerRoles();
    
    for( var i in prs ){
      let useId = prs[ i ].getId();
      let prLiteral = prs[ i ].toObject();
      prLiteral.extension[ 0 ].valueCoding.code = "ADMIN";
      
      prs[ i ].setRawResourceObject( prLiteral );
      prs[ i ].setId( useId );
      window.appEntityStore.saveResource( prs[ i ] );
    }
  }

  
  /**
   * 
   * @param string orgId
   * @return array
   */
   getHealthCareRegisterOptionsByOrganizationId( orgId ){
    let list = [];

    let resources = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "HealthcareService"
    } );

    for( var i in resources ){
      let test = resources[ i ].toObject();
      let parts = test.providedBy.reference.split( "Organization/" ); // from reference like "https://tean.abtram.eu/fhir/Organization/2c1b77d2xxxxxef8904b1b8"
      if( typeof parts[1] !== "undefined" ){
        let foundOrgId = parts[1];
        let code = "";

        if( foundOrgId === orgId && typeof test.identifier !== "undefined" ){
          // find code also 
          for( var i3 in test.identifier ){
            if(
              test.identifier[ i3 ].system === "https://tean.abtram.eu/fhir/namingsystem/gp-patient-listing-estonia" && 
              typeof test.identifier[ i3 ].value !== "undefined" 
            ){
              code = test.identifier[ i3 ].value;
            }
          }

          list.push( {
            "id" : test.id,
            "code" : code,
            "display" : test.name
          } );
        }
      }

    }

    // FOR DEMO- later filter HealthcareService-es by 
    // HealthcareService.providedBy on organisatsioon.. PractitionerRole.organization on sama organisatsioon. Kõik HealthcareService, millel on saama org, sobivad.
    /* getHealthCareRegisterOptions
      providedBy : 
        display :  "Osaühing Perearstide Keskus Neeme"
        reference :  "https://tean.abtram.eu/fhir/Organization/6cb113e4-3922-42c9-851e-7b577ddb839e"

    let list = [];
    list.push( {
      "id" : "N0001",
      "display" : "N0001 - Demo nimistu"
    } );
    list.push( {
      "id" : "N0021",
      "display" : "N0021 - Test nimistu"
    } );
    */

    return list;
  }


  /**
   * Is currentOrganizationCode currently selected in the new list? 
   * If yes- keep it. If form open- close it. 
   * 
   * @return void
   */
  updateAccessibleOrganizationsList(){
    //since this is called in timeout also
    if( !this._isMounted ){
      return;
    }

    let list = this.getUserOrganizationList();
    let oldCode = this.state.currentOrganizationCode;
    let oldId   = this.state.currentOrganizationId;
    let oldDisplay = this.state.currentOrganizationDisplay;
    let found = false;

    let selectedCode = null;
    let selectedDisplay = null;
    let selectedId = null;

    for( var i in list ){
      if( list[ i ].code === oldCode ){
        found = true;
      }
      selectedCode = list[ i ].code;
      selectedDisplay = list[ i ].code;
      selectedId = list[ i ].code;
    }

    if( list.length === 1 && !found && this.state.viewMode === "employeeList" ){
      // Maybe we could change currentOrganizationCode to the only one (select 1 by default)
      // But only when no eployee record is open and modified. 
      // This is not the problem when loaded first time. 
      console.log( "Select only organization list instantly" );
      if( this.state.viewMode === "employeeList" ){
        /*
        this.setState({
          "userAccessibleOrganizationsList" : list,
          "userAccessibleOrganizationsListLoading": false,
          "currentOrganizationCode" : selectedCode,
          "currentOrganizationId"   : selectedId,
          "currentOrganizationDisplay" : selectedDisplay,
          "viewMode" : "employeeList",
          "updatingList" : true,
          "employeePracticionerRoleId" : "",
          "employeeRecord" : null
        });
        */
        this.setState({
          "userAccessibleOrganizationsList" : list,
          "userAccessibleOrganizationsListLoading": false
        });
        var that = this;
        var sCode = selectedCode;
        let tm = setTimeout(function(){
          that.handleOrganizationChangeRequest( null, false, sCode );
        }, 10 );
        return;
      }
    }
    
    if( found ){
      this.setState({
        "userAccessibleOrganizationsList" : list,
        "userAccessibleOrganizationsListLoading": false,
        "currentOrganizationCode" : oldCode,
        "currentOrganizationId"   : oldId,
        "currentOrganizationDisplay" : oldDisplay,
        "viewMode" : "employeeList",
        "employeePracticionerRoleId" : "",
        "employeeRecord" : null
      });
      var that = this;
      let tm = setTimeout(function(){
        that.handleOrganizationChangeDone();
      }, 100 );
    } else {
      this.setState({
        "userAccessibleOrganizationsList" : list,
        "userAccessibleOrganizationsListLoading": false
      });
    }
  }

  
  /**
   * Get logged in user's Person DomainResource 
   * 
   * There should only be one Person in store (logged in user itself), but we should use 
   * logged in user set in login view instead in future?
   * 
   * @returns DomainResource od Person
   */
  getLoggedInPerson(){
    let persons = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "Person"
    } );

    for( var i in persons ){
      return persons[ i ];
    }
    
    return null;
  }
  

  /**
   * Get list of PractitionerRoles that user has found by identifier code
   * We use Estonian national identification code only at moment (https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia)
   * 
   * 
   * @returns array of PractitionerRole DomainResources
   */
  getUserPractitionerRoles(){
    let person = this.getLoggedInPerson();

    if( person === null ){
      return [];
    }

    let personLiteral = person.toObject();
    var personIdCode = null;

    for( var ii in personLiteral.identifier ){
      if( 
        typeof personLiteral.identifier[ ii ].system !== "undefined" && 
        personLiteral.identifier[ ii ].system === "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia" && 
        typeof personLiteral.identifier[ ii ].value !== "undefined" && 
        personLiteral.identifier[ ii ].value
      ){
        personIdCode = personLiteral.identifier[ ii ].value;
      }
    }

    if( personIdCode === null ){
      return [];
    }
    
    let practitionerRoles = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "PractitionerRole"
    } );
    
    let roles = [];

    for( var ii in practitionerRoles ){
      let prLiteral = practitionerRoles[ ii ].toObject();
      let foundMatch = false;

      for( var ii2 in prLiteral.identifier ){
        if( 
          typeof prLiteral.identifier[ ii2 ].system !== "undefined" && 
          typeof prLiteral.identifier[ ii2 ].system !== "undefined" && 
          prLiteral.identifier[ ii2 ].system === "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia" && 
          typeof prLiteral.identifier[ ii2 ].value !== "undefined" && 
          prLiteral.identifier[ ii2 ].value === personIdCode
        ){
          foundMatch = true;
        }
      }

      if( foundMatch ){
        roles.push( practitionerRoles[ ii ] );
      }
    }

    return roles;
  }


  /**
   * Get list of organization that user has access to
   * 
   * 
   * @returns array
   */
  getUserOrganizationList(){
    let userRoles = this.getUserPractitionerRoles();
    let roleIds = [];

    for( var i4 in userRoles ){
      let prLiteral = userRoles[ i4 ].toObject();
      let foundOrgId = null;
      let foundAdminRole = false; // ADMIN / HEALT-ANALYST HEALT_ANALYST

      if( 
        typeof prLiteral.organization !== "undefined" && 
        typeof prLiteral.organization.reference !== "undefined" 
      ){
        let parts = prLiteral.organization.reference.split( "Organization/" ); // from reference like "https://tean.abtram.eu/fhir/Organization/2c1b77d2-0728-4b6e-b9b2-f7ef8904b1b8"
        if( typeof parts[1] !== "undefined" ){
          foundOrgId = parts[1];
        }
      }

      // check role too!
      for( var i5 in prLiteral.code ){
        if( typeof prLiteral.code[ i5 ].coding !== "undefined" ){
          
          for( var n5 in prLiteral.code[ i5 ].coding ){
            if(
              typeof prLiteral.code[ i5 ].coding[ n5 ].system !== "undefined" && 
              prLiteral.code[ i5 ].coding[ n5 ].system === "https://tean.abtram.eu/fhir/codesystem/practitioner-role/code" && 
              prLiteral.code[ i5 ].coding[ n5 ].code === "ADMIN"
            ){
              foundAdminRole = true;
            }
          }
        }
      }
      
      if( foundOrgId !== null && foundAdminRole ){
        roleIds.push( foundOrgId );
      }
    }


    let organizationList = [];
    
    let all = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "Organization"
    } );

    for( var i in all ){
      let obj = this.createOrganizationObject( all[ i ] );


      if( obj !== null ){
        let matchedOrgId = false;
        for( var i2 in roleIds ){
          if( roleIds[ i2 ] === obj.id ){
            matchedOrgId = true;
          }
        }
        if( matchedOrgId ){
          organizationList.push( obj );
        }
      }
    }

    // FOR DEMO
    /*  
    let obj = {
      "code" : "D213123123",
      "displayName" : "Organisatsioon D213123123"
    };
    organizationList.push( obj );

    obj = {
      "code" : "10047645",
      "displayName" : "Organisatsioon 10047645"
    };
    organizationList.push( obj );
    
    obj = {
      "code" : "757567567576",
      "displayName" : "Organisatsioon 757567567576"
    };
    organizationList.push( obj );
    
    obj = {
      "code" : "11227184",
      "displayName" : "Terviseagentuur OÜ"
    };
    organizationList.push( obj );
    */
    
    return organizationList;
  }


  /**
   * 
   * @param Organization organization DomainResource
   * @return object
   */
  createOrganizationObject( organization ){
    let literal = organization.toObject();

    let findCode = null;

    for( var ii in literal.identifier ){
      if( 
        typeof literal.identifier[ ii ].system !== "undefined" && 
        literal.identifier[ ii ].system === "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia" && 
        typeof literal.identifier[ ii ].value !== "undefined" && 
        literal.identifier[ ii ].value
      ){
        findCode = literal.identifier[ ii ].value;
      }
    }

    if( findCode === null ){
      return null;
    }

    let obj = {
      "id" : literal.id,
      "code" : findCode,
      "displayName" : findCode
    };

    if( typeof literal.name !== "undefined" && literal.name ){
      obj.displayName = literal.name;
    }
    
    return obj;
  }


  handleOrganizationChangeRequest( event, fromUserClick, newValueInp ){
    if( fromUserClick ){
      if( this.state.viewMode !== "employeeList" ){
        this.showMessageDialog( 'Probleem', 'Enne organisatsiooni muutmist palun sulgege või salvestage käesolev töötaja vorm. ' );
        return;
      }
    }

    let newValue = newValueInp;

    if( fromUserClick ){
      newValue = event.target.value;
    }
    
    let newId = null;
    let newDisplay = null;

    // find id for this code
    for( var ii in this.state.userAccessibleOrganizationsList ){
      if( this.state.userAccessibleOrganizationsList[ ii ].code === newValue ){
        newId = this.state.userAccessibleOrganizationsList[ ii ].id;
        newDisplay = this.state.userAccessibleOrganizationsList[ ii ].displayName;
      }   
    }

    if( newId === null ){
      alert("Probleem organisatsiooni valikuga. ");
    }

    this.setState({
      "updatingList" : true,
      "currentOrganizationCode" : newValue,
      "currentOrganizationId" : newId,
      "currentOrganizationDisplay" : newDisplay,
      "viewMode" : "employeeList",
      "employeesList" : []
    });

    var that = this;
    let tm = setTimeout(function(){
      that.handleOrganizationChangeDone();
    }, 100 );
  }


  handleOrganizationChangeDone(){
    //since this is called in timeout also
    if( !this._isMounted ){
      return;
    }

    let list = this.getEmployeeList( this.state.currentOrganizationCode );

    this.setState({
      "updatingList" : false,
      "employeesList" : list
    });
  }
  

  /**
   * Get roles by Organization
   * Employee's are actualy PracticionerRole-s
   * 
   * @param string organizationCode
   * @returns array
   */
  getEmployeeList( organizationCode ){
    let all = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "PractitionerRole"
    } );
    
    // 
    let currentPersonPersonalCode = null;
    let person = this.getLoggedInPerson();
    if( person !== null ){
      let literal = person.toObject();
      for( var ii in literal.identifier ){
        if( 
          typeof literal.identifier[ ii ].system !== "undefined" && 
          literal.identifier[ ii ].system === "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia" && 
          typeof literal.identifier[ ii ].value !== "undefined" && 
          literal.identifier[ ii ].value
        ){
          currentPersonPersonalCode = literal.identifier[ ii ].value;
        }
      }
    }

    let employesList = [];
    
    for( var i in all ){
      let isInOrgnization = this.isPracticionerRoleInOrganization( organizationCode, all[ i ] );
      if( isInOrgnization ){
        let obj = this.createEmployeeObjectFromResource( all[ i ] );
        if( currentPersonPersonalCode !== null && obj !== null  && currentPersonPersonalCode === obj.personalCode ){
          obj.isCurrentUserPr = true;
        }
        if( obj !== null ){
          employesList.push( obj );
        }
      }
    }
    
    return employesList;
  }


  /**
   * 
   * @param string organizationCode
   * @param PractitionerRole practicionerRole 
   * @return object
   */
  isPracticionerRoleInOrganization( organizationCode, practicionerRole ){
    let tmp = practicionerRole.toObject();

    if( 
      typeof tmp.organization !== "undefined" &&  
      typeof tmp.organization.reference !== "undefined" 
    ){
      let code = this.getOrganizationCodeByOrganizationReference( tmp.organization.reference );
      if( code === organizationCode ){
        return true;
      }
    }

    return false;
  }


  /**
   * Get Organization from local store via reference like: 
   * "https://tean.abtram.eu/fhir/Organization/173c7983-49f3-4493-889e-6e3598afe293" 
   * and find it's code
   * 
   * @param string reference 
   * @return string
   */
  getOrganizationCodeByOrganizationReference( reference ){
    let parts = reference.split("Organization/");
    
    if( typeof parts[1] === "undefined" || !parts[1] ){
      return null;
    }
    
    let item = window.appEntityStore.getResourceById( parts[1] );
    if( item === null ){
      return null;
    }

    let obj = item.toObject();
    
    if( 
      typeof obj.identifier !== "undefined" && 
      typeof obj.identifier[ 0 ] !== "undefined" && 
      typeof obj.identifier[ 0 ].system !== "undefined" && 
      typeof obj.identifier[ 0 ].value !== "undefined" && 
      obj.identifier[ 0 ].system === "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia"
    ){
      return obj.identifier[ 0 ].value;
    }

    return null;
  }
  

  /**
   * 
   * @param PractitionerRole practicionerRole 
   * @return object
   */
   createEmployeeObjectFromResource( practitionerRole ){
    let practitionerRoleId = practitionerRole.getId().getResourceId();
    let object = practitionerRole.toObject();

    let employee = this.createEmployeeBaseObject();

    employee.id = object.id;

    if( typeof object.active !== "undefined" ){
      if( !object.active ){
        return null;
      }
    }

    if( 
      typeof object.practitioner !== "undefined" && 
      typeof object.practitioner.display !== "undefined"
    ){
      employee.displayName = object.practitioner.display;
    }
    
    if( 
      typeof object.practitioner !== "undefined" && 
      typeof object.practitioner.identifier !== "undefined" && 
      typeof object.practitioner.identifier.value !== "undefined"
    ){
      employee.healthcareWorkerCode = object.practitioner.identifier.value;
    }
    
    // Original data from MEDRE
    if( 
      typeof object.meta !== "undefined" && 
      typeof object.meta.profile !== "undefined" 
    ){
      for( var i4 in object.meta.profile ){
        if( object.meta.profile[i4] === "http://tean.abtram.eu/fhir/profile/practitionerrole/medre-sync-practitioner" ){
          employee.isExternallyManaged = true;
        }
      }
    }
    
    // MEDRE / MANUAL data?
    if( typeof object.extension !== "undefined" ){
      for( var i1 in object.extension ){
        let test = object.extension[ i1 ];
        if( 
          typeof test.url !== "undefined" && 
          test.url === "https://tean.abtram.eu/fhir/extension/practitionerrole/management-mode" && 
          typeof test.valueCoding !== "undefined" && 
          typeof test.valueCoding.code !== "undefined" && 
          typeof test.valueCoding.system !== "undefined" && 
          typeof test.valueCoding.system !== "https://tean.abtram.eu/fhir/codesystem/practitionerrole/management-mode" && 
          test.valueCoding.code === "MANUAL"
        ){
          employee.isManuallyManaged = true;
        }
      }
    }
    
    // find Estonian personalCode
    if( typeof object.identifier !== "undefined" ){
      for( var i1 in object.identifier ){
        let test = object.identifier[ i1 ];
        if( typeof test.system !== "undefined" && typeof test.value !== "undefined" && test.system === "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia" ){
          employee.personalCode = test.value;
        }
      }
    }
    
    // find phone number
    if( typeof object.telecom !== "undefined" ){
      for( var i1 in object.telecom ){
        let test = object.telecom[ i1 ];
        if( typeof test.system !== "phone" && typeof test.value !== "undefined" ){
          employee.contactPhone = test.value;
        }
      }
    }

    // find registries ( healthcareServices )
    // reference  https://tean.abtram.eu/fhir/HealthcareService/f4a5bed0-1xxxxb5d8d9d884d6"
    if( typeof object.healthcareService !== "undefined" ){
      for( var i1 in object.healthcareService ){
        let test = object.healthcareService[ i1 ];
        if( 
          typeof test.display && 
          typeof test.reference !== "undefined" 
        ){
          // find Resource from local store via ID to obtain code
          //reference  "https://tean.abtram.eu/fhir/HealthcareService/f4a5bexxxxd9d884d6"
          let parts = test.reference.split("HealthcareService/");
          if( typeof parts[1] !== "undefined" && parts[1] ){
            let reg = {
              "id" : parts[1],
              "display" : test.display
            };
            employee.healthCareRegisters.push( reg );
          }
        }
      }
    }

    // Find main role - code[ n ] - extension alla ikka MANUAL / MEDRE
    //   https://tean.abtram.eu/fhir/codesystem/practitioner-role/code
    
    if( typeof object.code !== "undefined" ){
      for( var i5 in object.code ){
        if( typeof object.code[ i5 ].coding !== "undefined" ){
          for( var i6 in object.code[ i5 ].coding ){
            if(
              typeof object.code[ i5 ].coding[ i6 ].system !== "undefined" && 
              object.code[ i5 ].coding[ i6 ].system === "https://tean.abtram.eu/fhir/codesystem/practitioner-role/code" && 
              typeof object.code[ i5 ].coding[ i6 ].code !== "undefined"){
                employee.mainRoleCode = object.code[ i5 ].coding[ i6 ].code;
                employee.hasMainRole = true;

                // backward compatibility (2023-01) just in case- correct is with underscore instead
                if( employee.mainRoleCode === 'HEALTH-ANALYST' ){
                  employee.mainRoleCode = 'HEALTH_ANALYST';
                }
              }
          }
        }
      }
    }
    
    /*
    // For DEMO
    if( 
      practitionerRoleId.indexOf( "janek" ) > -1 || 
      practitionerRoleId.indexOf( "f5e" ) > -1 || 
      practitionerRoleId.indexOf( "1632" ) > -1 || 
      practitionerRoleId.indexOf( "ivar" ) > -1 
    ){
      // janek-id  f5e4b47a-ed12-460d-8801-cf635d95cfb8   1632299389-131-1  ivar-id
      employee.hasMainRole = true;
      employee.mainRoleCode = "HEALTH_ANALYST"; // HEALTH_ANALYST FHC_ADMIN ADMIN RED-ANALYST
    }

    if( 
      practitionerRoleId.indexOf( "35" ) > -1 || 
      practitionerRoleId.indexOf( "mari" ) > -1 
    ){
      // janek-id  f5e4b47a-ed12-460d-8801-cf635d95cfb8   1632299389-131-1  ivar-id
      employee.hasMainRole = true;
      employee.mainRoleCode = "FHC_ADMIN"; // FHC_ADMIN ADMIN RED-ANALYST
    }
    */

    return employee;
  }
  

  /**
   * 
   * @param string organization 
   * @param string organizationDisplay 
   * @return object
   */
   createNewEmployeeBaseObject( organizationId, organizationDisplay ){

    let employee = this.createEmployeeBaseObject();


    return employee;
  }
  

  /**
   * 
   * @return object
   */
   createEmployeeBaseObject( ){

    let employee = {
      id: "",
      isCurrentUserPr : false,
      practitionerRoleId: null,
      resourceId: "",
      isExternallyManaged: false,
      isManuallyManaged : false,
      hasMainRole: false,
      mainRoleCode: "",
      contactPhone: "",
      personalCode: "",
      displayName: "",
      healthcareWorkerCode: "",
      healthCareRegisters: []
    }

    return employee;
  }


  handleEmployeeOpen( employeeRecord ){
    var that = this;

    if( this.state.updatingList ){
      console.log( "Employee opening rejected- list is still loading" );
      return;
    }

    if( employeeRecord === null ){
      this.setState({
        "viewMode" : "employeeFormOpen",
        "employeePracticionerRoleId" : "",
        "employeeRecord" : this.createNewEmployeeBaseObject()
      });
    } else {
      this.setState({
        "viewMode" : "employeeFormOpen",
        "employeePracticionerRoleId" : employeeRecord.id,
        "employeeRecord" : employeeRecord
      });
    }
  }


  /**
   * 
   * @param Event event 
   * @param boolean hasPendingUpdates 
   * @return void
   */
  handleEmployeeFormClose( event, hasPendingUpdates){

    if( hasPendingUpdates ){
      this.showYesNoDialog(
        "Kindel?",
        "Oled kindel, et soovite lahkuda salvestamata?",
        "employeeFormEdited"
      );
      return;
    }

    this.employeeFormClose();
  }


  /**
   * Close employee form instantly
   * 
   * @return void
   */
  employeeFormClose(){
    this.setState({
      "viewMode" : "employeeList",
      "employeePracticionerRoleId" : "",
      "employeeRecord" : null
    });

    // Update list with new data instantly- actually should wait update from server- or it will be waited in employee form already?
    this.handleOrganizationChangeDone();
  }

  // Show confirm dialog
  handleQuitJobConfirm(){
    this.showYesNoDialog(
      "Kindel?",
      "Oled kindel?",
      "employeeFormQuitJobAsk"
    );
    return;
  }


  /**
   * -
   * 
   * @param string title
   * @param string message
   * @return void
   */
  showMessageDialog( title, message ){
    
    this.setState( {
      "messageDialogShow" : true,
      "messageDialogContext" : "dialog",
      "messageDialogContent" : message,
      "messageDialogTitle" : title,

      // Only option - to close
      "messageYesText" : "Selge",
      "messageNoText" : ""
    });
  }


  /**
   * -
   * 
   * @param string title
   * @param string message
   * @param string askContext
   * @return void
   */
  showYesNoDialog( title, message, askContext ){
    this.setState( {
      "messageDialogShow" : true,
      "messageDialogContext" : askContext,
      "messageDialogContent" : message,
      "messageDialogTitle" : title,
      "messageYesText" : "Jah",
      "messageNoText" : "Ei"
    });
  }


  /**
   * -
   * 
   * @return void
   */
  handleDialogMessageAgree(){

    if( this.state.messageDialogContext === "employeeFormQuitJobAsk" ){
      this.myRef.current.jobQuitConfirmed();
    }

    if( this.state.messageDialogContext === "employeeFormEdited" ){
      this.employeeFormClose();
    }

    this.setState({
      "messageDialogShow" : false,
      "messageDialogTitle" : ''
    });
  }


  /**
   * Just close the dialog
   * 
   * @return void
   */
   handleDialogMessageDisAgree(){
    
    if( this.state.messageDialogContext === "employeeFormQuitJobAsk" ){
      this.myRef.current.jobQuitCanceled();
    }

    if( this.state.messageDialogContext === "employeeFormEdited" ){
      this.myRef.current.CloseOrSaveCanceled();
    }

    this.setState({
      "messageDialogShow" : false
    });
  }


  render() {
    var that = this;
    

    let wrapperClasses = '';
    if ( this.state.updatingList ) {
      wrapperClasses = 'state-list-loading';
    }

    let healthCareRegisterOptions = this.getHealthCareRegisterOptionsByOrganizationId( this.state.currentOrganizationId );
    
    if( this.state.userAccessibleOrganizationsList.length < 1 && !this.state.userAccessibleOrganizationsListLoading ){
      return (
        <Grid container className={wrapperClasses} spacing={3} key="employee-management-container" data-force={this.state.forceUpdate}>
          <Alert severity="info">
            Teil pole ligipääsu ühegi organisatsiooni töötajatele. 
          </Alert>
        </Grid>
      );
    }

    if( this.state.userAccessibleOrganizationsListLoading ){
      return (
        <Grid container className={wrapperClasses} spacing={3} key="employee-management-container" data-force={this.state.forceUpdate}>
          <Alert severity="info">
            Organisatsioonide nimekirja laetakse!
          </Alert>
        </Grid>
      );
    }
    
    
    return (
      <Grid container className={wrapperClasses} spacing={3} key="employee-management-container" data-force={this.state.forceUpdate}>

        <Grid item xs={12} spacing={3} className="select-organization" >
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">Valige organisatsioon</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.currentOrganizationCode}
              onChange={(e) => this.handleOrganizationChangeRequest( e, true, null )}
              label="Valige organisatsioon"
            >
              
              {this.state.userAccessibleOrganizationsList.map( function(organizationObj, index){
                return (
                  <MenuItem value={organizationObj.code} key={organizationObj.code} >{organizationObj.displayName} </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        
        <div className="general-spin"></div>

        
        {( this.state.viewMode === "employeeList" ) && (
          <Grid item xs={12} key="employee-management-grid-item">
            <div className="employees-table-top">
              <h3 className="pull-left">Töötajad</h3>

              {( this.state.currentOrganizationCode !== "" && this.state.viewMode === "employeeList" ) && (
                <Button 
                  className="add-employee-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(e) => that.handleEmployeeOpen( null )}
                  startIcon={<PersonAddOutlined />}
                >
                  Lisa töötaja
                </Button>
              )}
            </div>
            {( this.state.employeesList.length > 0 ) && (
              <EmployeeTable 
                key="employee-management-employee-table"
                employees={this.state.employeesList}
                onRowClick={this.handleEmployeeOpen} 
              />
            )}
          </Grid>
        )}

        {( this.state.viewMode === "employeeFormOpen" ) && (
          <Grid item xs={12} >
            <Paper elevation={3} className="inline-ticket employee-form-inline" key="employee-management-inline-eployee-form">
              <EmployeeForm 
                key="EmployeeForm1"
                employeeRoleId={this.state.employeePracticionerRoleId} 
                mode="edit"
                currentOrganizationId={this.state.currentOrganizationId}
                currentOrganizationDisplay={this.state.currentOrganizationDisplay}
                healthCareRegisterOptions={healthCareRegisterOptions}
                employeeRecord={this.state.employeeRecord}
                onFormClose={(e, hasPendingUpdates) => that.handleEmployeeFormClose( e, hasPendingUpdates )}
                onQuitJobConfirm={() => that.handleQuitJobConfirm()}
                ref={this.myRef}
              />
            </Paper>
          </Grid>
        )}

        {( this.state.currentOrganizationCode === "" && this.state.viewMode === "employeeList" ) && (
          <Grid item xs={12} >
            <Alert severity="info">
              Töötajate nimekirja kuvamiseks valige organisatsioon millega soovite tööd teha. 
            </Alert>
          </Grid>
        )}

        
        <Dialog
          key="employee-management-dialog-message"
          open={this.state.messageDialogShow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.messageDialogTitle}</DialogTitle>
          <DialogContent>
            <div dangerouslySetInnerHTML={{__html: this.state.messageDialogContent }} /> 
          </DialogContent>
          <DialogActions>
            
            {( this.state.messageNoText ) && (
              <Button onClick={this.handleDialogMessageDisAgree} color="primary">
                {this.state.messageNoText}
              </Button>
            )}
            <Button onClick={this.handleDialogMessageAgree} color="primary">
              {this.state.messageYesText}
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
    );
  }

}

export default EmployeeManagement;
