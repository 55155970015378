"use strict";

/**
 * This class syncronizes resources stored/updated locally or deleted
 * into remote API
 * 
 * @return void
 */


/**
 * -
 *
 * @return void
 */
function Syncronizer() {
}


/**
 * Request resource from remote server by it's id / reference
 * request is just a obeject describing what to get like: 
 * 
 * {
 *   "resourceType" : "Patient",
 *   "url" : "Patient/{uuid}",
 *   "id" : "{uuid}"
 * }
 * 
 * or 
 * 
 * {
 *   "resourceType" : "medicationKnowledge",
 *   "url" : "MedicationKnowledge?indication-code=http://hl7.org/fhir/sid/icd-10|F00.9"
 * }
 * 
 * @param object domainResourceRequest
 * @return boolean
 */
Syncronizer.prototype.requestRemoteResource = function ( domainResourceRequest ) {
  
  var requestBundle = {
    "resourceType": "Bundle",
    "type": "batch",
    "entry": [
      {
        "status": "active",
        "request": {
          "method": "GET",
          "url": domainResourceRequest.url
        }
      }
    ]
  };

  return window.apiClient.sendBundle(requestBundle);
}


/**
 * 
 *
 * @param object domainResource
 * @param boolean isLocalResourceId
 * @return boolean
 */
Syncronizer.prototype.requstResourceUpdate = function (domainResource, isLocalResourceId) {

  var id = domainResource.getId().getResourceId();

  var bundle = {
    "resourceType": "Bundle",
    "type": "batch",
    "entry": [
      {
        "resource": domainResource.toObject(),
        "request": {
          "method": "POST",
          "url": domainResource.getResourceType()
        }
      }
    ]
  };

  // if it's not new local resource - we must set it's id also
  // otherwise server treats it as new resource (and giving it a new ID)
  if (!isLocalResourceId) {
    bundle.entry[0].resource.id = domainResource.getId().getResourceId();
  } else {
    delete bundle.entry[0].resource.id;
    bundle.entry[0].resource.id = "";
    bundle.entry[0].link = [
      {
        "relation": "app",
        "url": id
      }
    ];
  }

  return window.apiClient.sendBundle(bundle);
}

export { Syncronizer };
