
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppIcon from './../AppIcon';




class TicketsTable extends React.Component {

  constructor( props ) {
    super( props );
    
    this.handleTicketClick = this.handleTicketClick.bind( this );
  }


  componentDidMount() {
    // 
  }


  handleTicketClick ( event, ticket ){
    if( event != null ){
      event.preventDefault();
    }
    
    if( typeof this.props.onTicketclick != "undefined" ){
      this.props.onTicketclick( ticket );
    }
  }


  render() {
    var that = this;

    return (
      <TableContainer component={Paper}>
        
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tickets-table-header">&nbsp;</TableCell>
              <TableCell className="tickets-table-header" align="left">Pileti nr</TableCell>
              <TableCell className="tickets-table-header" align="left">Nimi</TableCell>
              <TableCell className="tickets-table-header" align="left">Isikukood</TableCell>
              <TableCell className="tickets-table-header" align="left">Nimistu</TableCell>
              <TableCell className="tickets-table-header" align="left">Teenusenimetus</TableCell>
              

              <TableCell className="tickets-table-header" align="left">Muu info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.tickets.map( function(row, index){
              let priorityClass = 'priority-indicator-' + row.priority;
              
              let debugMessage = 'Täideviimise algusaeg: ' + row.dateTimeStartFormattedLong + "\n";
              debugMessage +=    'Täideviimise lõpuaeg: ' + row.dateTimeEndFormattedLong + "\n";
              debugMessage +=    'Pilet loodud: ' + row.authoredOnDateFormattedLong + "\n";
              debugMessage +=    'Tehniline staatus: ' + row.ticketStatus + "\n";
              
              let gpData = '-';
              if( row.patientGeneralPracticionerDisplayName != '' && row.patientGeneralPracticionerDisplayName != '-' ){
                gpData = row.patientGeneralPracticionerDisplayName;
              }

              if( row.patientGeneralPracticionerListCode != '' && row.patientGeneralPracticionerListCode != '-' ){
                gpData = gpData + ' (' + row.patientGeneralPracticionerListCode + ')';
              }
              
              return (
                <TableRow key={row.ticketId} className="tickets-table-row" onClick={(e) => that.handleTicketClick( e, row )} >
                  <TableCell className={priorityClass} scope="row" style={{'minWidth': '70px'}}>
                    
                    {( row.isOpening ) && (
                      <CircularProgress size={24} />
                    )}
                    {( !row.isOpening ) && (
                      <AppIcon businessStatus={row.businessStatus} useLabel={row.businessStatusDisplayText} />
                    )}

                    &nbsp;&nbsp;&nbsp;
                    {( row.isLocked ) && (
                      <AppIcon iconName="locked" size="medium" useLabel={row.isLockedTo} />
                    )}
                  </TableCell>
                  <TableCell align="left" label={debugMessage} title={debugMessage}>
                    {row.ticketNumber}
                  </TableCell>
                  <TableCell align="left">{row.personDisplayName}</TableCell>
                  <TableCell align="left">{row.personCode}</TableCell>
                  <TableCell align="left">{gpData}</TableCell>
                  <TableCell align="left">{row.serviceName}</TableCell>
                  <TableCell align="left">{row.additionalInfo}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </TableContainer>
    );
  }

}

export default TicketsTable;
