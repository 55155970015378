
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppIcon from './../AppIcon';




class EmployeeTable extends React.Component {

  constructor( props ) {
    super( props );
    
    this.handleRowClick = this.handleRowClick.bind( this );
  }


  componentDidMount() {
    // 
  }


  handleRowClick ( event, ticket ){
    if( event != null ){
      event.preventDefault();
    }
    
    if( typeof this.props.onRowClick != "undefined" ){
      this.props.onRowClick( ticket );
    }
  }

  /**
   * 
   * @param obj practicionerRoleObj 
   * @return string
   */
  createPatientRegisterString( practicionerRoleObj ){
    if( practicionerRoleObj.healthCareRegisters.length < 1 ){
      return '-';
    }

    let str = "";

    for( var i in practicionerRoleObj.healthCareRegisters ){
      let tmp = practicionerRoleObj.healthCareRegisters[ i ];
      let comma = ", ";
      if( str.length < 1 ){
        comma = "";
      }

      str = str + comma + tmp.display; 
    }

    return str;
  }


  render() {
    var that = this;

    // Healtcare services tulevad ka paraticoner role küljest

    return (
      <TableContainer component={Paper}>
        
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tickets-table-header">Allikas</TableCell>
              <TableCell className="tickets-table-header" align="left">Nimi</TableCell>
              <TableCell className="tickets-table-header" align="left">Töötaja kood</TableCell>
              <TableCell className="tickets-table-header" align="left">Isikukood</TableCell>
              <TableCell className="tickets-table-header" align="left">Roll</TableCell>
              <TableCell className="tickets-table-header" align="left">Nimistud</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.employees.map( function(row, index){
              let priorityClass = 'priority-indicator-' + row.priority;
              
              let debugMessage = "\n";
              let patientRegisters = that.createPatientRegisterString( row );
              
              return (
                <TableRow key={row.id} className="tickets-table-row" onClick={(e) => that.handleRowClick( e, row )} >
                  <TableCell className={priorityClass} scope="row" style={{'minWidth': '1rem'}}>
                    {( row.isExternallyManaged ) && (
                      <AppIcon iconName="externallyManaged" size="medium" useLabel="Väliselt hallatud (MEDRE)" />
                    )}
                    
                    {( row.isExternallyManaged && row.isManuallyManaged ) && (
                      <span>&nbsp;&nbsp;</span>
                    )}

                    {( row.isManuallyManaged ) && (
                      <AppIcon iconName="overWritten" size="medium" useLabel="Ülekirjutatud või organisatsiooni hallatud" />
                    )}
                  </TableCell>
                  <TableCell align="left">{row.displayName}</TableCell>
                  <TableCell align="left">{row.healthcareWorkerCode}</TableCell>
                  <TableCell align="left">{row.personalCode}</TableCell>
                  <TableCell align="left">
                    {( row.hasMainRole && row.mainRoleCode === "HEALTH_ANALYST" ) && (
                      <Chip label="TEAN" color="primary" ></Chip>
                    )}
                    {( row.hasMainRole && row.mainRoleCode === "FHC_ADMIN" ) && (
                      <Chip label="FHC Admin" color="secondary" ></Chip>
                    )}
                    {( row.hasMainRole && row.mainRoleCode === "ADMIN" ) && (
                      <Chip label="Admin" color="secondary" ></Chip>
                    )}
                  </TableCell>
                  <TableCell align="left">{patientRegisters}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </TableContainer>
    );
  }

}

export default EmployeeTable;
