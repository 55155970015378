/**
 * QuestionnaireResponse preview with demo data
 * 
 */

 import React from 'react';
 import Table from '@material-ui/core/Table';
 import TableBody from '@material-ui/core/TableBody';
 import TableCell from '@material-ui/core/TableCell';
 import TableHead from '@material-ui/core/TableHead';
 import TableRow from '@material-ui/core/TableRow';
 
 
 class PreviewQuestionnaireResponse extends React.Component {

  getListFromObservations(){
    // console.log( this.props.ticket.taskResource.getFocusReferenceUrl() );

    var list = [];

    for( var i in this.props.ticket.observations ){
      let observation = this.extractObservation( this.props.ticket.observations[ i ] );
      if( observation !== null ){
        list.push( observation );
      }
    }

    return list;
  }

  
  extractObservation( resource ){
    if( typeof resource == 'undefined' ){
      return null;
    }
    
    let json = resource.toObject();

    if( typeof json == 'undefined' || typeof json.component == 'undefined' ){
      return null;
    }

    let display = '';
    let answer = '';
    
    for( var j in json.component ){
      if( typeof json.component[ j ].code != 'undefined' ){
        display = json.component[ j ].code.coding[ 0 ].display;
      }
      if( typeof json.component[ j ].valueCodeableConcept != 'undefined' ){
        answer = json.component[ j ].valueCodeableConcept.coding[ 0 ].display;
      }
    }

    if( display == '' && answer == '' ){
      return null;
    }

    return { 
      "type" : 'question', 
      "title" : display, 
      "answer" : answer 
    }
  }


  extractObservationFromQuestionnaireResponse( resource ){
    if( typeof resource == 'undefined' || !resource ){
      return [];
    }

    let json = resource.toObject();

    if( typeof json.item == 'undefined' ){
      return [];
    }
    
    // console.log( 'extractObservationFromQuestionnaireResponse::' );
    // console.log( JSON.stringify( json.item ) );

    let list = [];

    for( var i2 in json.item ){
      let itemTmp = json.item[ i2 ];
      let mainDisplay = '';
      if( typeof itemTmp.text != 'undefined' ){
        mainDisplay = itemTmp.text;
      }
      if( typeof itemTmp.item != 'undefined' ){
        // console.log( itemTmp.item );

        let answer = '-';
        if( typeof itemTmp.item[ 0 ] != 'undefined' ){

          if( 
            typeof itemTmp.item[ 0 ].answer != 'undefined' &&  
            typeof itemTmp.item[ 0 ].answer[ 0 ] != 'undefined' &&  
            typeof itemTmp.item[ 0 ].answer[ 0 ].valueCoding != 'undefined'
          ){
            answer = itemTmp.item[ 0 ].answer[ 0 ].valueCoding.display;
          } else if( 
            typeof itemTmp.item[ 0 ].answer != 'undefined' &&  
            typeof itemTmp.item[ 0 ].answer[ 0 ] != 'undefined' &&  
            typeof itemTmp.item[ 0 ].answer[ 0 ].valueString != 'undefined' 
          ){ 
            answer = itemTmp.item[ 0 ].answer[ 0 ].valueString;
          } else if ( typeof itemTmp.item[ 0 ].text != 'undefined' ){
            answer = itemTmp.item[ 0 ].text;
          }
        }
        
        if( typeof itemTmp.item[ 0 ] != 'undefined' && typeof itemTmp.item[ 0 ].text != '' && itemTmp.item[ 0 ].text ){
          mainDisplay = itemTmp.text + ' ' + itemTmp.item[ 0 ].text;
        }
        
        list.push( { 
          "type" : 'question', 
          "title" : mainDisplay, 
          "answer" : answer
        } );
      }

    }

    return list;
  }

  
  render() {

    // var observations = this.getListFromObservations();
    var observations = this.extractObservationFromQuestionnaireResponse( this.props.questionnaireResponse );

    return (
      <div  style={{ "maxHeight" : "400px", "overflowY" : "auto"  }} className="preview-questionnaire-response">
        <Table aria-label="simple table" className="preview-questionnaire-table">
          <TableHead>
            <TableRow>
              <TableCell className="tickets-table-header" align="left">Küsimus</TableCell>
              <TableCell className="tickets-table-header" align="left">Vastus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {observations.map( function(row, index){
              return (
                <TableRow key={index} className="tickets-table-row"  >
                  <TableCell align="left" className="borderless">{row.title}</TableCell>
                  <TableCell align="left" className="borderless">{row.answer}</TableCell>
                </TableRow>
              )
            })}
            
          </TableBody>
        </Table>
      </div>
    );
  }
 
}
 
export default PreviewQuestionnaireResponse;
