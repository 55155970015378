/**
 * Value object describing domain resource related meta data in store
 * 
 * 
 */


class Meta {

  /**
   * 
   * @param boolean updatedToServer 
   * @param integer updatedToServerTime 
   * @param integer startWaitingServerConfirmTime 
   */
  constructor( updatedToServer, updatedToServerTime, startWaitingServerConfirmTime ) {

    /**
     * When resource is sent to be updated into server, but it's not yet confirmed, this will 
     * have timestamp attached to it. After confirmed update- timestamp will be removed
     */
    this._waitingUpdateToServer = startWaitingServerConfirmTime;

    /**
     * Is set true when resource update from server / read from server is stored locally
     */
    this._updatedToServer = updatedToServer;
    
    /**
     * Time is set and known after item is saved in server / read from server
     */
    this._updatedToServerTime = updatedToServerTime;
  }


  /**
   * 
   * @return boolean
   */
  needsUpdateToServer() {
    if( this._updatedToServer > 0 ){
      return false;
    }
    return true;
  }


  /**
   * Request already sent. Waiting for response 
   * 
   * @return boolean
   */
  isUpdatingToServer() {
    if( this._waitingUpdateToServer > 0 ){
      return true;
    }
    return false;
  }
  
}


export { Meta };