
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import PreviousTicketsTable from './PreviousTicketsTable';
import PreviewQuestionnaireResponse from './../Questionnaire/PreviewQuestionnaireResponse';
import AppIcon from './../AppIcon';

import List from '@material-ui/core/List';
import Popper from '@material-ui/core/Popper';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { styled , lighten, darken } from '@material-ui/core/styles';

import { DomainResource } from './../../Tean/DomainResource/DomainResource';
import Task from './../../Tean/Task/Task.js';


var communicationMethods = [
  { 'id' : "phone", "label" : "Telefon" },
  { 'id' : "email", "label" : "Kirjalik töö" },
  { 'id' : "video", "label" : "Videokõne" },
  { 'id' : "visit", "label" : "Visiit" }
];

const filter = createFilterOptions();

// Using https://mui.com/material-ui/react-autocomplete/
/*
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));
*/
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  fontWeight: 'bold',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85)
}));

const GroupItems = styled('ul')({
  padding: 0,
});

class HealthTicket extends React.Component {

  constructor( props ) {
    super( props );

    this.fakeTask = new Task( 'fake-1' );
    this.paperElevation = 2;
    this.priorities = this.fakeTask.getPossiblePriorities();

    this.practicionersOrderBy = "orderByPatientsHealthcareService"; // orderByorganization or orderByPatientsHealthcareService

    let preSelectedRestriction = null;
    this.timerRequestPracticionerSubmitPoll = null;
    this.timerRequestPracticionerDeletePoll = null;

    if( props.ticket.restrictionPracticionerId != null ){
      let restriction = window.appEntityStore.getResourceById( props.ticket.restrictionPracticionerId );

      if( restriction ){
        let tmp = restriction.toObject();
        
        let foundOrganizationCode = "NOTForg"; // tmp.organization.identifier.value
        let foundpPracticionerCode = "NOTFPr"; // tmp.practitioner.identifier.value
        
        preSelectedRestriction = {
          "resourceId" : tmp.id,
          "resourceIdObject" : restriction.getId(),
          "practicionerDisplayName" : tmp.practitioner.display, 
          "practicionerCode": foundpPracticionerCode, 
          "organizationDisplayName" : tmp.organization.display, 
          'organizationCode' :  foundOrganizationCode
        }
      }
    }

    //
    let bookingUrl = null;
    if(this.props.mode === "view" && this.props.ticket.webBookingUrl ){
      bookingUrl = this.props.ticket.webBookingUrl;
    }

    this.state = {
      "requestedEdit" : false,
      "requestedClose" : false,
      "prioritySelectorOpen" : false,
      "pendingUpdates" : {

      },
      "ticketHistoryOpen" : false,
      "ticketHistoryBtnEl" : null,
      "communicationMethods" : props.ticket.communicationMethods,
      "askPracticionerDeleteIsOpen" : false,
      "askPracticionerDeleteForName" : "",
      "practicionerFormOpen" : false,
      "practicionerFormError" : "",
      "practicionerFormSaving" : false,
      "practicionerAutoCompleteValue" : preSelectedRestriction,
      "practisionerDialogUpdated" : 0,
      "practisionerDialog" : {
        practicionerDisplayName: '',
        practicionerCode: '',
        organizationDisplayName: '',
        organizationCode: ''
      },
      "practicionerNoteText" : props.ticket.practicionerNoteText,
      "practicionerBeingDeleted" : false,
      "practicionerWasDeletedName" : '',
      
      "ticketBookingUrlView" : false,
      "ticketBookingIframeMaximized" : false,
      "ticketBookingUrl" : bookingUrl
    }

    this.keyPressListenerAdded = false;

    this.handleForwardTicketClick = this.handleForwardTicketClick.bind( this );
    this.handleExitTicketClick = this.handleExitTicketClick.bind( this );
    this.handlePrioritySelectorOpen = this.handlePrioritySelectorOpen.bind( this );
    this.handlePriorityChange = this.handlePriorityChange.bind( this );
    
    this.handleHealtOrganizationEditClick = this.handleHealtOrganizationEditClick.bind( this );
    this.handleHealtPracticionerRoleDeletelick= this.handleHealtPracticionerRoleDeletelick.bind( this );
    this.handleHealtOrganizationClose = this.handleHealtOrganizationClose.bind( this );
    
    this.handlePracticionerFormOpen = this.handlePracticionerFormOpen.bind( this );
    this.handlePracticionerFormClose = this.handlePracticionerFormClose.bind( this );
    this.setPractisionerDialogValue = this.setPractisionerDialogValue.bind( this );
    this.handlePracticionerFormSubmit = this.handlePracticionerFormSubmit.bind( this );
    this.handleCommnicationMethodSelect = this.handleCommnicationMethodSelect.bind( this );
    this.handleCommnicationMethodToggleClick = this.handleCommnicationMethodToggleClick.bind( this );
    
    this.handleDialogAskPracticionerDeleteClose = this.handleDialogAskPracticionerDeleteClose.bind( this );
    this.handleDialogAskPracticionerDeleteAgree = this.handleDialogAskPracticionerDeleteAgree.bind( this );
    this.handleTicketHistoryToggleClick = this.handleTicketHistoryToggleClick.bind( this );

    this.moveToBookingPart = this.moveToBookingPart.bind( this );
    this.handleBookingPartDone = this.handleBookingPartDone.bind( this );
    this.handleToggleMaximizeIframe = this.handleToggleMaximizeIframe.bind( this );
  }


  componentDidMount() {
    var that = this;

    if( !this.keyPressListenerAdded ){
      this.keyPressListenerInTicket = function( ev ){
        if( ev.key === "Escape" ){
          if( !that.state.ticketBookingUrlView ){
            that.handleExitTicketClick( null );
          }
        }

        if( ev.key === "F" || ev.key === "f" ){
          if( that.state.ticketBookingUrlView ){
            that.handleToggleMaximizeIframe();
          }
        }
      }

      document.addEventListener("keydown", this.keyPressListenerInTicket, false);
      this.keyPressListenerAdded = true;
    }
  }


  componentWillUnmount() {
    if( this.timerRequestPracticionerSubmitPoll != null ){
      clearTimeout( this.timerRequestPracticionerSubmitPoll );
    }
    if( this.timerRequestPracticionerDeletePoll != null ){
      clearTimeout( this.timerRequestPracticionerDeletePoll );
    }

    document.removeEventListener("keydown", this.keyPressListenerInTicket, false);
  }


  /**
   * 
   */
   moveToBookingPart( url ){

    this.setState( {
      "ticketBookingUrlView" : true,
      "ticketBookingUrl" : url
    } );

  }


  /**
   * If booking url was oepned after save- ticket mode for React app is still in "edit" mode
   * and we need to tell parent that we can finally close it. Actual update of the ticket is actually 
   * done by this point.  
   * 
   */
  handleBookingPartDone() {
    if( this.state.ticketBookingIframeMaximized ){
      document.body.style.position = "initial";
    }

    if( this.props.mode === "edit" ){
      if( typeof this.props.onAfterBookingPartDone !== "undefined" ){
        this.props.onAfterBookingPartDone();
      }
    } else {
      if( typeof this.props.onTicketExit !== "undefined" ){
        this.props.onTicketExit( this.props.ticket );
      }
    }

    return true;
  }

  handleToggleMaximizeIframe(){
    if( this.state.ticketBookingIframeMaximized ){
      document.body.style.position = "initial";
    } else {
      document.body.style.position = "fixed";
    }

    this.setState( {
      "ticketBookingIframeMaximized" : ( this.state.ticketBookingIframeMaximized ? false : true  ),
    } );
  }

  handleCanceledEditRequestByParent (){
    this.setState( {
      "requestedEdit" : false,
      "requestedClose" : false
    } );
  }


  handleShowLoaderByParent (){
    this.setState( {
      "requestedClose" : true,
    } );
  }
  

  handleExitTicketClick ( event ){
    if( event !== null ){
      event.preventDefault();
    }

    this.setState( {
      "requestedEdit" : false,
      "requestedClose" : true
    } );
    
    if( typeof this.props.onTicketExit != "undefined" ){
      this.props.onTicketExit( this.props.ticket );
    }
  }


  reportTicketModified( pendingUpdates ){
    if( typeof this.props.onSetTicketModified != "undefined" ){
      this.props.onSetTicketModified( pendingUpdates );
    }
  }
  

  handleTicketEditRequestClick( event ){
    if( event != null ){
      event.preventDefault();
    }
    
    if( typeof this.props.onTicketEditRequest != "undefined" ){
      this.props.onTicketEditRequest( this.props.ticket );
      this.setState( {
        "requestedEdit" : true,
      } );
    }
  }


  handleForwardTicketClick ( event, nextProcessStatus ){
    let pendingUpdates = this.state.pendingUpdates;

    pendingUpdates.processCommand = nextProcessStatus;
    
    /** 
    if( typeof this.props.onTicketExit != "undefined" ){
      this.props.onTicketExit( this.props.ticket );
      this.setState( {
        "requestedEdit" : false,
        "pendingUpdates" : pendingUpdates
      } );
    }
    */
    
    let updateState = false;

    if( typeof this.props.onTicketUpdate != "undefined" ){
      let test = this.props.onTicketUpdate( this.props.ticket.ticketId, pendingUpdates );
      if( test ){
        updateState = true;
      }
    } else {
      updateState = true;
    }

    if( updateState ){
      this.setState( {
        "requestedClose" : true,
        "requestedEdit" : false,
        "pendingUpdates" : pendingUpdates
      } );
    }
  }

  
  handlePrioritySelectorOpen ( event ){
    this.setState({
      'prioritySelectorOpen' : true
    });
  }

  
  handlePriorityChange ( priority ){
    let current = this.state.pendingUpdates;

    if( priority != '' ){
      current.priority = priority;
    }

    this.setState({
      "prioritySelectorOpen" : false,
      "pendingUpdates" : current
    });
    
    this.reportTicketModified( current );
  }

  
  handlePracticionerNoteChange ( newValue ){
    let current = this.state.pendingUpdates;
    
    current.practicionerNote = newValue;

    this.setState({
      "practicionerNoteText" : newValue,
      "pendingUpdates" : current
    });
    
    this.reportTicketModified( current );
  }

  preapareLineFeeds( text ){
    if( typeof text == 'undefined' ){
      return '';
    }
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return text;
  }


  handleHealtPracticionerRoleDeletelick ( event, item ){
    event.preventDefault();
    event.stopPropagation();
    
    let practicionerRole = window.appEntityStore.getResourceById( item.resourceId );

    if( practicionerRole === null ){
      console.log( item.resourceId );
      alert( 'Töötajat ei leitud. ' );
      return;
    }

    this.practicionerRoleDeleteBuffer = item;

    let tmp = practicionerRole.toObject();
    let personName = '-';
    if( typeof tmp.practitioner != 'undefined' && typeof tmp.practitioner.display != 'undefined' ){
      personName = tmp.practitioner.display;
    }

    this.setState({
      "askPracticionerDeleteIsOpen" : true,
      "askPracticionerDeleteForName" : tmp.practitioner.display
    });
    
  }


  /**
   * 
   * 
   */
  handleDialogAskPracticionerDeleteClose( ){
    this.setState({
      "askPracticionerDeleteIsOpen" : false
    });
  }

  handleDialogAskPracticionerDeleteAgree( ){
    this.setState({
      "askPracticionerDeleteIsOpen" : false
    });

    let practicionerRole = window.appEntityStore.getResourceById( this.practicionerRoleDeleteBuffer.resourceId );

    if( practicionerRole === null ){
      alert( 'Töötajat ei leitud. ' );
      this.setState({
        "practicionerBeingDeleted" : false,
        "practicionerWasDeletedName" : ""
      });
      return;
    }

    practicionerRole._resourceLiteral.active = false;
    window.appEntityStore.saveResource( practicionerRole );

    this.practicionerBeingDeletedId = this.practicionerRoleDeleteBuffer.resourceId;

    this.setState({
      "practicionerBeingDeleted" : true,
      "practicionerWasDeletedName" : ""
    });

    this.pollPracticionerDeleteDone();
  }


  pollPracticionerDeleteDone(){
    var that = this;

    if( this.timerRequestPracticionerDeletePoll != null ){
      clearTimeout( this.timerRequestPracticionerDeletePoll );
    }

    let practicionerRole = window.appEntityStore.getResourceById( this.practicionerBeingDeletedId );
    
    if( practicionerRole === null ){
      alert( 'Töötajat ei leitud. ' );
      this.setState({
        "practicionerBeingDeleted" : false,
        "practicionerWasDeletedName" : ""
      });
      return;
    }

    let json = practicionerRole.toObject();
    
    let syncing = window.appEntityStore.isRecourceSyncing( practicionerRole );
    
    if( syncing || json.active ){
      this.timerRequestPracticionerDeletePoll = setTimeout(function(){
        that.pollPracticionerDeleteDone();
      }, 1000);
      return;
    }

    // if practicioner which was deleted is set as practicioner in current form- remove it
    if(
      typeof this.state.practicionerAutoCompleteValue != 'undefined' && 
      this.state.practicionerAutoCompleteValue !==null && 
      typeof this.state.practicionerAutoCompleteValue.resourceIdObject != 'undefined' && 
      this.state.practicionerAutoCompleteValue.resourceIdObject !== null && 
      practicionerRole.getId().isEqual( this.state.practicionerAutoCompleteValue.resourceIdObject ) 
    ){
      console.log('Remove practicioner from form also');
      let pendingUpdates = this.state.pendingUpdates;
      pendingUpdates.restrictionPracticionerRoleId = null;
      this.setState({
        "practicionerAutoCompleteValue" : null,
        "pendingUpdates" : pendingUpdates
      });
    
      this.reportTicketModified( pendingUpdates );
    }
    
    this.setState({
      "practicionerBeingDeleted" : false,
      "practicionerWasDeletedName" : ""
    });
  }


  handleHealtOrganizationEditClick ( event, item ){
    event.preventDefault();
    event.stopPropagation();
    
    var that = this;
    if( item.resourceId == 'new' ){
      // Open new dialog instead? 
      return;
    }

    let resources = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "PractitionerRole"
    } );
    
    for( var i in resources ){
      let tmp = resources[ i ].toObject();
      if( tmp.id == item.resourceId ){
        
        that.handlePracticionerFormOpen();
        
        let practicionerCode = "practitioner code here"; // tmp.practitioner.identifier.value
        let organizationCode = "organization code here"; // tmp.organization.identifier.value 

        that.setState({
          "practicionerFormError" : "",
          "practisionerDialog" : {
            "resourceId" : tmp.id,
            "resourceIdObject" : resources[ i ].getId(),
            "practicionerDisplayName" : tmp.practitioner.display, 
            "practicionerCode": practicionerCode, 
            "organizationDisplayName" : tmp.organization.display, 
            'organizationCode' : organizationCode
          }
        });
      }
    }
    
    event.preventDefault();
  }

  
  handleHealtOrganizationClose ( event, reason ){
  }
  
  
  handleCommnicationMethodSelect ( event, value ){
    let pendingUpdates = this.state.pendingUpdates;
    pendingUpdates.communicationMethods = value;
    this.setState({
      "communicationMethods" : value,
      "pendingUpdates" : pendingUpdates
    });
    
    this.reportTicketModified( pendingUpdates );
  }

  
  handleCommnicationMethodToggleClick ( event, clickedMethod ){
    let pendingUpdates = this.state.pendingUpdates;
    let newMethodsList = [];
    
    let useList = this.state.communicationMethods;

    if( typeof pendingUpdates.communicationMethods != 'undefined' ){
      useList = pendingUpdates.communicationMethods;
    }

    for( var n in communicationMethods ){
      let currentlyPresent = false;

      for( var k in useList ){
        if( communicationMethods[ n ].id == useList[ k ] ){
          currentlyPresent = true;
        }
      }

      let add = currentlyPresent;
      if( communicationMethods[ n ].id == clickedMethod.id ){
        if( currentlyPresent ){
          add = false;
        } else {
          add = true;
        }
      }
      
      if( add ){
        newMethodsList.push( communicationMethods[ n ].id );
      }
    }
    
    pendingUpdates.communicationMethods = newMethodsList;

    this.setState({
      "communicationMethods" : newMethodsList,
      "pendingUpdates" : pendingUpdates
    });
    
    this.reportTicketModified( pendingUpdates );
  }

  
  handlePracticionerFormOpen ( ){
    this.setState({
      "practicionerFormOpen" : true,
      "practicionerFormError" : ""
    });
  }
  
  
  handlePracticionerFormClose ( ){
    this.setState({
      "practicionerFormOpen" : false,
      "practicionerFormSaving" : false,
      "practicionerFormError" : ""
    });
  }
  

  setPractisionerDialogValue ( vkey, value ){
    let vals = this.state.practisionerDialog;
    let newCnt = this.state.practisionerDialogUpdated + 1;
    
    if( vkey === 'practicionerDisplayName' ){
      vals.practicionerDisplayName = value;
    }
    if( vkey === 'practicionerCode' ){
      vals.practicionerCode = value;
    }
    if( vkey === 'organizationDisplayName' ){
      vals.organizationDisplayName = value;
    }
    if( vkey === 'organizationCode' ){
      vals.organizationCode = value;
    }
    
    this.setState({
      "practisionerDialogUpdated" : newCnt,
      "practisionerDialog" : vals
    });
  }

  
  handlePracticionerFormSubmit(){
    let vals = this.state.practisionerDialog;
    var useId = null;
    let saveTytpe = 'new';
    let domainResource = null;

    if( vals.resourceId == 'new' ){
      useId = window.appEntityStore.createLocalResourceId( 'PractitionerRole' );
      domainResource = new DomainResource( "PractitionerRole" );
    } else {
      useId = vals.resourceIdObject;
      saveTytpe = 'update';
      domainResource = window.appEntityStore.getResourceById( vals.resourceIdObject );
    }
    
    vals.practicionerCode = vals.practicionerCode.trim();
    vals.practicionerDisplayName = vals.practicionerDisplayName.trim();
    vals.organizationCode = vals.organizationCode.trim();
    vals.organizationDisplayName = vals.organizationDisplayName.trim();

    if(
      vals.practicionerCode.length < 1 ||
      vals.practicionerDisplayName.length < 1 || 
      vals.organizationCode.length < 1 || 
      vals.organizationDisplayName.length < 1
    ){
      this.setState({
        "practicionerFormError" : "Palun täida kõik väljad"
      });
      return false;
    }

    let resourceJson = {
      "resourceType": "PractitionerRole",
      "id": useId.getResourceId(),
      "active": true,
      "practitioner": {
        "identifier": {
          "system": "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
          "value": vals.practicionerCode
        },
        "display": vals.practicionerDisplayName
      },
      "organization": {
        "identifier": {
          "system": "https://tean.abtram.eu/fhir/namingsystem/organization-identifier-estonia",
          "value": vals.organizationCode
        },
        "display": vals.organizationDisplayName
      }
    }

    domainResource.setRawResourceObject( resourceJson );
    domainResource.setId( useId );

    window.appEntityStore.saveResource( domainResource );

    this.practicionerBeingSavedType = saveTytpe;
    this.practicionerBeingSavedId = useId;

    this.setState({
      "practicionerFormSaving" : true
    });

    this.pollPracticionerFormSubmitDone();
  }

  pollPracticionerFormSubmitDone(){
    var that = this;
    let vals = this.state.practisionerDialog;
    
    if( this.timerRequestPracticionerSubmitPoll != null ){
      clearTimeout( this.timerRequestPracticionerSubmitPoll );
    }

    let item = null;
    if( this.practicionerBeingSavedType == 'update' ){
      item = window.appEntityStore.getResourceById( this.practicionerBeingSavedId );
    } else {
      let savedId = window.appEntityStore.getMappedServerIdFromLocalId( this.practicionerBeingSavedId );
      if( !savedId || savedId === null ){
        this.timerRequestPracticionerSubmitPoll = setTimeout(function(){
          that.pollPracticionerFormSubmitDone();
        }, 2000);
        return;
      } else {
        item = window.appEntityStore.getResourceById( savedId );
      }
    }

    if( item === null ){
      alert( 'Töötajat ei leitud. ' );
      this.setState({
        "practicionerFormSaving" : false
      });
      return;
    }

    let syncing = window.appEntityStore.isRecourceSyncing( item );
    
    if( syncing ){
      this.timerRequestPracticionerSubmitPoll = setTimeout(function(){
        that.pollPracticionerFormSubmitDone();
      }, 2000);
      return;
    }
    
    this.practicionerJsonBeingSaved = null;

    let valsEmpty = {
      practicionerDisplayName: '',
      practicionerCode: '',
      organizationDisplayName: '',
      organizationCode: ''
    }

    let pendingUpdates = this.state.pendingUpdates;
    pendingUpdates.restrictionPracticionerRoleId = item.getId();
    
    let newCnt = this.state.practisionerDialogUpdated + 1;

    this.setState({
      "practicionerFormOpen" : false,
      "practicionerFormError" : "",
      "practicionerFormSaving" : false,
      "practisionerDialogUpdated" : newCnt,
      "practisionerDialog" : valsEmpty,
      "practicionerAutoCompleteValue" : vals,
      "pendingUpdates" : pendingUpdates
    });
    
    this.reportTicketModified( pendingUpdates );
  }

  
  /**
   * 
   * 
   * @return string
   */
   createCommunicationMethodsText() {
    let display = '';

    for( let j in this.state.communicationMethods ){
      let comma = display.length > 0 ? ', ' : '';
      let method = this.state.communicationMethods[ j ];

      for( let k in communicationMethods ){
        if( communicationMethods[ k ].id == method ){
          method = communicationMethods[ k ].label;
        }
      }

      display = display + comma + method;
    }

    return display;
  }


  /**
   * 
   * 
   * @param Date dateFuture 
   * @param Date dateNow 
   * @returns 
   */
  createETAText(dateFuture, dateNow) {
    let result = {
      'text' : '',
      'overTime' : false
    }
    let diffInMilliSeconds = (dateFuture - dateNow) / 1000; // Math.abs(dateFuture - dateNow) / 1000;

    if( diffInMilliSeconds < 1 ){
      result.text = ' möödas';
      result.overTime = true;
      return result;
    }

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} päev, ` : `${days} päeva, `;
    }

    difference += (hours === 0 || hours === 1) ? `${hours} tund, ` : `${hours} tundi, `;

    difference += (minutes === 0 || hours === 1) ? `${minutes} minut` : `${minutes} minutit`; 
    result.text = difference;

    return result;
  }


  /**
   * 
   * 
   * @returns 
   */
  getTicketHistory() {
    let obj = {
      'hasRecords' : false,
      'content' : ""
    }

    if( typeof this.props.ticket.ticketHistory == 'undefined' || this.props.ticket.ticketHistory.length < 1 ){
      return obj;
    }

    obj.hasRecords = true;
    
    for( var i in this.props.ticket.ticketHistory ){
      obj.content = obj.content + "<p>";

      obj.content = obj.content + this.props.ticket.ticketHistory[ i ].historyRecordDisplay;

      obj.content = obj.content + "</p>";
    }


    return obj;
  }
  

  handleTicketHistoryToggleClick ( event ){
    let current = this.state.ticketHistoryOpen;
    event.preventDefault();
    current = current === true ? false : true;
    this.setState({
      "ticketHistoryOpen" : current,
      "ticketHistoryBtnEl" : event.currentTarget
    });

    return false;
  }


  getPracticionersListForAutoComplete(){
    let list = [];

    let resources = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "PractitionerRole"
    } );
    
    //After ordering add special group based on Patient's healthcare register
    // Collect copies of PracticionerRoles into array
    let preferredList = [];
    let preferredRegistryId = "";
    if( this.props.ticket.preferredRegistryIdString !== "" ){
      preferredRegistryId = this.props.ticket.preferredRegistryIdString;
    }

    console.log( "preferredRegistryId::" );
    console.log( preferredRegistryId );

    for( var i in resources ){
      let tmp = resources[ i ].toObject();

      if( window.appEntityStore.isLocalResourceId( resources[ i ].getId().getResourceId() ) ){
        // skip local - not synced resources
      } else {
        if( typeof tmp.active === 'undefined' || tmp.active ){
          let practicionerCode = "";
          let organizationCode = "organization code here"; // tmp.organization.identifier.value

          if( 
            typeof tmp.practitioner !== "undefined" && 
            typeof tmp.practitioner.identifier !== "undefined" && 
            typeof tmp.practitioner.identifier.value !== "undefined" 
          ){
            practicionerCode = tmp.practitioner.identifier.value;
          }

          let groupByValue = tmp.organization.display;
          if( this.practicionersOrderBy === "orderByPatientsHealthcareService" ){
            groupByValue = '#2 - Kõik töötajad';
          }

          list.push( {
            "resourceId" : tmp.id,
            "resourceIdObject" : resources[ i ].getId(),
            "practicionerDisplayName" : ( tmp.practitioner.display + " (" + practicionerCode + ", " + tmp.organization.display + ")" ), // to be used in autocomplete search and if selected
            "practicionerNameForAutocomplete" : tmp.practitioner.display, // To be used as label in autocomplete
            "practicionerCode": practicionerCode, 
            "organizationNameForAutocomplete" : tmp.organization.display,
            "organizationDisplayName" : tmp.organization.display, 
            "groupByValue" : groupByValue,  
            'organizationCode' : organizationCode,
            'showOrganizationDisplayName' : ( this.practicionersOrderBy === "orderByorganization" ? false : true ) 
          } );

          //Is it eligible for preferredList ? 
          let isEligible =  this.isEligibleForPreferredList( tmp, preferredRegistryId ) ;

          // For test- All Anna's to the preferred list
          //if( tmp.practitioner.display.indexOf("anna") > -1 ){
          //   isEligible = true;
          //}


          if( isEligible ){
            // #1 forces it to be the first group
            //
            //this.practicionersOrderBy = "orderByorganization"; // orderByPatientsHealthcareService
            if( this.practicionersOrderBy === "orderByPatientsHealthcareService" ){
              groupByValue = '#1 - Nimistuga seotud töötajad';
            }

            list.push( {
              "resourceId" : tmp.id,
              "resourceIdObject" : resources[ i ].getId(),
              "practicionerDisplayName" : ( tmp.practitioner.display + " (" + practicionerCode + ", " + tmp.organization.display + ")" ), // to be used in autocomplete search and if selected
              "practicionerNameForAutocomplete" : tmp.practitioner.display, // To be used as label in autocomplete
              "practicionerCode": practicionerCode, 
              "organizationNameForAutocomplete" : tmp.organization.display, 
              "organizationDisplayName" : tmp.organization.display, 
              "groupByValue" : groupByValue,
              'organizationCode' : organizationCode,
              'showOrganizationDisplayName' : ( this.practicionersOrderBy === "orderByorganization" ? false : true ) 
            } );
          }

        }
      }
    }

    // order by organizationDisplayName name first and then inside of that group - order by practicionerDisplayName
    /*
    if( this.practicionersOrderBy === "orderByorganization" ){
      list.sort(function( a, b ){
        if( a.organizationDisplayName === b.organizationDisplayName ){
          return ( '' + a.practicionerDisplayName).localeCompare( '' + b.practicionerDisplayName);;
        }
        return ( '' + a.organizationDisplayName).localeCompare( '' + b.organizationDisplayName);
      });
    }
    */
    
    //if( this.practicionersOrderBy === "orderByPatientsHealthcareService" ){
      list.sort(function( a, b ){
        if( a.groupByValue === b.groupByValue ){
          return ( '' + a.practicionerDisplayName).localeCompare( '' + b.practicionerDisplayName);;
        }
        return ( '' + a.groupByValue).localeCompare( '' + b.groupByValue);
      });
    //}

    return list;
  }


  /**
   * Find out does given PracticionerRole hold given healthcareService id
   * 
   * @param object PracticionerRole json
   * @param string testHealthcareServiceId - Id of the testHealthcareService 
   * @return boolean
   */
  isEligibleForPreferredList( object, testHealthcareServiceId ){
    if( testHealthcareServiceId === "" ){
      return false;
    }
    
    // find registries ( healthcareServices )
    // reference  https://tean.abtram.eu/fhir/HealthcareService/f4a5bed0-1xxxxb5d8d9d884d6"
    if( typeof object.healthcareService !== "undefined" ){
      for( var i1 in object.healthcareService ){
        let test = object.healthcareService[ i1 ];
        if( 
          typeof test.display && 
          typeof test.reference !== "undefined" 
        ){
          // find Resource from local store via ID to obtain code
          //reference  "https://tean.abtram.eu/fhir/HealthcareService/f4a5bexxxxd9d884d6"
          let parts = test.reference.split("HealthcareService/");
          if( typeof parts[1] !== "undefined" && parts[1] ){
            if( parts[1] === testHealthcareServiceId ){
              return true;
            }
          }
        }
      }
    }

    return false;
  }


  /**
   * 
   * 
   * @return string
   */
   getPriorityLabelForCode( priority ) {
     for( var i in this.priorities ){
       if( this.priorities[ i ].priorityNumber == priority || this.priorities[ i ].code == priority ){
         return this.priorities[ i ].label;
       }
     }

    return priority;
  }

  
  render() {
    var that = this;

    if( this.state.ticketBookingUrlView ){
      return this.renderBookingPartView();
    }

    let priority = this.props.ticket.priority;
    
    if( typeof this.state.pendingUpdates.priority != 'undefined' ){
      priority = this.state.pendingUpdates.priority;
    }

    var currentPriority = priority;

    var priorityClasses = "priority-indicator-bg-" + priority +" priority-indicator-text-" + priority;
    var priorityText = this.getPriorityLabelForCode( priority );

    var practicionersList = this.getPracticionersListForAutoComplete();
    let lockedToText = "Pilet on töötaja käes: " + this.props.ticket.isLockedTo;

    let etaText = '';
    if( this.props.ticket.executionPeriodEnd != null && this.props.ticket.executionPeriodEnd ){
      let eta = this.createETAText( new Date( this.props.ticket.executionPeriodEnd ), new Date() );
      etaText = eta.text;
    }

    let ticketHistory = this.getTicketHistory();

    let communicationMethodsDisplay = this.createCommunicationMethodsText();

    let classNamesChangableData = 'ticket-changable-fields';
    if( this.props.mode === "edit" ){
      classNamesChangableData = 'MuiPaper-elevation3 MuiPaper-rounded ticket-changable-fields ticket-changable-fields-editing';
    }

    let gpData = '-';
    if( this.props.ticket.patientGeneralPracticionerDisplayName != '' && this.props.ticket.patientGeneralPracticionerDisplayName != '-' ){
      gpData = this.props.ticket.patientGeneralPracticionerDisplayName;
    }

    if( this.props.ticket.patientGeneralPracticionerListCode != '' && this.props.ticket.patientGeneralPracticionerListCode != '-' ){
      gpData = gpData + ' (' + this.props.ticket.patientGeneralPracticionerListCode + ')';
    }
    
    return (
      <Grid container className="health-ticket-container">

        <Grid container className="health-ticket-head-container">

          <Grid item xs={12} md={2} className="ticket-status-col">
            <p className="ticket-business-status"> <AppIcon size="small" businessStatus={this.props.ticket.businessStatus} useLabel={this.props.ticket.businessStatusDisplayText} /> {this.props.ticket.businessStatusDisplayText}</p>
          </Grid>
          
          {(this.props.mode === "edit" ) && (
            
            <Grid item xs={12} md={10} className="ticket-actions-col">
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleForwardTicketClick( e, 'task-postpone' )} >
                Lükka edasi
              </Button>
              
              &nbsp; &nbsp;
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleForwardTicketClick( e, 'task-forward' )} >
                Suuna edasi
              </Button>
              
              &nbsp; &nbsp;
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleForwardTicketClick( e, 'task-forward-booking' )} >
                Broneeri aeg
              </Button>
              &nbsp; &nbsp;
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleForwardTicketClick( e, 'task-forward-referred' )} >
                 Registratuuri
              </Button>

              &nbsp; &nbsp;
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleForwardTicketClick( e, 'task-finish' )} >
                Lahendatud
              </Button>
              &nbsp; &nbsp;
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleForwardTicketClick( e, 'task-refused' )} >
                Pole lahendatav
              </Button>
              &nbsp; 
              {/*
              <IconButton color="primary" component="span" onClick={(e) => that.handleExitTicketClick( e )} >
                <AppIcon iconName="save" size="large" />
              </IconButton>
              */}
              {(!this.state.requestedClose ) && (
                <IconButton color="primary" component="span" onClick={(e) => that.handleExitTicketClick( e )} >
                  <AppIcon iconName="close" size="large" />
                </IconButton>
              )}
              {(this.state.requestedClose ) && (
                <IconButton color="primary" component="span">
                  <CircularProgress size="35px" />
                </IconButton>
              )}
            </Grid>
          )}
          {(this.props.mode === "view" ) && (
            <Grid item xs={12} md={10} className="ticket-actions-col">
              
              {( this.props.ticket.isLocked ) && (
                <IconButton color="primary" component="span" title={lockedToText}>
                  <AppIcon iconName="locked" size="large" />
                </IconButton>
              )}

              {(!this.state.requestedEdit ) && (
                <IconButton color="primary" component="span" onClick={(e) => that.handleTicketEditRequestClick( e )} >
                  <AppIcon iconName="edit" size="large" />
                </IconButton>
              )}
              {(this.state.requestedEdit ) && (
                <IconButton color="primary" component="span">
                  <CircularProgress size={30} />
                </IconButton>
              )}

              {(!this.state.requestedClose ) && (
                <IconButton color="primary" component="span" onClick={(e) => that.handleExitTicketClick( e )} >
                  <AppIcon iconName="close" size="large" />
                </IconButton>
              )}
              {(this.state.requestedClose ) && (
                <IconButton color="primary" component="span">
                  <CircularProgress size={30} />
                </IconButton>
              )}
              
            </Grid>
          )}

          <Grid item xs={12} md={12} className="ticket-meta-col">
            <p className="ticket-arrival-date">
              
              <span>Pilet saabus {this.props.ticket.dateTimeFormattedLong} </span>
            
              {(ticketHistory.hasRecords) && (
                <span>
                  | <a href="#" onClick={function( e ){ that.handleTicketHistoryToggleClick( e ); return false; }}>
                    <AppIcon iconName="history" size="small" /> 
                    Ajalugu
                  </a>
                </span>
              )}

              <Popper open={this.state.ticketHistoryOpen} anchorEl={this.state.ticketHistoryBtnEl}>
                <Paper elevation={this.paperElevation} className="health-ticket-history-records">
                  <div dangerouslySetInnerHTML={{__html: ticketHistory.content }}></div>
                </Paper>
              </Popper>

              {(etaText) && (
                <span className="ticket-expected-solving-time-x"> | Soovitav lahendamise aeg {etaText}</span>
              )}

              &nbsp;

              {(this.props.mode === "view" && this.state.ticketBookingUrl ) && (
                <span> <AppIcon iconName="bookingLink" size="small" /> 
                  {/** 
                  <a href={this.props.ticket.webBookingUrl} target="_blank">Broneerimise link</a>
                  */}
                  <a href="#" onClick={() => this.moveToBookingPart( this.state.ticketBookingUrl )}>Broneerima</a>
                </span>
              )}
            </p>
          </Grid>

        </Grid>

        <Grid container className="health-ticket-body-container">
          <Grid item xs={12} md={8} className="ticket-body-left">
            <Paper elevation={this.paperElevation} className="health-ticket-part health-ticket-main">
              
              {/**
              <Grid container className="health-ticket-name-and-priority-container">
                <Grid item xs={12} md={6} className="details-left" >
                </Grid>
                <Grid item xs={12} md={6} className="details-right" >
                </Grid>
              </Grid>
               */}
               
              <Grid container className="health-ticket-details-container">
                <Grid item xs={12} md={5} className="details-left" >
                  <h3>{this.props.ticket.personDisplayName}</h3>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="cell-label">Isikukood</td>
                        <td className="cell-data">{this.props.ticket.personCode}</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Aadress</td>
                        <td className="cell-data">{this.props.ticket.patientAddress}</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Telefon</td>
                        <td className="cell-data">{this.props.ticket.patientPhone}</td>
                      </tr>
                      <tr>
                        <td className="cell-label">E-mail</td>
                        <td className="cell-data">{this.props.ticket.patientEmail}</td>
                      </tr>
                      <tr>
                        <td className="cell-label">PAK</td>
                        <td className="cell-data">{this.props.ticket.patientGeneralPracticionerOrganizationDisplayName}</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Nimistu</td>
                        <td className="cell-data">{gpData}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs={12} md={7} className="details-right" >
                  <h3>Pileti nr {this.props.ticket.ticketNumber}</h3>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="cell-label">Teenus</td>
                        <td className="cell-data">{this.props.ticket.serviceName}</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Lisainfo</td>
                        <td className="cell-data">{this.props.ticket.additionalInfo}</td>
                      </tr>
                      <tr>
                        <td className="cell-label" style={{ "verticalAlign": "top" }}>Patsiendi kommentaar</td>
                        <td className="cell-data">{this.props.ticket.patientNoteText}</td>
                      </tr>
                    </tbody>
                  </table>
                  

                </Grid>

                {/* edit part - start */}
                  <Grid item xs={12}>

                    {(this.props.mode === "edit" ) && (
                      <h3>Pileti muutmine ja suunamine</h3>
                    )}
                    {(this.props.mode === "view" ) && (
                      <h3>Pileti suunamise info</h3>
                    )}
                      
                    <table style={{ width: "100%" }} className={classNamesChangableData}>
                      <tbody>
                        <tr>
                          <td className="cell-label vertical-middle">Prioriteet</td>
                          <td className="cell-data">
                            {(this.props.mode === "view" ) && (
                              <Chip className={priorityClasses} label={priorityText} />
                            )}
                            &nbsp;
                          </td>
                        </tr>
                        {(this.props.mode === "edit" ) && (
                          <tr>
                            <td className="cell-data-fullwidth" colSpan="2">
                              
                              <div className="inline-selector">
                                {that.priorities.map( function(priority, index){
                                  let classNames = " priority-indicator-text-" + priority.code + " priority-indicator-bg-" + priority.code;
                                  
                                  let itemClasses = ' selection';
                                  if( that.props.ticket.priorityPreferred.priorityCode === priority.code ){
                                    itemClasses = ' selection selection-reccomended';
                                  }

                                  if( currentPriority == priority.code ){
                                    itemClasses = ' selection selection-selected';
                                    classNames = " priority-indicator-text-" + priority.code + " priority-indicator-bg-" + priority.code;
                                  }
                                  
                                  let aKey = 'ps_' + priority.code;
                                  
                                  return (
                                    <a key={aKey} className={itemClasses} onClick={() => that.handlePriorityChange(priority.code)}  key={priority.code} >
                                      <Chip className={classNames} label={priority.label} />
                                    </a>
                                  );
                                })}
                              </div>
                              
                              {/*
                              <List>
                                {that.priorities.map( function(priority, index){
                                  let classNames = " priority-indicator-text-" + priority.code + " priority-indicator-bg-" + priority.code;
                                  return (
                                  <ListItem button onClick={() => that.handlePriorityChange(priority.code)} key={priority.code}>
                                    <Chip className={classNames} label={priority.label} style={{ "width" : "100%", "cursor" : "pointer" }} />
                                  </ListItem>
                                  );
                                })}
                              </List>
                              <Chip className={priorityClasses} label={priorityText} />
                              &nbsp;&nbsp;
                              {(this.props.mode === "edit" ) && (
                                <IconButton color="primary" component="span" onClick={(e) => that.handlePrioritySelectorOpen( e )} >
                                  <AppIcon iconName="edit" size="small" />
                                </IconButton>
                              )}
                            */}

                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="cell-label">Eelistatud suhtlusviisid</td>
                          <td className="cell-data">
                            {(this.props.mode === "view" ) && (
                              <span>{communicationMethodsDisplay}</span>
                            )}
                            &nbsp;
                          </td>
                        </tr>
                        {(this.props.mode === "edit" ) && (
                          <tr>
                            <td className="cell-data-fullwidth" colSpan="2">
                                <div className="inline-selector">
                                  {communicationMethods.map( function(method, index){
                                    let classNames = " ";
                                    let itemClasses = ' selection';

                                    if( that.props.ticket.communicationMethodPreferred === method.id ){
                                      itemClasses = ' selection selection-reccomended';
                                    }

                                    for( var m in that.state.communicationMethods ){
                                      if( that.state.communicationMethods[ m ] === method.id  ){
                                        itemClasses = ' selection selection-selected';
                                      }
                                    }

                                    let aKey = 'cm_' + method.id;
                                    
                                    return (
                                      <a key={aKey} className={itemClasses} onClick={( e ) => that.handleCommnicationMethodToggleClick( e, method )} >
                                        <Chip className={classNames} label={method.label} key={method.id} />
                                      </a>
                                    );
                                  })}
                                </div>
                              {/**
                              {(this.props.mode === "edit" ) && (
                                <Select
                                  value={that.state.communicationMethods}
                                  onChange={( e ) => that.handleCommnicationMethodSelect( e, e.target.value )}
                                  multiple
                                  className="communication-method-selector"
                                  renderValue={(selected) => (
                                    <div className="">
                                      {selected.map( function( method, index ){
                                        let label = method;
                                        for( var i in communicationMethods ){
                                          if( method === communicationMethods[ i ].id ){
                                            label = communicationMethods[ i ].label ;
                                          }
                                        }
                                        return (
                                          <Chip key={method} label={label} />
                                        );
                                      })}
                                    </div>
                                  )}
                                >
                                  {communicationMethods.map( function( method, index ){
                                    return (
                                      <MenuItem value={method.id} key={method.id}>{method.label}</MenuItem>
                                    );
                                  })}
                                </Select>
                              )}
                              */}
                            </td>
                          </tr>
                        )}
                        {/** 
                        <tr>
                          <td className="cell-label-forward" colSpan="2">Pileti suunamine</td>
                        </tr>
                        */}
                        
                        <tr>
                          <td className="cell-label">Töötaja / Asutus</td>
                          <td className="cell-data">
                              {(this.props.mode === "view" ) && (
                                <span>
                                  {(this.state.practicionerAutoCompleteValue === null ) && (
                                    <span>-</span>
                                  )}
                                  {(this.state.practicionerAutoCompleteValue !== null ) && (
                                    <span>{this.state.practicionerAutoCompleteValue.practicionerDisplayName}</span>
                                  )}
                                </span>
                              )}
                            &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td className="cell-data-fullwidth" colSpan="2">
                            {/** 
                             * renderGroup is also if needed besides renderOption
                             * 
                            */}
                            {(this.props.mode === "edit" ) && (
                              <Autocomplete
                                size="small"
                                value={that.state.practicionerAutoCompleteValue}
                                options={practicionersList}
                                disableCloseOnSelect={false}
                                noOptionsText="Sobivad valikud puuduvad"
                                getOptionLabel={(option) => option.practicionerDisplayName}
                                groupBy={(option) => option.groupByValue}
                                style={{ width: "100%" }}
                                onChange={(event, newValue) => {
                                  
                                  if( newValue != null && typeof newValue.practicionerDisplayNameBuffer != 'undefined' ){
                                    setTimeout(() => {
                                      that.handlePracticionerFormOpen();
                                      newValue.practicionerDisplayName = newValue.practicionerDisplayNameBuffer;
                                      this.setState({
                                        "practisionerDialog" : newValue
                                      });
                                    });
                                  } else if ( typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                      that.handlePracticionerFormOpen();
                                      /* 
                                      setpractisionerDialog({
                                        title: newValue,
                                        year: '',
                                      });
                                      */
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    that.handlePracticionerFormOpen();
                                    /* 
                                    setpractisionerDialog({
                                      title: newValue.inputValue,
                                      year: '',
                                    });
                                      */
                                  } else {
                                    let pendingUpdates = this.state.pendingUpdates;

                                    if( newValue != null && typeof newValue.resourceIdObject != 'undefined' ){
                                      pendingUpdates.restrictionPracticionerRoleId = newValue.resourceIdObject;
                                    } else {
                                      pendingUpdates.restrictionPracticionerRoleId = null;
                                    }

                                    that.setState( {
                                      "practicionerAutoCompleteValue" : newValue,
                                      "pendingUpdates" : pendingUpdates
                                    } );
                                  }
                                }}
                                filterOptions={( options, params ) => {
                                  var filtered = filter( options, params );
                                  /** 
                                  // Suggest the creation of a new practicioner
                                  if (params.inputValue !== '') {
                                    filtered.push({ 
                                      "resourceId" : "new",
                                      "practicionerDisplayName" : 'Lisa "' + params.inputValue + '"', 
                                      "practicionerDisplayNameBuffer" : params.inputValue, 
                                      "practicionerCode": '', 
                                      "organizationDisplayName" : "", 
                                      "organizationCode" : ""
                                    });
                                  }
                                  */
                                  return filtered;
                                }}
                                renderOption={(option) => {
                                  return (
                                    <React.Fragment>
                                      <span className="option-practicioner bold">{option.practicionerNameForAutocomplete}</span>
                                      {( option.showOrganizationDisplayName ) && (
                                        <span className="greyed-out not-bold"> &nbsp; ( {option.practicionerCode}, &nbsp;{option.organizationDisplayName} )</span>
                                      )}
                                      {/*( typeof option.resourceId != 'object' && option.resourceId != 'new' ) && (
                                        <a href="#n" className="edit-listitem"  onClick={( e ) => that.handleHealtOrganizationEditClick( e, option)} >
                                          <AppIcon iconName="edit" size="small" />
                                        </a>
                                      )*/}
                                      {/*( typeof option.resourceId != 'object' && option.resourceId != 'new' ) && (
                                        <a href="#n" className="delete-listitem"  onClick={( e ) => that.handleHealtPracticionerRoleDeletelick( e, option)} >
                                          <AppIcon iconName="trash" size="small" />
                                        </a>
                                      )*/}
                                    </React.Fragment>
                                )}}
                                renderGroup={(params) => {
                                  let foundLabel = params.group;
                                  /*
                                  console.log( params.children[ i ] );
                                  */
                                  return (
                                    <li key={foundLabel}>
                                      <GroupHeader>{foundLabel}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}}
                                
                                renderInput={(params) => <TextField {...params} label="Otsi..." size="small" variant="outlined" />}
                              />
                            )}
                            
                              
                            {( this.state.practicionerBeingDeleted ) && (
                              <div>
                                <p style={{ "verticalAlign": "middle" }}>
                                  <CircularProgress  style={{ "verticalAlign": "middle" }} size={24} /> &nbsp; kustutan
                                </p>
                              </div>
                            )}
                            
                            {( this.state.practicionerWasDeletedName != '' ) && (
                              <div>
                                <p style={{ "verticalAlign": "middle" }}>
                                  Kustutatud "{this.state.practicionerWasDeletedName}"
                                </p>
                              </div>
                            )}
                            {/*
                            
                              example output generated inside MuiAutocomplete-popper

                              <div class="MuiPaper-root MuiAutocomplete-paper MuiPaper-elevation1 MuiPaper-rounded">
                                <ul id="mui-45812-popup" class="MuiAutocomplete-listbox" role="listbox" aria-labelledby="mui-45812-label">
                                  <li>
                                    <div class="MuiListSubheader-root MuiAutocomplete-groupLabel MuiListSubheader-sticky MuiListSubheader-gutters">Tarvastu perearstikeskus</div>
                                    <ul class="MuiAutocomplete-groupUl">
                                      <li id="mui-45812-option-0" class="MuiAutocomplete-option" tabindex="-1" role="option" data-option-index="0" aria-disabled="false" aria-selected="false"><span class="option-practicioner">Peeter Paanika </span></li>
                                    </ul>
                                  </li>
                                  <li>
                                    <div class="MuiListSubheader-root MuiAutocomplete-groupLabel MuiListSubheader-sticky MuiListSubheader-gutters">v&auml;ike-Maarja perearstikeskus</div>
                                    <ul class="MuiAutocomplete-groupUl">
                                      <li id="mui-45812-option-1" class="MuiAutocomplete-option" tabindex="-1" role="option" data-option-index="1" aria-disabled="false" aria-selected="false"><span class="option-practicioner">Mari Mets </span></li>
                                    </ul>
                                  </li>

                                </ul>
                              </div>
                            */}




                          </td>
                        </tr>

                        {/*
                        <tr>
                          <td className="cell-label">Töötaja</td>
                          <td className="cell-data">
                            
                            <a href="#n" className="edit-listitem" style={{ width: "10%", "float" : "left", "display" : "block", "marginTop" : "10px" , "marginLeft" : "5px" }} >
                              <AppIcon iconName="edit" size="small" />
                            </a>
                            <a href="#n" className="edit-listitem" style={{ width: "10%", "float" : "left", "display" : "block", "marginTop" : "10px", "marginLeft" : "5px" }} >
                              <AppIcon iconName="add" size="small" />
                            </a>

                          </td>
                        </tr>
                        */}

                      </tbody>
                    </table>

                  </Grid>
                {/* edit part - end */}


              </Grid>
              
            </Paper>
            <Paper elevation={this.paperElevation} className="health-ticket-part">
              <h3>Patsiendi eelnevad piletid  </h3>
              <PreviousTicketsTable tickets={this.props.previousTickets}/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} className="ticket-body-right">
            <Paper elevation={this.paperElevation} className="health-ticket-part">
              <h3>Sümptomiküsimustik</h3>
              <PreviewQuestionnaireResponse 
                ticket={this.props.ticket}
                questionnaireResponse={this.props.ticket.questionnaireResponse}
              />
            </Paper>
            {(this.props.mode === "view" ) && (
              <Paper elevation={this.paperElevation} className="health-ticket-part ticket-notes">
                <h3>Terviseanalüütiku märkmed</h3>
                <span dangerouslySetInnerHTML={{__html: this.preapareLineFeeds(this.props.ticket.practicionerNoteText)}}></span>
              </Paper>
            )}
            {(this.props.mode === "edit" ) && (
              <Paper elevation={this.paperElevation} className="health-ticket-part ticket-notes">
                <h3>Terviseanalüütiku märkmed</h3>
                <textarea 
                  value={this.state.practicionerNoteText} 
                  onChange={(event) => that.handlePracticionerNoteChange( event.target.value ) } ></textarea>
              </Paper>
            )}
          </Grid>
        </Grid>


        <Dialog onClose={() => that.handlePriorityChange('')} aria-labelledby="simple-dialog-title" open={this.state.prioritySelectorOpen}>
          <DialogTitle >Muuda prioriteeti</DialogTitle>
          <List>
            {that.priorities.map( function(priority, index){
              let classNames = " priority-indicator-text-" + priority.code + " priority-indicator-bg-" + priority.code;
              return (
              <ListItem button onClick={() => that.handlePriorityChange(priority.code)} key={priority.code}>
                <Chip className={classNames} label={priority.label} style={{ "width" : "100%", "cursor" : "pointer" }} />
              </ListItem>
              );
            })}
          </List>
        </Dialog>
        
        <Dialog open={that.state.practicionerFormOpen} onClose={that.handlePracticionerFormClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
          <form>
            
            {( that.state.practisionerDialog.resourceId == 'new' ) && (
              <DialogTitle id="form-dialog-title">Lisa uus töötaja</DialogTitle>
            )}
            {( that.state.practisionerDialog.resourceId != 'new' ) && (
              <DialogTitle id="form-dialog-title">Muuda töötajat</DialogTitle>
            )}
            
            <DialogContent fullWidth={true} maxWidth="sm">
              <h3>Töötaja</h3>
              <TextField
                autoFocus
                margin="dense"
                value={that.state.practisionerDialog.practicionerDisplayName}
                onChange={(event) => that.setPractisionerDialogValue( "practicionerDisplayName", event.target.value ) }
                label="Nimi"
                type="text"
                fullWidth={true} maxWidth="sm"
              />
              <br />
              <TextField 
                margin="dense"
                value={that.state.practisionerDialog.practicionerCode}
                onChange={(event) => that.setPractisionerDialogValue( "practicionerCode", event.target.value ) }
                label="Kood"
                type="text" 
                fullWidth={true} maxWidth="sm"
              />
              <br />
              <h3>Organisatsioon</h3>
              <TextField
                margin="dense"
                value={that.state.practisionerDialog.organizationDisplayName}
                onChange={(event) => that.setPractisionerDialogValue( "organizationDisplayName", event.target.value ) }
                label="Nimi"
                type="text"
                fullWidth={true} maxWidth="sm"
              />
              <br />
              <TextField
                margin="dense"
                value={that.state.practisionerDialog.organizationCode}
                onChange={(event) => that.setPractisionerDialogValue( "organizationCode", event.target.value ) }
                label="Kood"
                type="text"
                fullWidth={true} maxWidth="sm"
              />
              <br />
              
              {( that.state.practicionerFormError ) && (
                <div className="mdl-error-message" style={{"marginTop" : "15px"}}>
                  {that.state.practicionerFormError}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={that.handlePracticionerFormClose} color="primary">
                Tühista
              </Button>
              {( that.state.practisionerDialog.resourceId == 'new' ) && (
                <Button type="button" color="primary" onClick={that.handlePracticionerFormSubmit} >
                  Lisa
                </Button>
              )}
              {( that.state.practisionerDialog.resourceId != 'new' ) && (
                <Button type="button" color="primary" onClick={that.handlePracticionerFormSubmit} >
                  Uuenda
                </Button>
              )}

              {( that.state.practicionerFormSaving ) && (
                <IconButton color="primary" component="span">
                  <CircularProgress size={30} />
                </IconButton>
              )}

            </DialogActions>
          </form>
        </Dialog>
        
        <Dialog 
          key="health-analyst-tool-dialog"
          open={this.state.askPracticionerDeleteIsOpen}
          onClose={this.handleDialogAskPracticionerDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Kustutame?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Kas olete kindel, et soovite "{this.state.askPracticionerDeleteForName}" kustutada? 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogAskPracticionerDeleteClose} color="primary" autoFocus>
              Ei
            </Button>
            <Button onClick={this.handleDialogAskPracticionerDeleteAgree} color="secondary">
              Jah, kustuta
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
    );
  }


  renderBookingPartView() {
    var that = this;

    let classes = "health-ticket-container booking-iframe-minmax ";
    if( this.state.ticketBookingIframeMaximized ){
      classes = classes + " booking-iframe-maximized";
    }
    
     

    return (
      <Grid container className={classes}>

        <Grid container className="health-ticket-head-container">

          <Grid item xs={12} md={12} className="ticket-actions-col">
            
            <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleBookingPartDone( e )} >
              Broneerimine lõpetatud
            </Button>

            &nbsp; &nbsp;
            
            {( !that.state.ticketBookingIframeMaximized ) && (
              <IconButton 
                type="button" 
                color="default" 
                target="_blank"
                component="a"
                href={this.state.ticketBookingUrl}
                onClick={function(e){
                  // Give browser some time to follow the link. And then we close view.
                  let timer = setTimeout(function(){
                    that.handleBookingPartDone( e );
                  }, 500);
                } }
              >
                <AppIcon size="medium" iconName="openInNewWindow" useLabel="Ava uues aknas" />
              </IconButton>
            )}
          
            &nbsp; &nbsp;
            
            {( that.state.ticketBookingIframeMaximized ) && (
              <IconButton type="button" color="secondary" onClick={that.handleToggleMaximizeIframe}  component="span" >
                <AppIcon size="medium" iconName="viewContainedScreen" useLabel="Vähenda vaadet (või vajuta f klahvi)" />
              </IconButton>
            )}
            {( !that.state.ticketBookingIframeMaximized ) && (
              <IconButton type="button" color="secondary" onClick={that.handleToggleMaximizeIframe}  component="span" >
                <AppIcon size="medium" iconName="viewFulleScreen" useLabel="Suurenda vaadet (või vajuta f klahvi)" />
              </IconButton>
            )}

          </Grid>
        </Grid>

        <Grid container className="health-ticket-booking-iframe-container">
          <iframe src={this.state.ticketBookingUrl} style={{"width": "100%", "minHeight" : "750px"}} className="booking-iframe"></iframe> 
        </Grid>
        
      </Grid>
    );
          
  }


}

export default HealthTicket;
