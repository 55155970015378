
import StateMachine from 'react-simple-state/dist';
import SessionCookie from './Utils/SessionCookie.js';

const appState = new StateMachine();


/**
 * Fake webSocket connection for testing
 * 
 */

function WebSocketClient() {
  this.enpointUrl = 'wss://tean.abtram.eu/suva/wss';
  this.counter = 1;
  this.socketOpened = false;
  this.pingTimer = null;
  this.reConnectTimer = null;
  this.nextPingTime = 0;
  this.wsPingstarted = false;
  this.scoketListener = null;
}


WebSocketClient.prototype.init = function ( emitter ) {
  this.nextPingTime = Math.floor(Date.now() / 1000) + 30;
  this.emitter = emitter;
  // this.eventProcessor = eventProcessor;
  this.initRealWebSocket();
};


WebSocketClient.prototype.setEndpointUrl = function ( url ) {
  this.enpointUrl = url;
};


WebSocketClient.prototype.setListener = function ( listener ) {
  this.scoketListener = listener;
};


/**
 * For testing with real WS
 * 
 */
WebSocketClient.prototype.initRealWebSocket = function () {
  console.log('Init WebSocket');
  var that = this;
  
  if(this.reConnectTimer ){
    clearTimeout( this.reConnectTimer );
  }

  // if user is running mozilla then use it's built-in WebSocket
  window.WebSocket = window.WebSocket || window.MozWebSocket;

  var sessionCookie = new SessionCookie();
  const token = sessionCookie.readCookieValue("app-auth-token");
  
  let resetSessionCookie = '?reset';
  if ( token ) {
    resetSessionCookie = '';
  }

  this.connection = new WebSocket( this.enpointUrl + resetSessionCookie );

  this.connection.onopen = function () {
    that.socketOpened = true;
    appState.evoke('connection-state', { connected: true });
    console.log('WS CONNECTION OPENED');

    if (!that.wsPingstarted) {
      that.initRealWebSocketPing();
    }
    
    var sessionCookie = new SessionCookie();

    const token = sessionCookie.readCookieValue("app-auth-token");

    if (token) {
      var tokenBundle =
      {
        "resourceType": "Bundle",
        "type": "batch",
        "entry": [
          {
            "resource": {
              "resourceType": "Person",
              "identifier": [
                {
                  "system": "https://tean.abtram.eu/fhir/namingsystem/session/token",
                  "value": token
                }
              ],
              "managingOrganization": {
                "reference": "Organization/haigla"
              }
            },
            "request": {
              "method": "POST",
              "url": "Person"
            }
          }
        ]
      }

      that.connection.send(JSON.stringify(tokenBundle));

    }

  };

  this.connection.onclose = function ( closeEvent ) {
    that.socketOpened = false;
    // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
    appState.evoke('connection-state', { connected: false });
    console.log('WS CONNECTION closed with following code');
    console.log( closeEvent );

    // close withoud re-connecting
    if( closeEvent.code == 4999 ) {
      return;
    }

    this.reConnectTimer = setTimeout(function () {
      that.connection = null;
      that.initRealWebSocket();
    }, 100);
  };

  this.connection.onerror = function (error) {
    appState.evoke('connection-state', { connected: false });
    that.socketOpened = false;
    console.log('WS CONNECTION ERROR : ' + error);
  };

  this.connection.onmessage = function (message) {
    var jsonin = null;

    try {
      jsonin = JSON.parse(message.data);
    } catch (e) {
      console.log('This doesn\'t look like a valid JSON: ' + e.message, message.data);
      return;
    }

    
    that.emitter.emit( 'ServerCommunicationInRaw', {
      'conponent' : 'WebSocketClient',
      'rawJson' : jsonin
    } );

    //that.this.scoketListener.handleIncomingJson( jsonin );
    //that.eventProcessor.handleServerEvent('success', jsonin);
  };

};


/**
 * Keep socket open by sending pings
 * 
 */
WebSocketClient.prototype.initRealWebSocketPing = function () {
  var secondsBetweenPings = 45;
  this.wsPingstarted = true;
  var that = this;
  var test = Math.floor(Date.now() / 1000);

  if (test > this.nextPingTime  && this.socketOpened ) {
    this.nextPingTime = Math.floor(Date.now() / 1000) + secondsBetweenPings;
    this.connection.send(JSON.stringify( {
        "resourceType": "Bundle"
    } ));
  }

  this.pingTimer = setTimeout(function () { that.initRealWebSocketPing(); }, 3000);
}

WebSocketClient.prototype.resetConnection = function () {
  var that = this;

  // do we need to send some bundle before? 
  if( this.pingTimer ){
    clearTimeout(this.pingTimer);
  }
  if(this.reConnectTimer ){
    clearTimeout( this.reConnectTimer );
  }
  
  this.connection.close( 1000 );

  this.reConnectTimer = setTimeout(function () {
    that.connection = null;
    that.initRealWebSocket();
  }, 500);

}

WebSocketClient.prototype.closeConnection = function () {
  var that = this;

  // do we need to send some bundle before? 
  if( this.pingTimer ){
    clearTimeout(this.pingTimer);
  }

  if(this.reConnectTimer ){
    clearTimeout( this.reConnectTimer );
  }
  
  this.connection.close( 4999 );
}


/**
 * 
 * 
 * @return boolean
 */
WebSocketClient.prototype.sendBundle = function (bundle) {
  var res = bundle.entry[0].resource;
  var that = this;

  // Fail immidately if socket is not opened
  if( !this.socketOpened ){
    console.log('WebSocketClient : send failed - no connection');
    return false;
  }

  // and all others to new WS server
  try {
    this.connection.send(JSON.stringify(bundle));
  } catch (e) {
    console.log('WebSocketClient : send failed ');
    console.log(e);
    return false;
  }

  return true;
};


export default WebSocketClient;