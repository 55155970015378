
'use strict';



/**
 * 
 * @param object i18nProvider
 * @return void
 */
function DateTimeFormatter ( i18nProvider ) {
  this.i18nProvider = i18nProvider;
}


/**
 * Get value or return null
 * 
 * @param Date dateTime
 * @return string
 */
DateTimeFormatter.prototype.getTimeAgoAsTranslatedString = function( dateTime ) {
  let timeAgo = this.getTimeAgo( dateTime );
  
  if( ( timeAgo.unit == 'second' || timeAgo.unit == 'seconds' ) && timeAgo.value < 5 ){
    return ( "just now" );
  }
  
  if( this.i18nProvider && this.i18nProvider !== null ){
    return timeAgo.value + ' ' + this.i18nProvider.t( timeAgo.unit ) + ' ' + this.i18nProvider.t( 'ago' );
  }

  return timeAgo.value + ' ' + timeAgo.unit + ' ago';
}


/**
 *  
 * 
 * @param Date val
 * @return string
 */
DateTimeFormatter.prototype.getTimeAgo = function( val ) {
  val = 0 | ( Date.now() - val.getTime() ) / 1000;

  if( val < 1 ){
    return {
      unit: 'second',
      value: 0
    };
  }

  var unit, length = { second: 60, minute: 60, hour: 24, day: 7, week: 4.35,
    month: 12, year: 10000 }, result;

  for (unit in length) {
    result = val % length[unit];
    if (!(val = 0 | val / length[unit])){
      //return result + ' ' + (result-1 ? unit + 's' : unit); 
      return {
        unit: (result-1 ? unit + 's' : unit),
        value: result
      };
    }
  }
}


/**
 * 
 * 
 * @param Date d
 * @return string dateTime In format "Y-m-dTH:i:s+00:00" like "2021-08-03T22:22:59+03:00" 
 */
 DateTimeFormatter.prototype.defaultFormatWithTimezone = function( d ) {
  
  let tz = '+02:00'; //TODO: We need actual timezone here

  let monthBaseOne = d.getMonth() + 1;
  let month = ( monthBaseOne > 9 ) ? monthBaseOne : '0' + monthBaseOne;
  let day = ( d.getDate() > 9 ) ? d.getDate() : '0' + d.getDate();
  let hours = ( d.getHours() > 9 ) ? d.getHours() : '0' + d.getHours();
  let minutes = ( d.getMinutes() > 9 ) ? d.getMinutes() : '0' + d.getMinutes();
  let seconds = ( d.getSeconds() > 9 ) ? d.getSeconds() : '0' + d.getSeconds();
  let dtFormatted = d.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + tz;

  return dtFormatted;
}


export default DateTimeFormatter;