/**
 * 
 * 
 * 
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SubscriptionsTable from './SubscriptionsTable';

class NotificationSubscriptions extends React.Component {

  constructor( props ) {
    super( props );
    this.myRef = React.createRef();

    this._isMounted = false;
    this.timerRequestTicketViewPoll = null;

    this.state = {
      "forceReRender" : 1
    }

  }


  componentDidMount() {
    this._isMounted = true;
    var that = this;

    this.pollTest();

    var updateTicketsList = function (eventPayLoad) {
      if (that._isMounted) {
        
      }
    };
    
    window.appEventsEmitter.emit( 'rendered', { "eventSource" : "NotificationSubscriptions" } );
  }


  componentWillUnmount() {
    this._isMounted = false;

    if( this.timerRequestTicketViewPoll != null ){
      clearTimeout( this.timerRequestTicketViewPoll );
      this.timerRequestTicketViewPoll = null;
    }
  }


  pollTest(){
    return;
    // https://stackoverflow.com/a/938195
    const node = this.myRef.current;
    console.log("pollTest");
    console.log(node);
    console.log( node.contentWindow.location.href );
    
    var that = this;

    this.timerRequestTicketViewPoll = setTimeout(function(){
      that.pollTest();
    }, 300);
  }


  render() {
    var that = this;
    

    let wrapperClasses = '';
    if ( false ) {
      wrapperClasses = 'state-list-loading';
    }

    let subscriptions = [
      {
        "subscriptionId": "2",
        "reasonText": "Ainult kollased teated",
        "prioritydisplay": "Punased",
        "registriesDisplay": "N0231 - Dr Maie Metsanukk - Märjamaa, N9323 - Mari Mets - Lasnamäe"
      },
      {
        "subscriptionId": "3",
        "reasonText": "Isiklikud ",
        "prioritydisplay": "Punased, sinised",
        "registriesDisplay": "N332 - Mai Juuni - Harjumaa, N9323 - Neeme Nali - Kristiine"
      },
      {
        "subscriptionId": "4",
        "reasonText": "Ainult kollased teated",
        "prioritydisplay": "Ainult kollased teated",
        "registriesDisplay": "N0231 - Dr Maie Metsanukk - Märjamaa, N9323 - Mari Mets - Lasnamäe"
      }
    ];
    
    return (
      <Grid container className={wrapperClasses} spacing={3} key="notification-subscriptions-container" data-force={this.state.forceUpdate}>
        
        <div className="general-spin"></div>


        <Grid item xs={12} key="notification-subscriptions-grid-item">
          <div className="tickets-table-top">
            <h3 className="pull-left">Tellitud teated</h3>
          </div>
          <br />
          
          <SubscriptionsTable 
            key="notification-subscriptions-subscriptions-table"
            subscriptions={subscriptions}
          />
        </Grid>

      </Grid>
    );
  }

}

export default NotificationSubscriptions;
