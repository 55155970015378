/**
 * Tickets list to be used in one ticket view context
 * 
 */

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


class PreviousTicketsTable extends React.Component {x

  constructor( props ) {
    super( props );
    
    this.handleTicketClick = this.handleTicketClick.bind( this );
  }


  handleTicketClick ( event, ticket ){
    if( event != null ){
      event.preventDefault();
    }
    
    if( typeof this.props.onTicketclick != "undefined" ){
      this.props.onTicketclick( ticket );
    }
  }


  render() {
    var that = this;

    return (
      <Table aria-label="simple table" className="previous-tickets-table">
        <TableHead>
          <TableRow>
            <TableCell className="tickets-table-header" align="left">Pileti nr</TableCell>
            <TableCell className="tickets-table-header" align="left">Kuupäev</TableCell>
            <TableCell className="tickets-table-header" align="left">Teenusenimetus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.tickets.map( function(row, index){
            let key = row.ticketId + '--' + index;
            return (
              <TableRow key={key} className="tickets-table-row" onClick={(e) => that.handleTicketClick( e, row )} >
                <TableCell align="left">{row.ticketNumber}</TableCell>
                <TableCell align="left">{row.dateTimeFormatted}</TableCell>
                <TableCell align="left">{row.serviceName}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    );
  }

}

export default PreviousTicketsTable;
