
/**
 * Basically it is holding key-value pairs in array. We hold keys as 
 * properties of objects in that array. So key would not have 
 * constraints about what it is made of. You can have "(ಠ_ಠ)" as key if you want. 
 * 
 * @return void
 */
function KeyValueCache () {
  this.cache = [];
  
}


/**
 * Set value by key
 * 
 * @param string key
 * @param object value
 * @return void
 */
KeyValueCache.prototype.set = function( key, value ) {
  
  if( !this.get( key ) ){
    this.cache[ this.cache.length ] = {
      'key' : key,
      'value' : value
    }
  }

}


/**
 * Get value or return null
 * 
 * @param string key
 * @return object
 */
KeyValueCache.prototype.get = function( key ) {
  
  for( var i in this.cache ){
    if ( this.cache[ i ].key == key ) {
      return this.cache[ i ].value;
    }
  }
  
  return null;
}


/**
 * Is given key stored in storage
 * 
 * @param string key
 * @return boolean
 */
KeyValueCache.prototype.keyExists = function( key ) {
  
  for( var i in this.cache ){
    if ( this.cache[ i ].key == key ) {
      return true;
    }
  }
  
  return false;
}


/**
 * 
 * 
 * @return array
 */
KeyValueCache.prototype.getAllAsArray = function() {
  var out = [];

  for( var i in this.cache ){
    out[ this.cache[ i ].key  ] = this.cache[ i ].value;
  }
  
  return out;
}

export default KeyValueCache;