/**
 * Represents one PractitionerRole resource (DomainResource)
 * 
 */

import HumanName from './../Core/HumanName';
import { ContactPoint } from './../Core/ContactPoint';
import { DomainResource } from './../DomainResource/DomainResource';


class PractitionerRole extends DomainResource {
  
  /**
   * Patient
   * 
   * @param string resourceId
   * @return void
   */
  constructor(
    resourceId
  ) {
    super( 'PractitionerRole' );

    this._id = resourceId;

    this._telecoms = [];
  }
  

  /**
   * Adds telecom or replaces former one by system code like phone or e-mail
   * 
   * @param ContactPoint
   * @return void
   */
  updateTelecomBySystemCode( cp : ContactPoint ) {
    let foundIndex = -1;
    for( var i in this._telecoms ){
      if( this._telecoms[ i ].getSystemCode() == cp.getSystemCode() ){
        foundIndex = i;
      }
    }

    if( foundIndex < 0 ){
      foundIndex = this._telecoms.length;
    }

    this._telecoms[ foundIndex ] = cp;
    
    // overwrite telecom-s in resource literal
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.telecom = [];

      for( var i in this._telecoms ){
        this._resourceLiteral.telecom.push( this._telecoms[ i ].toObject() );
      }
    }
  }


  /**
   * Adds telecom or replaces former one by system code like phone or e-mail
   * 
   * @param code
   * @return void
   */
  removeTelecomsBySystemCode( code ) {
    for( var i in this._telecoms ){
      if( this._telecoms[ i ].getSystemCode() == code ){
        delete this._telecoms[ i ];
      }
    }

    // overwrite telecom-s in resource literal
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.telecom = [];

      for( var i in this._telecoms ){
        this._resourceLiteral.telecom.push( this._telecoms[ i ].toObject() );
      }
    }
  }


  /**
   * get telecom by system code like phone or email
   * 
   * @param code
   * @return void
   */
  getFirstTelecomBySystemCode( code ) {
    for( var i in this._telecoms ){
      if( this._telecoms[ i ].getSystemCode() == code ){
        return this._telecoms[ i ];
      }
    }

    return null;
  }


  /**
   * Overwrite parent method 
   * 
   * Set local variables based on input JSON
   * 
   * @param object json
   * @return boolean
   */
  setRawResourceObject( json ) {
    super.setRawResourceObject( json );
    
    if( typeof json.name != 'undefined' ){
      for( var i in json.name ){
        if( 
          typeof json.name[ i ].family != 'undefined' && 
          typeof json.name[ i ].given != 'undefined'
        ){
          let firstnames = '';

          for( var j in json.name[ i ].given ){
            firstnames = firstnames + '' + json.name[ i ].given[ j ];
          }

          firstnames = firstnames.trim();

          let fullName = new HumanName(
            'official', firstnames, json.name[ i ].family
          );

          this.officialName = fullName;
        }
      }
    }
    
    if( typeof json.identifier != 'undefined' ){
      for( var i in json.identifier ){
        if( 
          typeof json.identifier[ i ].system != 'undefined' && 
          typeof json.identifier[ i ].value != 'undefined' && 
          json.identifier[ i ].system == 'http://documental.eu/fhir/naming/person/personal_id'
        ){
          this.identifierCode = json.identifier[ i ].value;
        }
      }
    }

    if( typeof json.telecom != 'undefined' ){
      for( var i in json.telecom ){
        if( 
          typeof json.telecom[ i ].system != 'undefined' && 
          typeof json.telecom[ i ].value != 'undefined'
        ){
          let telecom = new ContactPoint(
            json.telecom[ i ].value, json.telecom[ i ].system, null
          );
          this._telecoms.push( telecom );
        }
      }
    }

  }


  /**
   * Overwrite parent method 
   * 
   * Build basic resource literal
   * This needs to be overwritten by actual resource
   * 
   * @return object
   */
  buidResourceLiteral() {
    super.buidResourceLiteral();

    //Dont build- it's already as json
    if( this._resourceSetByLiteral ){
      return;
    }
    
    this._resourceLiteral.identifier = [
      {
        "system": "http://documental.eu/fhir/naming/person/personal_id",
        "value": this.getIdentifierCodeAsString()
      }
    ];

    var fullName = this.getOfficialName();
    if( fullName != null ){
      this._resourceLiteral.name = [
        {
          "family": fullName.getLastName(),
          "given": fullName.getGivenNames()
        }
      ];
    }

    if( typeof this._resourceLiteral.telecom == 'undefined' ){
      this._resourceLiteral.telecom = [];
    }
    
    for( var i in this._telecoms ){
      this._resourceLiteral.telecom.push( this._telecoms.toObject() );
    }
  }

}


export default PractitionerRole;