

import React from "react";
import StateMachine from '../node_modules/react-simple-state/dist/';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import NotificationsOutlined from '@material-ui/icons/NotificationsOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import AccountCircle from '@material-ui/icons/PowerSettingsNewOutlined'
import HealthAnalystTool from './Components/HealthAnalystTool/HealthAnalystTool';
import NotificationSubscriptions from './Components/NotificationSubscriptions/NotificationSubscriptions';
import EmployeeManagement from './Components/EmployeeManagement/EmployeeManagement';
import LoginClientForm from  './Components/Login/LoginClientForm';

const appState = new StateMachine();



class App extends React.Component {

  constructor( props ) {
    super( props );
    
    this._isMounted = false;

    this.state = {
      "forceRenderCnt" : 0,
      "appContent" : "contentAnalystTool",
      "appAuthStatus" : appState.getState( 'app-auth-status' )

    }
    this.handleLogout = this.handleLogout.bind( this );
    this.handleDebugClick = this.handleDebugClick.bind( this );
    this.handleMainMenuClick = this.handleMainMenuClick.bind( this );
  }

  componentWillMount() {
    var that = this;

    appState.subscribe( 'app-auth-status', this, ( appAuthStatus ) => {
      let next = ( that.state.forceRenderCnt + 1 );
      that.setState({
        appAuthStatus: appAuthStatus,
        forceRenderCnt: next
      });
    });
  }

  
  componentDidMount() {
    this._isMounted = true;
    var that = this;

    var updateView = function (eventPayLoad) {
      if (that._isMounted) {
        that.updateView();
      }
    };
    
    window.appEventsEmitter.addListener("ServerCommunicationHandeled", updateView );
    this.listenerUpdateView = updateView;
  }

  handleLogout(){
    window.appEventsEmitter.emit( 'deauthenticated' );
  }


  componentWillUnmount() {
    appState.unsubscribe( 'app-auth-status', this );

    window.appEventsEmitter.removeListener(
      "ServerCommunicationHandeled",
      this.listenerUpdateView
    );
  }


  handleDebugClick(){
    let resources = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "PractitionerRole"
    } );
    console.log( window.appLoggedInUser );
    console.log( resources );
  }


  handleMainMenuClick( navigateToContent ){
    this.setState({
      "appContent": navigateToContent
    });
  }

  
  updateView(){
    let next = ( this.state.forceRenderCnt + 1 );
    this.setState({
      "forceRenderCnt": next
    });
  }


  /**
   * 
   * @return PractitionerRole|null
   */
  getCurrentlyLoggedInPracticionerRole(){
    if( typeof window.appLoggedInUser === 'undefined' || !window.appLoggedInUser.isLoggedIn ){
      return null;
    }

    return window.appEntityStore.getEntityByEntityId( window.appLoggedInUser.practitionerRoleId );
  }


  render(){
    var that = this;
    let isLoggedIn = this.state.appAuthStatus.isAuthenticated();

    let analystName = '';
    let practicionerRole = this.getCurrentlyLoggedInPracticionerRole();
    if( practicionerRole !== null ){
      let json = practicionerRole.toObject();
      analystName = json.practitioner.display;
    }

    let mainMenuUserAccessClasses    = "main-menu-item";
    let mainMenuHealthAnalystClasses = "main-menu-item";
    let mainMenuNoticesClasses = "main-menu-item";
    
    let contentTitle = "";

    if( this.state.appContent === "contentAnalystTool" ){
      mainMenuHealthAnalystClasses = mainMenuHealthAnalystClasses + "main-menu-active";
      contentTitle = "Terviseanalüütiku töölaud - " + analystName;
    }
    
    if( this.state.appContent === "contentUserAccess" ){
      mainMenuUserAccessClasses = mainMenuUserAccessClasses + "main-menu-active";
      contentTitle = "Töötajate haldus - " + analystName;
    }
    
    if( this.state.appContent === "contentNoticesManager" ){
      mainMenuNoticesClasses = mainMenuNoticesClasses + "main-menu-active";
      contentTitle = "Teatiste haldus - " + analystName;
    }

    return (
      <div className="App app-container">
        
        {( isLoggedIn ) && (
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" className="MuiButtonBase-root MuiIconButton-root jss312 MuiIconButton-colorInherit MuiIconButton-edgeStart" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className="MuiTypography-root jss313 MuiTypography-h6" onClick={this.handleDebugClick}>
                {contentTitle}
              </Typography>
              <div className="account-menu-icon-wrap">

                <IconButton
                  aria-label="Account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  className="account-menu-icon"
                  onClick={this.handleLogout}
                >
                  <AccountCircle /> &nbsp; logi välja
                </IconButton>
                
                <span className={mainMenuUserAccessClasses}>
                  <IconButton 
                    className="main-menu-icon"
                    color="inherit"
                    onClick={() => that.handleMainMenuClick("contentUserAccess")}
                    >
                    <PeopleOutlinedIcon />
                  </IconButton>
                </span>
                {/* 
                <span className={mainMenuNoticesClasses}>
                  <IconButton 
                    className="main-menu-icon"
                    color="inherit"
                    onClick={() => that.handleMainMenuClick("contentNoticesManager")}
                    >
                    <NotificationsOutlined />
                  </IconButton>
                </span>
                */}
                
                <span className={mainMenuHealthAnalystClasses}>
                  <IconButton 
                    className="main-menu-icon"
                    color="inherit"
                    onClick={() => that.handleMainMenuClick("contentAnalystTool")}
                    >
                    <ArchiveOutlinedIcon />
                  </IconButton>
                </span>

              </div>
            </Toolbar>
          </AppBar>
        )}

        {( !isLoggedIn ) && (
          <Container maxWidth={false} className="content-container mdl-layout__container app-main-container">
            <Grid className="mdl-grid padding-0 home-bg">
              <LoginClientForm />
            </Grid>
          </Container>
        )}

        {( isLoggedIn ) && (
          <Container maxWidth="lg" className="content-container">
            <br />
            <br />
            
            {( this.state.appContent === "contentAnalystTool" ) && (
              <HealthAnalystTool />
            )}
            
            {( this.state.appContent === "contentUserAccess" ) && (
              <EmployeeManagement />
            )}
            
            {/*( this.state.appContent === "contentNoticesManager" ) && (
              <NotificationSubscriptions />
            )*/}

          </Container>
        )}

      </div>
    );
  }
}

export default App;
