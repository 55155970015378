'use strict';



/**
 * Subscribe into render event 
 * 
 * 
 */ 

function RenderListener () {
  this.renderedTimer    = null;
  this.eventRendered = this.eventRendered.bind( this );
}


/**
 * Setup event listeners
 * 
 * @param object el
 * @return void
 */
RenderListener.prototype.startListening = function( el ) {
  //el.addListener( 'authenticated',         this.eventAuthenticated );
  //el.addListener( 'deauthenticated',       this.eventDeAuthenticated );
}


/**
 * For example if react component did render or some ajax callback is injected to DOM
 * We can trigger UI updates to comonents that need also re-rendering
 * 
 * At moment we only update Material UI elements
 * 
 * Timeout is added because we don't have to always update everything if 
 * done at batch (like rendering bunch of components at once). Only last one will 
 * fire after timer runs out
 * 
 * @param object eventIn
 */ 
RenderListener.prototype.eventRendered = function( eventIn ) {

  if ( this.renderedTimer ) {
    clearTimeout( this.renderedTimer );
  }

  var that = this;
  
  this.renderedTimer = setTimeout( function(){
    that.renderedTimer = null;
    
    // eslint-disable-next-line no-undef
    if( typeof window.componentHandler != 'undefined' ){
      // eslint-disable-next-line no-undef
      window.componentHandler.upgradeAllRegistered();

      //  [...document.querySelectorAll('.mdl-js-radio')].forEach(el => el.MaterialRadio.checkToggleState());
      //  [...document.querySelectorAll('.mdl-js-checkbox')].forEach(el => el.MaterialCheckbox.checkToggleState());
      
      [].map.call(document.querySelectorAll('.mdl-js-radio'), function(el) {
        el.MaterialRadio.checkToggleState();
      });
      [].map.call(document.querySelectorAll('.mdl-js-checkbox'), function(el) {
        el.MaterialCheckbox.checkToggleState();
      });
      [].map.call(document.querySelectorAll('.mdl-js-switch'), function(el) {
        el.MaterialSwitch.checkToggleState();
      });
    }
  } , 50 );
}

export default RenderListener;
