/**
 * Helper to initialize new or already existing ID
 * VO for resources
 * 
 * 
 */

import { ResourceId } from './ResourceId.js';


class ResourceIdFactory {

  /**
   * 
   */
  constructor() {

  }


  /**
   * 
   * 
   * @pram object params
   * @return ResourceId
   */
  create( params ) {

    let fullUrl = '';

    if( typeof params.resourceId == 'undefined' ){
      return null;
    }

    if( typeof params.resourceType == 'undefined' ){
      return null;
    }

    if( typeof params.fullUrl != 'undefined' ){
      fullUrl = params.fullUrl;
    }
    
    let id = new ResourceId( 
      params.resourceId, 
      params.resourceType, 
      fullUrl
    );

    return id;
  }

  createFromRawResourceObject( resourceJson ) {
    let id = '';
    let resourceType = '';
    
    if( typeof resourceJson.id != 'undefined' ){
      id = resourceJson.id;
    }

    if( typeof resourceJson.resourceType != 'undefined' ){
      resourceType = resourceJson.resourceType;
    }

    let resourceId = new ResourceId( 
      id, 
      resourceType, 
      ''
    );

    return resourceId;
  }

}


export { ResourceIdFactory };