
/**
 * Menu items rendered as badges
 * 
 * 
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppIcon from './AppIcon'


class BadgeMenu extends React.Component {

  constructor( props ) {
    super( props );

    this.handleBadgeClick = this.handleBadgeClick.bind( this );
  }


  handleBadgeClick ( event, item ){
    if( typeof this.props.onBadgeClick !== 'undefined' ){
      this.props.onBadgeClick( item );
    }
  }


  render(){
    var that = this;

    return (

      <Grid container className="badge-menu-container">
        
        {this.props.menuItems.map( function(badgeItem, index){
          //var isActive = that.isMenuItemActive( childItem );

          let badgeColumnClasses = 'badge-menu-column';
          if( typeof badgeItem.menuSelected != 'undefined' && badgeItem.menuSelected ){
            badgeColumnClasses = 'badge-menu-column active';
          }

          if( badgeItem.count != null ){
            badgeColumnClasses = badgeColumnClasses + " icon-with-spacing";
          }

          return (
            <Grid key={badgeItem.menuLinkId} item className={badgeColumnClasses}>
              <Paper elevation={2} className="badge-wrapper" onClick={(e) => that.handleBadgeClick( e, badgeItem )}>
                
                <span className="count">
                  <AppIcon iconName={badgeItem.iconName} />
                  {badgeItem.count}
                </span>
                <div className="label">
                  {badgeItem.label}
                </div>
                
              </Paper>
            </Grid>
          );
        })}
        
      </Grid>
    );
  }
}

export default BadgeMenu;
