
/**
 * 
 * 
 * @return void
 */
function DateFormatter () {
  
}


/**
 * Default format for app is "DD.MM.YYYY" and if time is also 
 * included then "DD.MM.YYYY HH:ii"
 * 
 * @param string dateString
 * @return object
 */
DateFormatter.prototype.defaultDateFormat = function( dateString, withTime ) {
  
  let addTimePart = false;
  if( typeof withTime !== 'undefined' ){
    addTimePart = withTime;
  }

  try {
    const date = new Date( dateString );

    const day = date.getDate();
    const formattedDay = day < 10 ? '0' + day : day;
    const month = date.getMonth() + 1;
    const formattedMonth = month < 10 ? '0' + month : month;
    const year = date.getFullYear();

    let constructed = formattedDay + '.' + formattedMonth + '.' + year;

    if( addTimePart ){
      const hour = date.getHours();
      const formattedHour = hour < 10 ? '0' + hour : hour;
      const minutes = date.getMinutes();
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      constructed = constructed + ' ' + formattedHour + ':' + formattedMinutes;
    }
    
    return constructed;
  } catch (error) {
    console.error( 'Problem in defaultDateFormat with date with input ' + dateString + '. ' + error );
  }

  return dateString.substr( 0, 10 );
}


export default DateFormatter;