"use strict";

/**
 *
 * Main point at moment is to postpone choosing storing mechanism into
 * future (and for prototyping) by providing this simple wrapper for app.
 *
 * This currenlty stores entities into memory
 *
 * @return void
 */

//import ResourceReference from "../Task/ResourceReference";
import { Meta as EntityMeta } from "./Meta.ts";
import { ResourceMeta } from './../DomainResource/ResourceMeta';
import { ResourceIdFactory } from './../DomainResource/ResourceIdFactory';
import { AlternateEmailSharp, SmsSharp } from "@material-ui/icons";


/**
 * -
 *
 * @return void
 */
function DomainResourceStore() {
  this.localIdToRemoteIdMappings = [];
  this.remoteResourceRequests = [];
  this.localEntityCounter = 1;
  this.localEntitySourceCounter = 1;
  this.allResources = [];
  this.syncer = null;
  this.eventListener = null;
  this.requestSyncTimeOut = null;
  
  this.idFactory = new ResourceIdFactory();
}

/**
 * Add or update resource (based on DomainResource) into store
 *
 * If localReference is set- it means it's response from server
 * and we can update local entity (and it's ID) by the server given entity via that
 * reference- and  effectively remove local ID from entity bt replacing it.
 *
 * After that local id is no more present- it will equal to server id
 *
 * @param DomainResource resource
 * @param string localReference
 * @param boolean updatedByServer
 * @return boolean
 */
DomainResourceStore.prototype.saveResource = function(resource, localReference, updatedByServer) {
  var id = resource.getId();

  var localReference = typeof localReference != "undefined" ? localReference : "";
  var isUpdatedByServer = typeof updatedByServer != "undefined" ? updatedByServer : false;
  var localEntityPresent = this.getEntityByEntityId(id);
  
  // we can't access entity via server given id before local id replaced
  // check that that updatable resource is actually the same resource
  let resourceType = resource.getResourceType();
  if ( localReference != '' && this.isLocalResourceId( localReference ) ) {
    localEntityPresent = this.getEntityByEntityId(localReference);
    if( updatedByServer ){
      if( localEntityPresent ){
        this.addLocalToServerIdMapping( localEntityPresent.getId(), resource.getId() );
      }
    }
  }

  var entityMetaNeedsSync = new EntityMeta(false, 0, 0);
  var entityMetaSynced = new EntityMeta(
    true,
    Math.floor(Date.now() / 1000),
    0
  );
  
  // add into store if not found
  if (!localEntityPresent) {
    let nextIndex = this.allResources.length;
    this.allResources[nextIndex] = {
      entityMeta: entityMetaNeedsSync,
      entity: resource
    };
    this.removeResourceRequests( resource );
    if ( isUpdatedByServer ) {
      this.allResources[nextIndex].entityMeta = entityMetaSynced;
    } else {
      this.requestSync();
    }
    return true;
  }

  // update if already in store
  for (var i in this.allResources) {
    if (
      this.allResources[i].entity.getId().isEqual( localEntityPresent.getId() ) 
    ) {

      if ( isUpdatedByServer ) {
        this.allResources[i].entityMeta = entityMetaSynced;
      } else {
        this.allResources[i].entityMeta = entityMetaNeedsSync;
        this.requestSync();
      }

      this.allResources[i].entity = resource;
      this.removeResourceRequests( resource );
      
      return true;
    }
  }

  return false;
};


/**
 * 
 *
 * @param ResourceId localId
 * @param ResourceId serverId
 * @return void
 */
DomainResourceStore.prototype.addLocalToServerIdMapping = function(
  localId, serverId
) {

  if( localId.isEqual( serverId ) ){
    return;
  }

  let alreadyMapped = false;
  for( var i1 in this.localIdToRemoteIdMappings ){
    if( this.localIdToRemoteIdMappings[ i1 ].localId.isEqual( localId ) ){
      alreadyMapped = true;
    }
  }

  if( !alreadyMapped ){
    this.localIdToRemoteIdMappings.push({
      'localId' : localId,
      'serverId' : serverId,
    });
  }
}


/**
 * 
 * @param ResourceId localId
 * @return ResourceId
 */
 DomainResourceStore.prototype.getMappedServerIdFromLocalId = function(
  localId
) {
  for( var i1 in this.localIdToRemoteIdMappings ){
    if( this.localIdToRemoteIdMappings[ i1 ].localId.isEqual( localId ) ){
      return this.localIdToRemoteIdMappings[ i1 ].serverId;
    }
  }

  return null;
}


/**
 * Get specified tasks by target patient identifierCode
 *
 * @param string identifierCode
 * @return array of Task objects
 */
DomainResourceStore.prototype.getTasksByTargetPatientIdentifierCode = function(
  identifierCode
) {
  var tasks = [];

  
  let allTasks = this.getAllResourcesBySearchParams( {
    "resourceType" : "Task"
  } );

  for (var i in allTasks) {
    if (
      allTasks[i].entity.getTargetPatientReference().getIdentityId() ==
      identifierCode
    ) {
      tasks[tasks.length] = allTasks[i].entity;
    }
  }

  // moveOnHoldTasksToCompleted(tasks);
  // const filteredTasks = tasks.filter(task => task.getStatus() !== "completed");

  return tasks;
};


/**
 * TODO: This is a quick fix - update task also (update patient references within task). 
 * Later server will initate it? Update task patient reference value. 
 * 
 * Change only if something is to be changed
 * Called after server confirms Patient change/add
 * 
 * @param Patient patient
 * @return void
DomainResourceStore.prototype.updateTasksTargetPatientReferences = function(patient) {
  var tasks = this.getTasksByTargetPatientIdentifierCode(
    patient.getIdentifierCodeAsString()
  );
  
  for (var i in tasks) {
    let task = tasks[i];
    let patientReference = task.getTargetPatientReference();
    
    if(
      patientReference.getDisplayName() != patient.getFullName()
    ){
      task.setTargetPatientReference(
        new ResourceReference(
          task.getTargetPatientReference().getReference(),
          "",
          patient.getFullName(),
          patient.getIdentifierCodeAsString()
        )
      );

      this.saveResource(task);
    }
  }
};
 */

/**
 * Get specified Patient from store by identifierCode (isikukood)
 *
 * @param string identifierCode
 * @return Patient object
 */
DomainResourceStore.prototype.getPatientByIdentifierCode = function(identifierCode) {
  for (var i in this.allResources) {
    if (
      this.allResources[i].entity.getResourceType() == 'Patient' && 
      this.allResources[i].entity.getIdentifierCodeAsString() == identifierCode
    ) {
      return this.allResources[i].entity;
    }
  }

  return null;
};


/**
 * Request resource from remote server by it's id / reference
 * request is just a obeject describing what to get like: 
 * 
 * {
 *   "resourceType" : "Patient",
 *   "url" : "Patient/{uuid}",
 *   "id" : "{uuid}"
 * }
 * 
 * Url or id and resourceType must be present
 *
 * @param object domainResourceRequest
 * @return boolean
 */
DomainResourceStore.prototype.requestResource = function( domainResourceRequest ) {

  if( !domainResourceRequest ){
    return false;
  }

  var type = "";
  var id = "";
  var resourceUrl = "";

  
  if( typeof domainResourceRequest.url != "undefined" ){
    var extracted = this.getResourceIdAndTypeFromUrl( domainResourceRequest.url );
    type = extracted.resourceType;
    id = extracted.id;
    resourceUrl = domainResourceRequest.url;
  } else if( typeof domainResourceRequest.id != "undefined" && typeof domainResourceRequest.resourceType != "undefined" ){
    type = domainResourceRequest.resourceType;
    id = domainResourceRequest.id;
    resourceUrl = domainResourceRequest.resourceType + '/' + domainResourceRequest.id;
  }
  
  if( !resourceUrl || !id || !type ){
    return false;
  }

  var obj = {
    "resourceType" : type,
    "id" : id,
    "url" : resourceUrl,
    "requestSent" : false
  };

  
  var alreadyRequested = false;
  for( var i in this.remoteResourceRequests ){
    if( 
      this.remoteResourceRequests[ i ].id == obj.id && 
      this.remoteResourceRequests[ i ].resourceType == obj.resourceType
    ){
      alreadyRequested = true;
    }
  }

  if( !alreadyRequested ){
    this.remoteResourceRequests.push( obj );
  }

  this.requestSync();

  return true;
};


/**
 * Add request from reference url's like
 *   - "Patient/abeab160-7bf6-4142-aae5-9545675ebea4"
 *   - "QuestionnaireResponse/8d85a564-c5b3-4240-a42e-bb9366c5c961" 
 *   
 * And spcial "nested" ones also like: 
 *   
 *   - "Patient/abeab160-7bf6-4142-aae5-9545675ebea4/QuestionnaireResponse/8d85a564-c5b3-4240-a42e-bb9366c5c961"
 * 
 * 
 *
 * @param string referenceUrl
 * @return boolean
 */
DomainResourceStore.prototype.requestResourceByReferenceUrl = function( referenceUrl ) {
  
  var obj = {
    "resourceType" : '',
    "id" : '',
    "url" : referenceUrl,
    "requestSent" : false
  };
  
  var alreadyRequested = false;
  for( var i in this.remoteResourceRequests ){
    if( 
      this.remoteResourceRequests[ i ].url == obj.url 
    ){
      alreadyRequested = true;
    }
  }

  if( !alreadyRequested ){
    console.log( "Add into requestResourceByReferenceUrl : " );
    console.log( referenceUrl );
    this.remoteResourceRequests.push( obj );
  }

  this.requestSync();

  return true;
};


/**
 * We need to clear remote resource request that are successfully loaded into 
 * store so we don't request them again over and over again. 
 *
 * @param DomainResource resource
 * @return void
 */
DomainResourceStore.prototype.removeResourceRequests = function( resource ) {
  for( var i in this.remoteResourceRequests ){
    if( 
      this.remoteResourceRequests[ i ].id == resource.getId().getResourceId() && 
      this.remoteResourceRequests[ i ].resourceType == resource.getResourceType()
    ){
      delete this.remoteResourceRequests[ i ];
    }
  }
};

/**
 * Extract id and resourceType from reference/url like: 
 * 
 * "Patient/ebc21-61d3a3d-46c8-b26e-c30665a4d91d"
 * 
 * resulting object
 * 
 * {
 *   "resourceType" : "Patient",
 *   "id" : "ebc21-61d3a3d-46c8-b26e-c30665a4d91d"
 * }
 *
 * @param string url
 * @return object
 */
DomainResourceStore.prototype.getResourceIdAndTypeFromUrl = function( url ) {
  var id = "";
  var type = "";

  if( !url || typeof url == 'undefined' ){
    return {
      "resourceType" : type,
      "id" : id
    };
  }

  var parts = url.split("/");
  if (parts.length > 1) {
    type = parts[0];
    id   = parts[parts.length - 1];
  }

  id   = id.trim();
  type = type.trim();
  
  var obj = {
    "resourceType" : type,
    "id" : id
  }

  return obj;
}

/**
 * 
 * @param string resourceType
 * @return string
 */
DomainResourceStore.prototype.getResourceMetaWithOnlyNewSourceUrl = function( resourceType ) {
  let url = this.createResourceMetaSourceUrl( resourceType );

  let meta = new ResourceMeta();
  meta.setSourceUrl( url );

  return meta;
};

/**
 * Create source url for domain resource (uniqe and random)
 * like "http://tean.abtram.ee/model/241243245-515-1454135-15"
 * 
 * @param string resourceType
 * @return string
 */
DomainResourceStore.prototype.createResourceMetaSourceUrl = function( resourceType ) {
  let time = Math.floor(Date.now() / 1000);
  let rnd = Math.floor(Math.random() * 1000) + 1;

  let ref =
    "https://tean.abtram.ee/model/" +
    resourceType +
    "-" +
    time +
    "-" +
    rnd +
    "-" +
    this.localEntitySourceCounter;

  this.localEntitySourceCounter++;

  return ref;
};

/**
 * Create local id for resource. Important part is here
 * "dmLocalClientId/" prefix which trough we can dedect
 * is object already updated to API / server or it's only
 * stored on local store
 *
 * @see method self:isLocalResourceId()
 *
 * @param string resourceType
 * @return string
 */
DomainResourceStore.prototype.createLocalResourceId = function(resourceType) {
  let time = Math.floor(Date.now() / 1000);
  let rnd = Math.floor(Math.random() * 1000) + 1;
  let ref =
    "appLocalClientId/" +
    resourceType +
    "/" +
    time +
    "-" +
    rnd +
    "-" +
    this.localEntityCounter;
  this.localEntityCounter++;

  let resourceId = this.idFactory.create( {
    'resourceType' : resourceType, 
    'resourceId' : ref,
    'fullUrl' : ''
  } );

  return resourceId;
};

/**
 * -
 *
 * @param string id
 * @return boolean
 */
DomainResourceStore.prototype.isLocalResourceId = function(id) {
  if (id == "" || id.trim() == "") {
    return true;
  }

  if (id.indexOf("appLocalClientId") > -1) {
    return true;
  }
  return false;
};

/**
 * Get entity from local store by it's resourceReference resource
 *
 * @param resourceReference resourceReference
 * @return object
 */
DomainResourceStore.prototype.getEntityByResourceReference = function(
  resourceReference
) {
  if (resourceReference == null) {
    return null;
  }

  var id = "";
  var resourceType = "";

  // extract id from resource resource like "Patient/1573934623-940-1"
  var ref = "";
  if( 
    typeof resourceReference != "undefined" && 
    typeof resourceReference.resReference != "undefined"
  ){
    ref = resourceReference.resReference;
  }

  var parts = ref.split("/");
  if (parts.length > 0) {
    id = parts[parts.length - 1];
  }
  if (parts.length > 1) {
    resourceType = parts[parts.length - 2];
  }

  if ( id && resourceType ) {
    //return this.getEntityByEntityId(id);
    let list = this.getAllResourcesBySearchParams( {
      "resourceId" : id,
      "resourceType" : resourceType
    } );

    if( typeof list[ 0 ] != 'undefined' ){
      return list[ 0 ];
    }
  }

  return null;
};


/**
 * Get entity / domain resource by it's 
 * resourceUrl like "QuestionnaireResponse/ebc23a31-61dd-46c8-b26e-c30665a4d91d" by 
 * id : "ebc23a31-61dd-46c8-b26e-c30665a4d91d"
 * 
 * @param string url
 * @return DomainResource object
 */
DomainResourceStore.prototype.getResourceByResourceUrl = function( url ) {
  var id = "";
  
  var parts = url.split("/");
  if (parts.length > 0) {
    id = parts[parts.length - 1];
  }

  if (id) {
    return this.getEntityByEntityId(id);
  }

  return null;
}


DomainResourceStore.prototype.getResourceById = function(id) {
  return this.getEntityByEntityId( id );
}

/**
 * Get entity from local store by it's uniqe resource id
 *
 * @return object
 */
DomainResourceStore.prototype.getEntityByEntityId = function(id) {
  if( ( typeof id ) == 'object' ){
    for (var i in this.allResources) {
      if (this.allResources[i].entity.getId().isEqual( id ) ) {
        return this.allResources[i].entity;
      }
    }
    return null;
  }
  
  for (var i2 in this.allResources) {
    if (this.allResources[i2].entity.getId().getResourceId() == id) {
      return this.allResources[i2].entity;
    }
  }

  return null;
};

/**
 * Is given resource currently syncing to server?
 * 
 * @param DomainResource resource
 */
DomainResourceStore.prototype.isRecourceSyncing = function( resource ) {
  
  for (var m in this.allResources) {
    if( this.allResources[m].entity.getId().isEqual( resource.getId() ) ){
      let meta = this.allResources[m].entityMeta;
      if ( meta.needsUpdateToServer() || meta.isUpdatingToServer() ) {  
        return true;
      } 
    }
  }

  return false;
}


/**
 * Get all resources by defined params from local store
 * 
 * @param string params
 * @return array
 */
DomainResourceStore.prototype.getAllResourcesBySearchParams = function( params ) {
  var list = [];
  
  for (var i in this.allResources) {

    var allCriteriasMatched = true;
    let resourceJson = this.allResources[i].entity.toObject();
    
    
    if( typeof params.resourceType != 'undefined' ){
      if( this.allResources[i].entity.getResourceType() != params.resourceType ){
        allCriteriasMatched = false;
      }
    }
    
    if( typeof params.resourceId != 'undefined' ){
      if( this.allResources[i].entity.getId().getResourceId() != params.resourceId ){
        allCriteriasMatched = false;
      }
    }
    
    if( typeof params.subjectReferenceUrl != 'undefined' ){
      if( 
        typeof resourceJson.subject == 'undefined' || typeof resourceJson.subject.reference == 'undefined' || 
        resourceJson.subject.reference != params.subjectReferenceUrl
      ){
        allCriteriasMatched = false;
      }
    }
    
    if( typeof params.encounterReferenceUrl != 'undefined' ){
      if( 
        typeof resourceJson.encounter == 'undefined' || typeof resourceJson.encounter.reference == 'undefined' || 
        resourceJson.encounter.reference != params.encounterReferenceUrl
      ){
        allCriteriasMatched = false;
      }
    }
    
    // Look for matching code and coding system, look matching "code + system", "code" or "system" seperately
    if( typeof params.code != 'undefined' ){
      if( 
        typeof resourceJson.code == 'undefined' || typeof resourceJson.code.coding == 'undefined' || 
        resourceJson.code.coding.length < 1
      ){
        allCriteriasMatched = false;
      } else {
        var matchFound = false;

        for( var c in resourceJson.code.coding ){
          let testCoding = resourceJson.code.coding[ c ];
          let testValue  = ( typeof testCoding.code != 'undefined' ) ? testCoding.code : '';
          let testSystem = ( typeof testCoding.system != 'undefined' ) ? testCoding.system : '';
          
          if( typeof params.code.codingValue != 'undefined' && typeof params.code.codingSystem != 'undefined' ){
            if( params.code.codingValue == testValue && params.code.codingSystem == testSystem ){
              matchFound = true;
            }
          } else if ( typeof params.code.codingValue != 'undefined' && params.code.codingValue == testValue ){
            matchFound = true;
          } else if ( typeof params.code.codingSystem != 'undefined' && params.code.codingSystem == testSystem ){
            matchFound = true;
          }
        }

        if( !matchFound ){
          allCriteriasMatched = false;
        }
      }
    }


    if ( allCriteriasMatched ) {
      list[ list.length ] = this.allResources[i].entity;
    }
  }
  
  return list;
}


/**
 * Get all resources by their resourceType from local store
 *
 * @param string resourceType
 * @return array
 */
DomainResourceStore.prototype.getAllResourcesByType = function( resourceType ) {
  var list = [];

  for (var i in this.allResources) {
    if ( resourceType == 'all' || this.allResources[i].entity.getResourceType() == resourceType ) {
      list[ list.length ] = this.allResources[i].entity;
    }
  }

  return list;
};
/**
 * Get all resources by their resourceType from local store- except MedicationKnowledge items
 * There are too many of them
 * 
 * @note Method should only be used for debugging
 *
 * @param string resourceType
 * @return array
 */
DomainResourceStore.prototype.getAllResourcesByTypeExceptMedicationKnowledge = function( resourceType ) {
  var list = [];

  for (var i in this.allResources) {
    if ( resourceType == 'all' || this.allResources[i].entity.getResourceType() == resourceType ) {
      if( this.allResources[i].entity.getResourceType() != 'MedicationKnowledge' ){
        list[ list.length ] = this.allResources[i].entity;
      }
    }
  }

  return list;
};


/**
 * -
 *
 * @return void
 */
DomainResourceStore.prototype.clearAll = function() {
  this.allResources = [];
};


/**
 * -
 *
 * @return void
 */
DomainResourceStore.prototype.clearAllResourcesBySearchParams = function( params ) {
  let i = 0;

  while (i < this.allResources.length) {
    var allCriteriasMatched = true;
    
    if( typeof params.resourceType != 'undefined' ){
      if( this.allResources[i].entity.getResourceType() != params.resourceType ){
        allCriteriasMatched = false;
      }
    }

    if ( allCriteriasMatched ) {
      this.allResources.splice( i, 1 );
    } else {
      ++i;
    }
  }
};


/**
 * -
 *
 * @param EntityStoreSyncronizer syncer
 * @return void
 */
DomainResourceStore.prototype.setSyncronizer = function(syncer) {
  this.syncer = syncer;
};


/**
 * -
 *
 * @param eventlistener
 * @return void
 */
DomainResourceStore.prototype.startListening = function( eventListener ) {
  this.eventListener = eventListener;
};


/**
 * If many entities are changed at once - we only need to call sync once
 * after they are done. By adding little delay we can make this happen.
 *
 * @return void
 */
DomainResourceStore.prototype.requestSync = function() {
  if (this.syncer == null) {
    return;
  }

  if (this.requestSyncTimeOut) {
    clearTimeout(this.requestSyncTimeOut);
  }

  var that = this;

  this.requestSyncTimeOut = setTimeout(function() {
    that.syncronize();
  }, 120);
};

/**
 * Request syncer to update local changes to remote API in seperate syncing script
 *
 * @return void
 */
DomainResourceStore.prototype.syncronize = function() {
  
  var entityMetaStartedSyncing = new EntityMeta(
    false,
    0,
    Math.floor(Date.now() / 1000)
  );

  for (var i in this.allResources) {
    let meta = this.allResources[i].entityMeta;
    if (meta.needsUpdateToServer() && !meta.isUpdatingToServer() ) {
      var domainResource = this.allResources[i].entity;
      let syncStarted = this.syncer.requstResourceUpdate(
        domainResource,
        this.isLocalResourceId(domainResource.getId().getResourceId())
      );
      if( syncStarted ) {
        this.allResources[i].entityMeta = entityMetaStartedSyncing;
      }
    }
  }

  // get requested resources
  for (var i in this.remoteResourceRequests) {
    if( !this.remoteResourceRequests[ i ].requestSent ){
      this.syncer.requestRemoteResource( this.remoteResourceRequests[ i ] );
      this.remoteResourceRequests[ i ].requestSent = true;
    }
  }

};

export { DomainResourceStore };
