
'use strict';

/**
 * Value object for holding and passing around authentication token
 * 
 * @param 
 * @param string tokenString
 * @return this
 */
function AuthToken ( tokenString ) {

  var tokenString = typeof tokenString != 'undefined' ? tokenString : '';

  this.token = tokenString.trim();
  return this;
}


/**
 * 
 * 
 * @return string
 */
AuthToken.prototype.getTokenAsString = function( ) {
  return this.token;
};


/**
 * Does current token have value set?
 * 
 * @return boolean
 */
AuthToken.prototype.hasValue = function( ) {
  let test = this.token.trim();
  return ( test != '' ? true : false );
};


export default AuthToken;