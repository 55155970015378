
 import { ResourceMeta } from './../DomainResource/ResourceMeta';
 import { ResourceIdFactory } from './../DomainResource/ResourceIdFactory';
 import { DomainResource } from './../DomainResource/DomainResource';
 import SubscriptionResource from './../Subscription/Subscription.js';
 import TaskResource from './../Task/Task.js';
 import PatientResource from './../Patient/Patient.js';
 import PractitionerRole from './../PractitionerRole/PractitionerRole.js';
 import BundleResource from './../Bundle/Bundle.js';
 // import { DomainResourceStore } from './../DomainResourceStore/DomainResourceStore.js';


/**
 * 
 * @parm object config
 * @return void
 */
function BundleParser ( config ) {
  this.idFactory = new ResourceIdFactory();
  this.currentId = '';
  
  if( typeof config == 'object' ){
    if( typeof config.tryExtractIdFromFullUrl != 'undefined' ){
      this.configTryExtractIdFromFullUrl = config.tryExtractIdFromFullUrl;
    }
  }

  this.configExtractIdFromFullUrl = false;
}


BundleParser.prototype.handleJson = function( json, entityStore ) {
}


BundleParser.prototype.tryExtractIdFromUrl = function() {
  this.configExtractIdFromFullUrl = false;
}


/**
 * Handle raw JSON. Extract resources and add them into (local) store
 * 
 * @param object json
 * @param DomainResourceStore entityStore
 * @return void
 */
BundleParser.prototype.handleJson = function( json, entityStore ) {
  
  if(typeof json.id != 'undefined' ){
    this.currentId = json.id;
  }

  if( 
    typeof json.resourceType != 'undefined' && 
    json.resourceType == 'Bundle'
  ){
    for (  var i in json.entry ){

      let requestUrl = '';
      if(
        typeof json.entry[ i ].request != 'undefined' && 
        typeof json.entry[ i ].request.url != 'undefined'
      ){
        // eventIn.rawJson.entry[ i ].request.url  // PractitionerRole
        requestUrl = json.entry[ i ].request.url;
      }

      // console.log( requestUrl );
      
      if( typeof json.entry[ i ].resource != 'undefined' ){
        var resource = json.entry[ i ].resource;
        if( typeof resource.resourceType != 'undefined' ){
          this.handleBundleEntry( json.entry[ i ], entityStore );
        }
      }
    }
  }
}


/**
 * Handle one bundle entry (and resource within if set)
 *  
 * @param object bundleEntry
 * @param DomainResourceStore entityStore
 */ 
 BundleParser.prototype.handleBundleEntry = function( bundleEntry, entityStore ) {
    
  var entryMeta = this.extractBundleEntryMetaData( bundleEntry );
  if( typeof bundleEntry.resource == 'undefined' || typeof bundleEntry.resource.resourceType == 'undefined' ){
    // No rsource included in entry - return
    return;
  }

  let entryFullUrl = '';
  if( typeof bundleEntry.fullUrl != 'undefined' ){
    entryFullUrl = bundleEntry.fullUrl;
  }

  this.handleDomainResourceEntry( bundleEntry.resource, entryMeta, entryFullUrl, entityStore );
};


/**
 * - 
 * 
 * @param object resourceJson
 * @param object entryMeta
 * @param DomainResourceStore entityStore
 * @return
 */
 BundleParser.prototype.handleDomainResourceEntry = function( resourceJson, entryMeta, entryFullUrl, entityStore ) {
  
  if( typeof resourceJson.resourceType == 'undefined' || !resourceJson.resourceType ) {
    return;
  }

  // Server sent resources must have ID set
  // or try to extract ID from fullUrl (supports urn:uuid at moment)
  let resourceIdString = '';
  if( typeof resourceJson.id == 'undefined' || !resourceJson.id ) {
    if( this.configTryExtractIdFromFullUrl ){
      if( entryFullUrl != ''  && entryFullUrl.indexOf( 'urn:uuid' ) > -1 ){
        resourceIdString = entryFullUrl.split('urn:uuid:').join('');
      } else {
        return;
      }
    } else {
      return;
    }
  } else {
    resourceIdString = resourceJson.id;
  }

  

  if( this.currentId == "050eed4a-6295-320b-8167-91a7ed9d3011" ){
    console.log( "handleBundleEntry json.entry :::" );
    console.log( resourceJson );
  }

  var domainResource = new DomainResource( resourceJson.resourceType );
  
  if( resourceJson.resourceType == 'Subscription' ){
    domainResource = new SubscriptionResource( resourceJson.id );
  }

  if( resourceJson.resourceType == 'Task' ){
    domainResource = new TaskResource( resourceJson.id );
  }

  if( resourceJson.resourceType == 'Bundle' ){
    domainResource = new BundleResource( resourceJson.id );
  }

  if( resourceJson.resourceType == 'Patient' ){
    domainResource = new PatientResource( resourceJson.id, resourceJson.identifier[ 0 ].value );
  }

  if( resourceJson.resourceType == 'PractitionerRole' ){
    domainResource = new PractitionerRole( resourceJson.id );
  }
  
  domainResource.setRawResourceObject( resourceJson );

  // Task POST method is returned without fullUrl which is used when sending tasks first time 
  if( entryFullUrl == '' ){
    entryFullUrl = resourceJson.resourceType + '/' + resourceJson.id;

    // quick fix for practicioner also- it also has no fullUri set when POST request is successfull
    //if( entryMeta.crudAction == 'update_or_insert' && resourceJson.resourceType == 'PractitionerRole' ){
    //  entryFullUrl = 'https://tean.abtram.eu/fhir/PractitionerRole/' + resourceJson.id;
    // }
  }

  let resourceId = this.idFactory.create( {
    'resourceType' : resourceJson.resourceType, 
    'resourceId' : resourceIdString,
    'fullUrl' : entryFullUrl
  } );

  // domainResource.setId( resourceJson.id );
  domainResource.setId( resourceId );
  
  var localReference = '';
  if( entryMeta.crudAction == 'update_or_insert' && entryMeta.crudActionResult && domainResource.getId().getResourceId() != entryMeta.localReference ){
    localReference = entryMeta.localReference;
  }
  
  entityStore.saveResource( domainResource, localReference, true );
}


/**
 * Extract some useful info to handle what to do with given resource
 */
 BundleParser.prototype.extractBundleEntryMetaData = function( bundleEntry ) {
  var entryMeta = {
    'crudAction' : '',
    'crudActionResult' : false,
    'localReference' : '',
    'resourceId' : '',
    'responseCode' : '',
    'rawResponseStatusValue' : ''
  }
  
  var requestMethod = '';
  if( typeof bundleEntry.request != 'undefined' ){
    requestMethod = bundleEntry.request.method;
  }

  var saveMethods = false;
  if( ( requestMethod == 'POST' || requestMethod == 'PUT') ){
    saveMethods = true;
  }

  if( typeof bundleEntry.response != 'undefined' ){
    entryMeta.rawResponseStatusValue = bundleEntry.response.status;
    var status = bundleEntry.response.status.substring( 0, 3 );
    var responseCode = parseInt( status );

    if( saveMethods ){
      entryMeta.crudAction = 'update_or_insert';
      entryMeta.crudActionResult = ( status == '201' );
    } else if ( status == '200' ){
      entryMeta.crudAction = 'read';
      entryMeta.crudActoinResult = true;
    }

    if( responseCode > 0 ){
      entryMeta.responseCode = responseCode;
    }
  }
  
  if( typeof bundleEntry.request != 'undefined' ){
    requestMethod = bundleEntry.request.method;
  }


  if( typeof bundleEntry.resource != 'undefined' && typeof bundleEntry.resource.id != 'undefined' ){
    entryMeta.resourceId = bundleEntry.resource.id;
  }

  // find local reference link- by default it's server reference ID 
  entryMeta.localReference = ''; // entryMeta.resourceId;

  if( typeof bundleEntry.link != 'undefined' ){
    for( var i in bundleEntry.link ){
      var linkTest = bundleEntry.link[ i ];
        // 
      if( 
        typeof linkTest.url != 'undefined' && 
        typeof linkTest.relation != 'undefined' && 
        linkTest.relation == 'app'
      ){
        entryMeta.localReference = linkTest.url;  
      }

    }
  }

  return entryMeta;
}


export default BundleParser;
