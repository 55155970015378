
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppIcon from './../AppIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import { DomainResource } from './../../Tean/DomainResource/DomainResource';
import { Alert } from '@material-ui/lab';

import {Isikukood} from 'isikukood/dest/isikukood';


var roleOptions = [
  { 'id' : "HEALTH_WORKER", "label" : "Piletite lahendaja" }, // Fake role, not saved as one if set
  { 'id' : "HEALTH_ANALYST", "label" : "Terviseanalüütik" },
  { 'id' : "ADMIN", "label" : "Adminstraator" }
];


class EmployeeForm extends React.Component {

  constructor( props ) {
    super( props );

    this.paperElevation = 3;
    
    //
    let isEdit = false;
    if( props.employeeRecord.id !== "" ){
      isEdit = true;
    }

    let usePhoneNr = props.employeeRecord.contactPhone;
    if( usePhoneNr.trim() === "" ){
      usePhoneNr = "+372";
    }
    
    if( usePhoneNr.trim().substring( 0, 4 ) !== "+372" ){
      usePhoneNr = "+372" + usePhoneNr;
    }

    this.state = {
      "hasPendingUpdates" : false,
      "isEditEmployee" : isEdit,
      "formSubmissionError" : "",
      "formValues" : {
        "hasMainRole" : ( props.employeeRecord.hasMainRole ? props.employeeRecord.mainRoleCode : "HEALTH_WORKER" ),
        "personalCode" : props.employeeRecord.personalCode,
        "displayName" : props.employeeRecord.displayName,
        "contactPhone" : usePhoneNr,
        "healthcareWorkerCode" : props.employeeRecord.healthcareWorkerCode,
        "healthCareRegisters" : props.employeeRecord.healthCareRegisters
      },
      "formErrors" : {
        "hasMainRole" : "",
        "personalCode" : "",
        "displayName" : "",
        "contactPhone": "",
        "healthcareWorkerCode" : "",
        "healthCareRegisters" : ""
      },
      "quittingJob" : false,
      "formClosingOrSaving" : false
    }
    // healthCareRegisters

    this.handleQuitJobClick = this.handleQuitJobClick.bind( this );
    this.handleCloseFormClick = this.handleCloseFormClick.bind( this );
    this.handleSaveClick = this.handleSaveClick.bind( this );
    this.handleRoleChange = this.handleRoleChange.bind( this );
    this.handleFieldChange = this.handleFieldChange.bind( this );
  }
  

  handleRoleChange( event ){
    let currentFormValues = this.state.formValues;
    currentFormValues.hasMainRole = event.target.value;

    this.setState({
      "formValues" : currentFormValues,
      "hasPendingUpdates" : true,
      "formSubmissionError" : ""
    });
  }


  handleFieldChange ( fieldKey, newValue ){
    let currentForm = this.state.formValues;

    if( fieldKey !== "displayName" ){
      newValue = newValue.trim();
    }

    if( fieldKey === "personalCode" ){
      currentForm.personalCode = newValue;
    }
    if( fieldKey === "displayName" ){
      currentForm.displayName = newValue;
    }
    if( fieldKey === "healthcareWorkerCode" ){
      currentForm.healthcareWorkerCode = newValue;
    }
    if( fieldKey === "contactPhone" ){
      currentForm.contactPhone = newValue;
    }
    
    this.setState({
      "formValues" : currentForm,
      "hasPendingUpdates" : true,
      "formSubmissionError" : ""
    });
  }

  
  handleToggle = ( service ) => () => {
    let currentForm = this.state.formValues;
    let newSelected = [];

    let foundInCurrent = false;

    for( var i in currentForm.healthCareRegisters ){
      if( service.id === currentForm.healthCareRegisters[i].id ){
        foundInCurrent = true;
      } else {
        newSelected.push( currentForm.healthCareRegisters[i] );
      }
    }

    if( !foundInCurrent ){
      newSelected.push( service );
    }
    
    currentForm.healthCareRegisters = newSelected;

    this.setState({
      "formValues" : currentForm,
      "hasPendingUpdates" : true,
      "formSubmissionError" : ""
    });
  };


  /**
   * If there are pending updates- ask before closing form. 
   * This is handleded in parent conponent
   * 
   * @param Event event 
   * @return void
   */
  handleCloseFormClick ( event ){
    
    this.setState({
      "formClosingOrSaving" : true
    });
    
    if( typeof this.props.onFormClose !== "undefined" ){
      var that = this;
      setTimeout(function(){
        that.props.onFormClose( event, that.state.hasPendingUpdates );
      }, 300);
    }
  }
  
  
  /**
   * Present confirm dialog before actual quitting. This dialog is managed in parent. 
   * If confirmed - parent component will message this one to proceed via .jobQuitConfirmed()
   * 
   * @param Event event 
   * @return void
   */
  handleQuitJobClick ( event ){
    this.setState({
      "quittingJob" : true
    });
    
    if( typeof this.props.onQuitJobConfirm !== "undefined" ){
      this.props.onQuitJobConfirm();
      return;
    } else {
      // or if no confirm in props defined, fire instantly
      this.requestQuitJob();
    }
  }


  jobQuitConfirmed(){
    this.requestQuitJob();
  }


  jobQuitCanceled(){
    this.setState({
      "quittingJob" : false
    });
  }


  CloseOrSaveCanceled(){
    this.setState({
      "formClosingOrSaving" : false
    });
  }
  

  /**
   * 
   * @return void
   */
  requestQuitJob(){
    // kustuta / Lõpeta haldamine
    // lisame hiljem kinnituseks dialoogi
    
    let useId = null;
    let domainResource = null;

    domainResource = window.appEntityStore.getResourceById( this.props.employeeRecord.id );
    if( domainResource === null ){
      return false;
    }
    useId = domainResource.getId();
    
    let prJson = domainResource.toObject();


    // MANUAL vs MEDRE managed? Remove MANUAL - replace with MEDRE if originally from there
    let foundIndex2 = -1;
    if( typeof prJson.extension !== "undefined" ){
      for( var i5 in prJson.extension ){
        if( 
          typeof prJson.extension[ i5 ].url !== "undefined" && 
          prJson.extension[ i5 ].url === "https://tean.abtram.eu/fhir/extension/practitionerrole/management-mode" && 
          typeof prJson.extension[ i5 ].valueCoding !== "undefined" && 
          typeof prJson.extension[ i5 ].valueCoding.system !== "undefined" && 
          prJson.extension[ i5 ].valueCoding.system === "https://tean.abtram.eu/fhir/codesystem/practitionerrole/management-mode" && 
          typeof prJson.extension[ i5 ].valueCoding.code !== "undefined"
        ){
          foundIndex2 = i5;
        }
      } 
    } else {
      prJson.extension = [];
    }

    let ext = {
      "url" : "https://tean.abtram.eu/fhir/extension/practitionerrole/management-mode",
      "valueCoding" : {
        "system" : "https://tean.abtram.eu/fhir/codesystem/practitionerrole/management-mode",
        "code" : "MEDRE"
      }
    }

    if( ext !== null ){
      if( foundIndex2 > -1 ){
        prJson.extension[ foundIndex2 ] = ext; // update
      } else {
        prJson.extension.push ( ext );
      }  
    }


    if( !this.props.employeeRecord.isExternallyManaged ){
      // Delete by setting active to false if not managed by MEDRE / externally
      prJson.active = false;
    }
    
    domainResource.setRawResourceObject( prJson );
    domainResource.setId( useId );


    window.appEntityStore.saveResource( domainResource );

    // should wait sync!
    var that = this;
    setTimeout(function(){
      if( typeof that.props.onFormClose !== "undefined" ){
        that.props.onFormClose( null, false );
        
        that.setState({
          "quittingJob" : false
        });
      }
    }, 300);
    
  }


  /**
   * 
   * @param Event event 
   * @return void
   */
  handleSaveClick ( event ){
    let validationResult = this.validateSaveForm();
    

    if( validationResult.hasErrors ){
      this.setState({
        "formSubmissionError" : validationResult.mainSubmissionError,
        "formClosingOrSaving" : false,
        "formErrors" : validationResult
      });

      return false;
    }

    this.setState({
      "formClosingOrSaving" : true
    });

    let result = false;

    if( this.state.isEditEmployee ){
      result = this.updatePractitionerRole();
    } else {
      result = this.createNewPractitionerRole();
    }

    if( !result ){
      this.setState({
        "formSubmissionError" : "Salvestamine ebaõnnestus",
        "formErrors" : validationResult
      });
    }

    this.setState({
      "formClosingOrSaving" : false
    });

    if( typeof this.props.onFormClose !== "undefined" ){
      this.props.onFormClose( null, false );
    }
  }


  /**
   * Employee is here actually a PractitionerRole
   * 
   * @retun boolean
   */
  updatePractitionerRole(){
    let useId = null;
    let domainResource = null;

    domainResource = window.appEntityStore.getResourceById( this.props.employeeRecord.id );
    if( domainResource === null ){
      return false;
    }
    useId = domainResource.getId();
    
    let resourceJson = domainResource.toObject();

    resourceJson = this.assemblePractitionerRoleJson( resourceJson );

    domainResource.setRawResourceObject( resourceJson );
    domainResource.setId( useId );

    window.appEntityStore.saveResource( domainResource );
  }


  /**
   * Employee is here actually a PractitionerRole
   * 
   * @retun boolean
   */
  createNewPractitionerRole(){
    let useId = window.appEntityStore.createLocalResourceId( 'PractitionerRole' );
    let domainResource = new DomainResource( "PractitionerRole" );

    domainResource.setId( useId );

    let resourceJson = domainResource.toObject();
    resourceJson = this.assemblePractitionerRoleJson( resourceJson);

    // Add Organization   reference
    resourceJson.organization = {
      "reference": "https://tean.abtram.eu/fhir/Organization/" + this.props.currentOrganizationId,
      "display": this.props.currentOrganizationDisplay
    };

    /** 
    resourceJson.organization = {
      "identifier": {
        "system": "https://tean.abtram.eu/fhir/namingsystem/organisation-identifier-estonia",
        "value": "77777"
      },
      "display": "Näidis PAK"
    };
    */
   
    domainResource.setRawResourceObject( resourceJson );
    domainResource.setId( useId );

    window.appEntityStore.saveResource( domainResource );

    /**
     {
        "resourceType": "PractitionerRole",
        "id": "983acbe6-c43f-4f2d-a267-557919040cef",
        "meta": {
          "lastUpdated": "2021-10-21T14:30:33.217+00:00"
        },
        "active": false,
      }
     * 
     */
    return true;
  }


  /**
   * Create/update raw PractitionerRole json to be saved based on form data
   * 
   * @param object prJson
   * @retun boolean
   */
  assemblePractitionerRoleJson( prJson ){
    console.log( "assemblePractitionerRoleJson: " );
    console.log( prJson );

    // Overwrite all healthcareService registers
    prJson.healthcareService = null;
    prJson.healthcareService = [];
    prJson.healthcareService.length = 0;
    for( var i in this.state.formValues.healthCareRegisters ){
      prJson.healthcareService.push({
        "reference" : "https://tean.abtram.eu/fhir/HealthcareService/" + this.state.formValues.healthCareRegisters[i].id,
        "display"   : this.state.formValues.healthCareRegisters[i].display
      });
    }

    //Overwrite healthcare worker code and name
    prJson.practitioner = null;
    prJson.practitioner = {
      "display" : this.state.formValues.displayName,
      "identifier" : {
        "system" : "https://tean.abtram.eu/fhir/namingsystem/healthcare-professional-estonia",
        "value" : this.state.formValues.healthcareWorkerCode
      }
    }

    // Overwrite personal code value. If exist update, otherwise- add
    let foundIndex = -1;
    if( typeof prJson.identifier !== "undefined" ){
      for( var i3 in prJson.identifier ){
        if(
          typeof prJson.identifier[ i3 ].system !== "undefined" && 
          prJson.identifier[ i3 ].system === "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia"
        ){
          foundIndex = i3;
        }
      }
    } else {
      prJson.identifier = [];
    }

    let rec = {
      "system" : "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia",
      "value" : this.state.formValues.personalCode
    }

    if( foundIndex > -1 ){
      prJson.identifier[ foundIndex ] = rec; // update
    } else {
      prJson.identifier.push ( rec );
    }
    
    // Add/update this.state.formValues.contactPhone
    if( typeof prJson.telecom === "undefined" ){
      prJson.telecom = [];
    }

    let foundPhoneIndex = -1;
    for( var j11 in prJson.telecom ){
      if( typeof prJson.telecom[ j11 ].system !== "undefined" && prJson.telecom[ j11 ].system === "phone" ){
        foundPhoneIndex = j11;
      }
    }

    let onlyPrefix = ( this.state.formValues.contactPhone === "+372" ? true : false );
    let phoneWasSet = false;

    if( this.state.formValues.contactPhone.trim() === "" || onlyPrefix ){
      if( foundPhoneIndex > -1 ){
        // delete prJson.telecom[ foundPhoneIndex ];
        prJson.telecom.splice( foundPhoneIndex, 1);
      }
    } else {
      phoneWasSet = true;
      if( foundPhoneIndex > -1 ){
        prJson.telecom[ foundPhoneIndex ].value = this.state.formValues.contactPhone;
        prJson.telecom[ foundPhoneIndex ].id = "1";
      } else {
        prJson.telecom.push({
          "system": "phone",
          "value": this.state.formValues.contactPhone,
          "use": "work",
          "id" : 1
        });
      }
    }

    // If telecom was set (for login) - set/check that extension also exists ( https://tean.abtram.eu/fhir/extension/paractioner/mobile-id-contact-id )
    let foundMIDIndex = -1;

    if( typeof prJson.extension !== "undefined" ){
      for(var k2 in prJson.extension){
        if( 
          typeof prJson.extension[ k2 ].url !== "undefined" && 
          typeof prJson.extension[ k2 ].valueId !== "undefined" && 
          prJson.extension[ k2 ].url === "https://tean.abtram.eu/fhir/extension/paractioner/mobile-id-contact-id" 
        ){
          foundMIDIndex = k2;
        }
      }
    }
    
    if( phoneWasSet ){
      if( typeof prJson.extension === "undefined" ){
        prJson.extension = [];
      }
      if( foundMIDIndex < 0 ){
        prJson.extension.push({
          "url": "https://tean.abtram.eu/fhir/extension/paractioner/mobile-id-contact-id",
          "valueId": "1"
        });
      }
    } else {
      if( foundMIDIndex > -1 ){
        prJson.extension.splice( foundMIDIndex, 1);
      }
    }
    
    // Updte this.state.formValues.hasMainRole
    let foundIndex3 = -1;
    let foundIndex3level2 = -1;
    let foundLevel1 = -1;
    if( typeof prJson.code !== "undefined" ){
      for( var i8 in prJson.code ){
        if( typeof prJson.code[ i8 ].coding !== "undefined" ){
          for( var i9 in prJson.code[ i8 ].coding ){
            foundLevel1 = i8;
            let test = prJson.code[ i8 ].coding[ i9 ];
            if( 
              typeof test.system !== "undefined" && 
              test.system === "https://tean.abtram.eu/fhir/codesystem/practitioner-role/code" 
            ){
              foundIndex3 = i8;
              foundIndex3level2 = i9;
            }
          }
        }
      } 
    } else {
      prJson.code = [];
    }
    
    if( this.state.formValues.hasMainRole === "HEALTH_WORKER" ){
      // not saving it, but remove previous if it was set
      if( foundIndex3 > -1 && foundIndex3level2 > -1 ){
        //delete prJson.code[ foundIndex3 ].coding[ foundIndex3level2 ];
        prJson.code[ foundIndex3 ].coding.splice( foundIndex3level2, 1);
      }
    }

    let ext2 = null;
    if( 
      this.state.formValues.hasMainRole === "HEALTH_ANALYST" || 
      this.state.formValues.hasMainRole === "FHC_ADMIN" || 
      this.state.formValues.hasMainRole === "ADMIN"
    ){
      ext2 = {
        "system": "https://tean.abtram.eu/fhir/codesystem/practitioner-role/code",
        "code": this.state.formValues.hasMainRole
      }
    }

    if( ext2 !== null ){
      if( foundIndex3level2 < 0 ){
        if( foundLevel1 < 0 ){
          prJson.code.push( {
            "coding": [
              ext2
            ]
          } );
        } else {
          prJson.code[ foundLevel1 ].coding.push( ext2 );
        }
      } else {
        prJson.code[ foundIndex3 ].coding[ foundIndex3level2 ] = ext2; // update
      }
    }


    // MANUAL vs MEDRE managed? On save we always set MANUAL. Update if somethign else here
    let foundIndex2 = -1;
    if( typeof prJson.extension !== "undefined" ){
      for( var i5 in prJson.extension ){
        if( 
          typeof prJson.extension[ i5 ].url !== "undefined" && 
          prJson.extension[ i5 ].url === "https://tean.abtram.eu/fhir/extension/practitionerrole/management-mode" && 
          typeof prJson.extension[ i5 ].valueCoding !== "undefined" && 
          typeof prJson.extension[ i5 ].valueCoding.system !== "undefined" && 
          prJson.extension[ i5 ].valueCoding.system === "https://tean.abtram.eu/fhir/codesystem/practitionerrole/management-mode" && 
          typeof prJson.extension[ i5 ].valueCoding.code !== "undefined"
        ){
          foundIndex2 = i5;
        }
      } 
    } else {
      prJson.extension = [];
    }

    let ext = {
      "url" : "https://tean.abtram.eu/fhir/extension/practitionerrole/management-mode",
      "valueCoding" : {
        "system" : "https://tean.abtram.eu/fhir/codesystem/practitionerrole/management-mode",
        "code" : "MANUAL"
      }
    }

    if( ext !== null ){
      if( foundIndex2 > -1 ){
        prJson.extension[ foundIndex2 ] = ext; // update
      } else {
        prJson.extension.push ( ext );
      }  
    }
    
    console.log( "RESULT: prJson " );
    console.log( prJson );
    
    return prJson;
  }


  /**
   * Validation rules are based on role. 
   * 
   */
  validateSaveForm(){
    
    let validationResult = {
      "hasErrors" : false,
      "mainSubmissionError" : ( this.state.isEditEmployee ? "Salvestamine ebaõnnestus" : "Töötaja lisamine ebaõnnestus" ),

      "hasMainRole" : "",
      "personalCode" : "",
      "displayName" : "",
      "contactPhone" : "",
      "healthcareWorkerCode" : "",
      "healthCareRegisters" : ""
    }

    /*
    validationResult.displayName = 'ISikukood on UNIKAALNE?!!!';
    validationResult.hasErrors = true;
    return validationResult;
    */
    
    if( this.state.formValues.displayName.trim() === "" ){
      validationResult.displayName = 'Väli pole korrektselt täidetud';
      validationResult.hasErrors = true;
    }
    
    if( this.state.formValues.healthcareWorkerCode.trim() === "" ){
      validationResult.healthcareWorkerCode = 'Väli pole korrektselt täidetud';
      validationResult.hasErrors = true;
    }
    
    if( validationResult.hasErrors ){
      return validationResult;
    }

    if( this.state.formValues.hasMainRole === "HEALTH_WORKER" ){
    }

    if( this.state.formValues.hasMainRole === "HEALTH_ANALYST" ){

    }

    if( this.state.formValues.hasMainRole === "FHC_ADMIN" ){

    }

    if( this.state.formValues.hasMainRole === "ADMIN" ){

    }

    // Remove prefix for validation
    let textPhone = this.state.formValues.contactPhone.split("+372").join("").trim();


    if( 
      this.state.formValues.hasMainRole === "ADMIN" || 
      this.state.formValues.hasMainRole === "FHC_ADMIN" || 
      this.state.formValues.hasMainRole === "HEALTH_ANALYST"
    ){
      if( this.state.formValues.personalCode.trim() === "" ){
        validationResult.personalCode = 'Väli pole korrektselt täidetud';
        validationResult.mainSubmissionError = "Isikukood on vajalik kui rolliks on valitud analüütik või admin. Vajalik autentimiseks. ";
        validationResult.hasErrors = true;
      }  
      if( textPhone === "" ){
        validationResult.contactPhone = 'Väli pole korrektselt täidetud';
        validationResult.mainSubmissionError = "Korrektne telefoni number on vajalik kui rolliks on valitud analüütik või admin. Vajalik autentimiseks. ";
        validationResult.hasErrors = true;
      }
    }

    if( this.state.formValues.personalCode !== "" ){
      const ik = new Isikukood( this.state.formValues.personalCode );
      if( !ik.validate() ){
        validationResult.personalCode = 'Väli pole korrektselt täidetud';
        validationResult.mainSubmissionError = "Isikukood ei ole korrektses formaadis. ";
        validationResult.hasErrors = true;
      }
    }

    if( textPhone !== "" ){
      let prefixMissing = ( this.state.formValues.contactPhone.substring(0,4) === "+372" ? false : true );

      if( textPhone.length < 6 || !this.isNumeric( textPhone ) || prefixMissing ){
        validationResult.contactPhone = 'Väli pole korrektselt täidetud';
        validationResult.mainSubmissionError = "Telefoni number ei ole korrektses formaadis (+372xxxxxxx). ";
        validationResult.hasErrors = true;
      }
    }

    return validationResult;
  }


  /**
   * https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
   * 
   * @param string str 
   * @returns boolean
   */
  isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }


  /**
   * 
   * @param Object service 
   * @return boolean
   */
  hasHealthCareServiceChecked( service ){
    for( var i in this.state.formValues.healthCareRegisters ){
      if( service.id === this.state.formValues.healthCareRegisters[i].id ){
        return true;
      }
    }

    return false;
  }
  
  
  render() {
    var that = this;
    
    let etaText = '---';

    let personalCodeWrapClasses = ( this.state.formErrors.personalCode !== "" ? "field-error" : "" );
    let healthcareWorkerCodeWrapClasses = ( this.state.formErrors.healthcareWorkerCode !== "" ? "field-error" : "" );
    let displayNameWrapClasses = ( this.state.formErrors.displayName !== "" ? "field-error" : "" );
    let contactPhoneWrapClasses = ( this.state.formErrors.contactPhone !== "" ? "field-error" : "" );
    
    return (
      <Grid container className="employee-form-container">

        <Grid container className="employee-form-head-container">

          <Grid item xs={12} md={6} className="externally-managed-badge-col">
            {(this.props.employeeRecord.isExternallyManaged ) && (
              <p> <AppIcon iconName="externallyManaged"  size="small"  /> MEDRE</p>
            )}
          </Grid>
          
          <Grid item xs={12} md={6} className="employee-form-actions-col">
            
            {this.state.quittingJob && (
              <Button >
                <CircularProgress size="35px" />
              </Button>
            )}
            
            {(this.state.isEditEmployee && this.props.employeeRecord.isExternallyManaged && this.props.employeeRecord.isManuallyManaged ) && !this.state.quittingJob && !this.props.employeeRecord.isCurrentUserPr && (
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleQuitJobClick( e )} >
                Lõpeta haldamine
              </Button>
            )}

            {(this.state.isEditEmployee && !this.props.employeeRecord.isExternallyManaged && this.props.employeeRecord.isManuallyManaged ) && !this.state.quittingJob && !this.props.employeeRecord.isCurrentUserPr && (
              <Button variant="outlined" className="rounded-button" onClick={(e) => that.handleQuitJobClick( e )} >
                Kustuta
              </Button>
            )}
            
            &nbsp; 

            {(this.state.hasPendingUpdates && !this.state.formClosingOrSaving ) && (
            <IconButton color="primary" component="span" onClick={(e) => that.handleSaveClick( e )} >
              <AppIcon iconName="save" size="large" />
            </IconButton>
            )}
            
            &nbsp; 

            {(!this.state.formClosingOrSaving ) && (
              <IconButton color="primary" component="span" onClick={(e) => that.handleCloseFormClick( e )} >
                <AppIcon iconName="close" size="large" />
              </IconButton>
            )}

            {(this.state.formClosingOrSaving ) && (
              <IconButton color="primary" component="span">
                <CircularProgress size="35px" />
              </IconButton>
            )}
          </Grid>
          

        </Grid>

        <Grid container className="employee-form-body-container">
        
          {( this.state.formSubmissionError !== "" ) && (
            <Alert severity="error">
              {this.state.formSubmissionError}
            </Alert>
          )}

          <Grid item xs={12} md={6} className="employee-form-body-left">
              
          <h3>Töötaja andmed</h3>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className={healthcareWorkerCodeWrapClasses}>
                  <td className="cell-label">Töötaja kood</td>
                  <td className="cell-data">
                    <TextField 
                      variant="outlined" value={this.state.formValues.healthcareWorkerCode} 
                      onChange={(event) => that.handleFieldChange( "healthcareWorkerCode", event.target.value ) }
                    />
                    {( this.state.formErrors.healthcareWorkerCode !== "" ) && (
                      <div className="field-error-text">
                        {this.state.formErrors.healthcareWorkerCode}
                      </div>
                    )}
                  </td>
                </tr>
                <tr className={displayNameWrapClasses}>
                  <td className="cell-label">Töötaja nimi</td>
                  <td className="cell-data">
                    <TextField variant="outlined" value={this.state.formValues.displayName}
                      onChange={(event) => that.handleFieldChange( "displayName", event.target.value ) }
                    />
                    {( this.state.formErrors.displayName !== "" ) && (
                      <div className="field-error-text">
                        {this.state.formErrors.displayName}
                      </div>
                    )}
                  </td>
                </tr>
                <tr className={personalCodeWrapClasses}>
                  <td className="cell-label">Isikukood (sisse logimiseks)</td>
                  <td className="cell-data">
                    {( this.props.employeeRecord.isCurrentUserPr ) && (
                      <TextField variant="outlined" disabled value={this.state.formValues.personalCode} />
                    )}
                    {( !this.props.employeeRecord.isCurrentUserPr ) && (
                      <TextField variant="outlined" value={this.state.formValues.personalCode} 
                        onChange={(event) => that.handleFieldChange( "personalCode", event.target.value ) }
                      />
                    )}
                    {( this.state.formErrors.personalCode !== "" ) && (
                      <div className="field-error-text">
                        {this.state.formErrors.personalCode}
                      </div>
                    )}
                  </td>
                </tr>
                <tr className={contactPhoneWrapClasses}>
                  <td className="cell-label">Telefoni number (sisse logimiseks)</td>
                  <td className="cell-data">
                    <TextField variant="outlined" value={this.state.formValues.contactPhone} 
                      onChange={(event) => that.handleFieldChange( "contactPhone", event.target.value ) }
                    />
                    {( this.state.formErrors.contactPhone !== "" ) && (
                      <div className="field-error-text">
                        {this.state.formErrors.contactPhone}
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="cell-label" style={{ "verticalAlign": "middle" }}>Vali töötaja roll</td>
                  <td className="cell-data">
                    <FormControl component="fieldset" disabled={this.props.employeeRecord.isCurrentUserPr}>
                      <RadioGroup value={this.state.formValues.hasMainRole} onChange={this.handleRoleChange} >
                        {roleOptions.map( function(row, index){
                          return (
                            <FormControlLabel value={row.id} control={<Radio />} label={row.label} />
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
              </tbody>
            </table>
 
          </Grid>
          <Grid item xs={12} md={6} className="employee-form-body-right">
              <h3>Nimistud</h3>
              <div>
                
                <List dense component="div" role="list">
                  {this.props.healthCareRegisterOptions.map((value, index) => {
                    const labelId = `transfer-list-item-${value.id}-label`;
                    let isChecked = that.hasHealthCareServiceChecked( value );

                    return (
                      <ListItem key={value.id} role="listitem" button onClick={that.handleToggle(value)}>
                        <ListItemIcon>
                          <Checkbox
                            checked={isChecked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.display} />
                      </ListItem>
                    );
                  })}
                  <ListItem />
                </List>


              </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

}

export default EmployeeForm;
