import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StateMachine   from '../node_modules/react-simple-state/dist/';

import { FakeResources } from './Tean/FakeResources.js';
import { Syncronizer as DomainResourceStoreSyncronizer } from './Tean/DomainResourceStore/Syncronizer.js';
import { DomainResourceStore } from './Tean/DomainResourceStore/DomainResourceStore.js';
import AuthToken  from './Tean/User/Authentication/AuthToken.js';
import AppAuthStatus  from './Tean/User/Authentication/AppAuthStatus.js';
import SessionCookie  from   './Tean/Utils/SessionCookie.js';
import WebSocketClient from './Tean/WebSocketClient';
import RenderListener from './Tean/EventListener/RenderListener';
import WebSocketListener from './Tean/EventListener/WebSocketListener';


const appState = new StateMachine();
appState.addListener( 'app-auth-status' );

window.appLoggedInUser = {
  "isLoggedIn" : false,
  "practitionerRoleId" : null
}

var sessionCookie = new SessionCookie();
var authTokenCookie = sessionCookie.readCookieValue( 'app-auth-token' );
appState.evoke( 'app-auth-status', new AppAuthStatus( false, new AuthToken() ) );
if( authTokenCookie !== null && authTokenCookie && authTokenCookie != '' ){
  var authStatus = new AppAuthStatus( true, new AuthToken( authTokenCookie ) );
  appState.evoke( 'app-auth-status', authStatus ); 
}

appState.addListener( 'connection-state' );
appState.evoke( 'connection-state', { connected: false } );


const EventEmitter = require('events');
window.appEventsEmitter = new EventEmitter();
window.appEventsEmitter.setMaxListeners( 10000 );

// setup event listeners
let renderListener = new RenderListener();
renderListener.startListening( window.appEventsEmitter );

let socketListener = new WebSocketListener();
socketListener.startListening( window.appEventsEmitter );

// wrapper for storing entities in runtime
window.appEntityStore = new DomainResourceStore();
window.appEntityStore.startListening( window.appEventsEmitter );
window.appEntityStore.setSyncronizer( new DomainResourceStoreSyncronizer() );

// let faker = new FakeResources();
// faker.init();

let sockerUrl = "wss://tean.abtram.eu/suva/wss";

if( 
  ( typeof process.env.REACT_APP_ENV !== "undefined" && process.env.REACT_APP_ENV === "staging" ) || 
  process.env.NODE_ENV === "development"
){
  sockerUrl = "wss://teantest.abtram.eu/suva/wss";
}

window.apiClient = new WebSocketClient();
window.apiClient.setEndpointUrl( sockerUrl );
window.apiClient.init( window.appEventsEmitter );

console.log( 'App version: 0.1.1 and build type: ' );
console.log( process.env );
console.log( 'WS url used: ' + sockerUrl );

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
    console.log( "get focus" );
    console.log(
      window.appEntityStore.getEntityByResourceReference( { "resReference" : "https://tean.abtram.eu/fhir/Bundle/7debd759-4326-423c-960b-3252f43f926c" } )
    );

    let XXXX = window.appEntityStore.getAllResourcesBySearchParams( {
      "resourceType" : "Bundle"
    } );
    
    for( var jj in XXXX ){
      console.log( "RESOURCES:::" + XXXX[ jj ].getId() );
      console.log( XXXX[ jj ].getContainedResourceByReferenceUrl( 'https://tean.abtram.eu/fhir/bundle/Observation/o1' ) );
    }

 * 
 * 
 * 
 */

