'use strict';

/**
 * Subscribe into render event 
 * 
 * 
 */ 

 import AuthToken from './../User/Authentication/AuthToken.js';
 import AppAuthStatus  from './../User/Authentication/AppAuthStatus.js';
 import SessionCookie  from   './../Utils/SessionCookie.js';
 import { ResourceMeta } from './../DomainResource/ResourceMeta';
 import { DomainResource } from './../DomainResource/DomainResource';
 import SubscriptionResource from './../Subscription/Subscription.js';
 import PatientDomainResource from './../Patient/Patient.js';
 import BundleParser from './../Utils/BundleParser.js';
 
 import TaskResource from './../Task/Task.js';
 
 import StateMachine from 'react-simple-state/dist';
 
 const appState = new StateMachine();


function WebSocketListener () {
  this.renderedTimer    = null;
  this.eventServerCommunicationInRaw = this.eventServerCommunicationInRaw.bind( this );
  this.eventAuthenticated = this.eventAuthenticated.bind( this );
  this.eventDeAuthenticated = this.eventDeAuthenticated.bind( this );
}


/**
 * Setup event listeners
 * 
 * @param object el
 * @return void
 */
WebSocketListener.prototype.startListening = function( el ) {
  this.eventsEmitter = el;
  el.addListener( 'ServerCommunicationInRaw', this.eventServerCommunicationInRaw );
  el.addListener( 'authenticated', this.eventAuthenticated );
  el.addListener( 'deauthenticated', this.eventDeAuthenticated );
}


/**
 * 
 * 
 * @param object eventIn
 */ 
WebSocketListener.prototype.eventServerCommunicationInRaw = function( eventIn ) {
  this.parser = new BundleParser();

  if( 
    typeof eventIn.rawJson.resourceType != 'undefined' && 
    eventIn.rawJson.resourceType == 'Bundle'
  ){
    
    // dedect logged in user afte refresh
    var foundPractitionerRoleIdString = null;
    if ( !window.appLoggedInUser.isLoggedIn && eventIn.rawJson.resourceType == "Bundle") {
      for (var m in eventIn.rawJson.entry ) {
        if( 
          typeof eventIn.rawJson.entry[ m ].request != "undefined" && 
          typeof eventIn.rawJson.entry[ m ].request.method != "undefined" && 
          typeof eventIn.rawJson.entry[ m ].request.url != "undefined" && 
          eventIn.rawJson.entry[ m ].request.method == "POST" && 
          eventIn.rawJson.entry[ m ].request.url == "Person" && 
          typeof eventIn.rawJson.entry[ m ].resource != 'undefined' &&
          eventIn.rawJson.entry[ m ].resource.resourceType == "PractitionerRole"
        ){
          foundPractitionerRoleIdString = eventIn.rawJson.entry[ m ].resource.id;
          // console.log( eventIn.rawJson.entry[ m ].resource );
          window.appLoggedInUser = {
            "isLoggedIn" : true,
            "practitionerRoleId" : foundPractitionerRoleIdString
          }
          console.log('Logged in PractitionerRole dedected: ' + foundPractitionerRoleIdString );
        }
      }
    }

    for ( var i in eventIn.rawJson.entry ){
      this.handleServerSentBundleEntry( eventIn.rawJson.entry[ i ] );
    }
    
    this.parser.handleJson(
      eventIn.rawJson, window.appEntityStore
    );
  }

  this.eventsEmitter.emit( 'ServerCommunicationHandeled', {
    'conponent' : 'WebSocketListener'
  } );
}


WebSocketListener.prototype.handleServerSentBundleEntry = function( bundleEntry ){
  let entryMeta = this.parser.extractBundleEntryMetaData( bundleEntry );

  if( typeof bundleEntry.resource == 'undefined' || typeof bundleEntry.resource.resourceType == 'undefined' ){

    // 401 informative kind ( NEEDS_CREDENTIALS )
    // 403 Forbidden NOT_AUTHORIZED
    // 408 REQUEST_TIMEOUT 

    // de-auth check entryMeta.responseCode == 408 || 403
    // wait for token login response before acting on this message
    if( 
      entryMeta.rawResponseStatusValue.indexOf( 'NOT_AUTHORIZED' ) > -1 || 
      entryMeta.rawResponseStatusValue.indexOf( 'REQUEST_TIMEOUT' ) > -1 
    ){
      let appAuthStatus = appState.getState("app-auth-status");
      if ( appAuthStatus.isAuthenticated() ) {
        // if( this.sessionTokenResultStatus < 0 ){
          // fist time and token login results are not yet in - skip
          //console.log('Skip de-auth, waiting token login results');
          //return;
        //} else {
          console.log('De-auth command received');
          this.eventsEmitter.emit( 'deauthenticated' );
          return;
        //}
      }
    }
    
    if(  entryMeta.rawResponseStatusValue.indexOf( 'NEEDS_CREDENTIALS' ) > -1  ){
      // Infomative- nothing to do. Let person login via login view
    }
  }
  
}

/**
 * 
 */
WebSocketListener.prototype.eventAuthenticated = function( eventIn ) {
  
}


/**
 * 
 */
WebSocketListener.prototype.eventDeAuthenticated = function( eventIn ) {
  appState.evoke( 'app-auth-status', new AppAuthStatus( false, new AuthToken() ) );
  
  var sessionCookie = new SessionCookie();
  sessionCookie.eraseCookie( 'app-auth-token' );
  sessionCookie.eraseCookie( 'teanSession' );
  
  window.appLoggedInUser = {
    "isLoggedIn" : false,
    "practitionerRoleId" : null
  }

  // Should actually close, but then we need some update in login coponent to bring connection up again
  // window.apiClient.closeConnection();
  window.apiClient.resetConnection();
  
  window.appEntityStore.clearAll();
  
  this.eventsEmitter.emit( 
    'entities-updated',  {
      'source' : 'self', 
      'entityTypes' : [],
      'allEntities' : true
    }
  );
  

  if( window.location.href.indexOf( 'localhost' ) < 0 ){
    // TODO: Should be removed and use build config instead
    window.apiClient.resetConnection();
  }
}


export default WebSocketListener;
