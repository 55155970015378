"use strict";

import React from "react";


class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { verificationCode } = this.props;

    if (verificationCode === "") {
      return null;
    }

    return (<h1 style={{'textAlign':'center'}}>{verificationCode}</h1>);
  }
}

export default VerificationCode;