/**
 * Represents one Task resource (DomainResource) on TEAN domain
 * 
 */

import { DomainResource } from './../DomainResource/DomainResource';


class Task extends DomainResource {
  
  /**
   * Task
   * 
   * @param ResourceId resourceId
   * @return void
   */
  constructor(
    resourceId
  ) {
    super( 'Task' );

    this._id = resourceId;
    this._statusReasonCode    = 'NEW';
    this._statusReasonDisplay = 'New task';
    this._priorityCode = 'routine';
    this._lastModified = null;
    this._communicationMethods = [];
    this._businessStatus = [];
    this._practicionerNote = '';
    this._practicionerNoteMakerReference = {
      reference : null
    };
    this._ownerReference = {
      reference : null
    };
    this._restrictionReference = {
      reference : null
    };

    this.communicationMethodSystemUrl = 'https://tean.abtram.eu/fhir/codingsystem/task-communication-method';
  }
  
  
  /**
   * Sets both statusReason and status into correct state based on 
   * given process status
   * 
   * @param string code (postpone, forward, finish, refused)
   */
   setProcessStatus( processStatus ){

    let taskCategory = this.getTicketCategoryCode();
    let currentStatus = this.getStatus();
    let currentStatusReason = this.getReasonStatusCode();

    switch(processStatus) {
      case "task-close-with-no-edits":
        this.setStatusReasonCode( 'NULL', '' );
        break;

      case "task-postpone":
        this.setStatusReasonCode( 'POSTPONED', 'Postponed' );
        this.setStatus( 'on-hold' );
        break;

      case "task-forward":
        //Suuna edasi
        //if( "HEALTH-ISSUE" == taskCategory ){
        //  this.setStatusReasonCode( 'INBOOKING', 'In booking' );
        //} else {
          this.setStatusReasonCode( 'REFERRED', 'Kinnituse ootel' );
        //}
        this.setStatus( 'on-hold' );
        break;

      case "task-forward-booking":
        // Broneeri aeg
        this.setStatusReasonCode( 'POSTPONED', 'In booking' );
        this.setStatus( 'on-hold' );
        break;

      case "task-forward-referred":
        // Registratuuri
        this.setStatusReasonCode( 'INBOOKING', 'Kinnituse ootel' );
        this.setStatus( 'on-hold' );
        break;

      case "task-finish":
        this.setStatusReasonCode( 'SOLVED', 'Lahendatud' );
        this.setStatus( 'completed' );
        break;

      case "task-refused":
        this.setStatus( 'rejected' );
        this.setStatusReasonCode( 'REFUSED', 'Pole lahendatav' );
        break;

      default:
        break;
    }
    
  }


  /**
   * Extract task status reason code
   * 
   * @return string
   */
  getReasonStatusCode() {
    let status = '';

    if( typeof  this._resourceLiteral.statusReason != 'undefined' ){
      for( var i in this._resourceLiteral.statusReason.coding ){
        status = this._resourceLiteral.statusReason.coding[ i ].code;
      }
    }
    
    return status;
  }


  /**
   * Set task status reason code
   * 
   * @param string
   * @return string
   */
  setStatusReasonCode( newCode, display ) {
    let status = '';
    
    if( typeof display == 'undefined' || display == '' || display == null ){
      display = newCode;
    }

    this._statusReasonCode    = newCode;
    this._statusReasonDisplay = display;
    
    if( this._resourceSetByLiteral ){
      
      if( typeof this._resourceLiteral.statusReason == 'undefined' ){
        this._resourceLiteral.statusReason = {
          coding : []
        };
      }
      
      this._resourceLiteral.statusReason = {
        "coding": [
          {
            "system": "https://tean.abtram.eu/fhir/codesystem/task-status-reason",
            "code": newCode,
            "display": display
          }
        ]
      }

    }
  }


  /**
   * Extract focus reference of task
   * 
   * @return string
   */
  getFocusReferenceUrl() {
    let ref = '';

    if( typeof this._resourceLiteral.focus != 'undefined' && typeof this._resourceLiteral.focus.reference != 'undefined' ){
      return this._resourceLiteral.focus.reference;
    }

    return ref;
  }


  /**
   * Extract patient reference of task
   * 
   * @return string
   */
  getPatientReferenceUrl() {
    let ref = '';

    if( typeof this._resourceLiteral.requester != 'undefined' && typeof this._resourceLiteral.requester.reference != 'undefined' ){
      return this._resourceLiteral.requester.reference;
    }

    return ref;
  }


  /**
   * Extract patient's Identifier Code (example isikukood) from task.input
   * 
   * @return string
   */
  getPatientIdentifierCodeAsString() {
    
    if( typeof this._resourceLiteral.input == 'undefined' ){
      return "";
    }

    for( var j in this._resourceLiteral.input ){
      if( 
        typeof this._resourceLiteral.input[ j ].valueIdentifier != 'undefined' && 
        typeof this._resourceLiteral.input[ j ].valueIdentifier.system != 'undefined' && 
        this._resourceLiteral.input[ j ].valueIdentifier.system == "https://tean.abtram.eu/fhir/namingsystem/personal-identifier-estonia" && 
        typeof this._resourceLiteral.input[ j ].valueIdentifier.value != 'undefined' 
      
      ){
        return this._resourceLiteral.input[ j ].valueIdentifier.value;
      }
    }
    
    return "";
  }


  /**
   * Extract patient's general practicioner data
   * 
   * @return object
   */
   getPatienGeneralPracticionerData() {
    let obj = {
      'gpDisplayName' : '',
      'gpCode' : '',
      'gpListDisplayName' : '',
      'gpListCode' : ''
    }

    if( typeof this._resourceLiteral.input == 'undefined' ){
      return obj;
    }

    
    let result = this.findValueFromInputByType( "https://tean.abtram.eu/fhir/codesystem/task/parameter", 'FAMILY_PHYSICIAN_PATIENT_LIST', 'valueReference' );
    
    if( result.codeFound && result.valueFound ){
      if(
        typeof result.originalValue != 'undefined' && 
        typeof result.originalValue.identifier != 'undefined' &&  
        typeof result.originalValue.identifier.value != 'undefined'
      ) {
        obj.gpListCode = result.originalValue.identifier.value;
      }
      if(
        typeof result.originalValue != 'undefined' && 
        typeof result.originalValue.display != 'undefined'
      ) {
        obj.gpListDisplayName = result.originalValue.display;
      }
    }
    
    let result2 = this.findValueFromInputByType( "https://tean.abtram.eu/fhir/codesystem/task/parameter", 'FAMILY_PHYSICIAN', 'valueReference' );
    
    if( result2.codeFound && result2.valueFound ){
      if(
        typeof result2.originalValue != 'undefined' && 
        typeof result2.originalValue.identifier != 'undefined' &&  
        typeof result2.originalValue.identifier.value != 'undefined'
      ) {
        obj.gpCode = result2.originalValue.identifier.value;
      }
      if(
        typeof result2.originalValue != 'undefined' && 
        typeof result2.originalValue.display != 'undefined'
      ) {
        obj.gpDisplayName = result2.originalValue.display;
      }
    }
    
    
    return obj;
  }
  
  

  /**
   * 
   * 
   * @return string
   */
  getReasonStatusDisplayText() {
    
    let status = this.getReasonStatusCode();

    if( status == 'NEW' ){
      return 'Uus';
    }
    
    if( status == 'POSTPONED' ){
      return 'Edasi lükatud';
    }
    
    if( status == 'IN-BOOKING' || status == 'INBOOKING' ){
      return 'Broneeringu ootel';
    }
    
    if( status == 'REFERRED' ){
      return 'Kinnituse ootel';
    }

    // If task marked complete by health analyst
    if( status == 'SOLVED' ){
      return 'Lahendatud';
    }
    
    if( status == 'REJECTED' ){
      return 'Tagastatud';
    }
    
    if( status == 'REFUSED' ){
      return 'Pole lahendatav';
    }
    
    // Other statuses from background processes 
    // statuses that are not giveable by this app health analyst

    // If task cancelled by patient
    if( status == 'CANCELLED' ){
      return 'Patsient tühistas';
    }

    // If task marked complete by doctor
    if( status == 'COMPLETED' ){
      return 'Tehtud';
    }

    return status;
  }

  
  /**
   * -
   * 
   * @param string code 
   */
  setPriorityCode( code ){
    this._priorityCode = code;
    
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.priority = code;
    }
  }


  /**
   * 
   * 
   * @return string
   */
  getPriorityCode() {
    return this._priorityCode;
  }


  /**
   * 
   * 
   * @return string
   */
   getEncounterNumber() {
    
    //let encounterNumber = literal.encounter.identifier.value;
    
    if( !this._resourceSetByLiteral ){
      return '';
    }

    if( 
      typeof this._resourceLiteral.encounter != 'undefined' && 
      typeof this._resourceLiteral.encounter.identifier != 'undefined' && 
      typeof this._resourceLiteral.encounter.identifier.value != 'undefined'
    ){
      return this._resourceLiteral.encounter.identifier.value;
    }

    return '';
  }


  


  /**
   * Returnes preferred priority orginally set for tesk/ticekt with value od following
   *  - routine
   *  - urgent
   *  - stat
   *  - asap 
   * 
   * @return object Containgin both - priorityNumber and priorityCode
   */
  getPreferredPriority() {
    
    if( typeof this._resourceLiteral.input == 'undefined' ){
      return "";
    }

    let result = this.findValueFromInputByType( 'https://tean.abtram.eu/fhir/codesystem/task/parameter', 'ORIGINAL_PRIORITY', 'valueCode' );
    
    if( result.codeFound && result.valueFound ){
      
      let priorities = this.getPossiblePriorities();
      let priorityNumber = "";
      for( let i in priorities ){
        if( result.originalValue == priorities[ i ].code ){
          priorityNumber = priorities[ i ].priorityNumber;
        }
      }

      return {
        "priorityCode" : result.originalValue,
        "priorityNumber" : priorityNumber
      };
    }
    
    return {
      "priorityCode" : "",
      "priorityNumber" : "0"
    };
  }

  /**
   * 
   * 
   * @param string system 
   * @param string code 
   * @param string returnValueType 
   * @returns object
   */
  findValueFromInputByType( system, code, returnValueType ){
    let result = {
      "codeFound" : false,
      "valueFound" : false,
      "originalValue" : null,
      "value" : null // modified if requested
    };
    
    if( typeof this._resourceLiteral.input == 'undefined' ){
      return result;
    }
    
    let ref = null;

    for( var j in this._resourceLiteral.input ){
      let typeFound = false;
      if( 
        typeof this._resourceLiteral.input[ j ].type != 'undefined' && 
        typeof this._resourceLiteral.input[ j ].type.coding != 'undefined'
      ){
        for( var k in this._resourceLiteral.input[ j ].type.coding ){
          if(
            (
              system != null && 
              code != null && 
              this._resourceLiteral.input[ j ].type.coding[ k ].system == system && 
              this._resourceLiteral.input[ j ].type.coding[ k ].code == code 
            ) || (
              system == null && 
              code != null && 
              this._resourceLiteral.input[ j ].type.coding[ k ].code == code 
            )
          ){
            typeFound = true;
          }
          
        }
      }
      
      if( typeFound ){
        result.codeFound = true;
        ref = this._resourceLiteral.input[ j ];
      }
    }

    if( !result.codeFound ){
      return result;
    }
    
    if( returnValueType == 'valueString' && typeof ref.valueString != 'undefined' ){
      result.valueFound = true;
      result.originalValue = ref.valueString;
      result.value = ref.valueString;
    }
    
    if( returnValueType == 'valueCode' && typeof ref.valueCode != 'undefined' ){
      result.valueFound = true;
      result.originalValue = ref.valueCode;
      result.value = ref.valueCode;
    }

    if( returnValueType == 'valueCoding' && typeof ref.valueCoding != 'undefined' ){
      result.valueFound = true;
      result.originalValue = ref.valueCoding;
      result.value = ref.valueCoding;
    }

    if( returnValueType == 'valueReference' && typeof ref.valueReference != 'undefined' ){
      result.valueFound = true;
      result.originalValue = ref.valueReference;
      result.value = ref.valueReference;
    }
    
    return result;
  }

  
  /**
   * Remove owner (who was editing the ticket) 
   * Usually we need to restore previous status if closed 
   * without settings new status explicitly via  (this.setProcessStatus). 
   * 
   * @param newStatus If set - set status
   * @return void
   */
  removeOwner( newStatus ){
    this._ownerReference.reference = null;
    
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.owner = {};
    }

    if( typeof newStatus != 'undefined' && newStatus !== null && newStatus !== '' ){
      this.setStatus( newStatus );
    }
  }

  
  /**
   * -
   * 
   * @param object PractitionerRole Resource
   */
   setOwnerByPractitionerRoleResource( prResource ){

    let id = prResource.getId();
    let resourceType = prResource.getResourceType();
    let obj = prResource.toObject();
    
    this._ownerReference.reference = {
      "reference": "https://tean.abtram.eu/fhir/bundle/" + resourceType + "/" + id.getResourceId(),
      "display": obj.practitioner.display
    };

    if( this._resourceSetByLiteral ){
      this._resourceLiteral.owner = {
        "reference": "https://tean.abtram.eu/fhir/bundle/" + resourceType + "/" + id.getResourceId(),
        "display": obj.practitioner.display
      };
    }

    this.setStatus( 'in-progress' );
  }

    
  /**
   * 
   * 
   * return object
   */
  getLockedStatus(){

    let lockedStatus = {
      'isLocked' : false,
      'lockedToDisplayName' : ''
    };
    
    if( this._ownerReference.reference !== null ){
      lockedStatus = {
        'isLocked' : true,
        'lockedToDisplayName' : this._ownerReference.reference.display
      }
    }

    return lockedStatus;
  }


  /**
   * -
   * 
   * @param object PractitionerRole Resource
   */
   setRestrictionByPractitionerRoleResource( prResource ){
    let id = prResource.getId();
    let resourceType = prResource.getResourceType();
    let obj = prResource.toObject();
    
    this._restrictionReference.reference = {
      "reference": "https://tean.abtram.eu/fhir/" + resourceType + "/" + id.getResourceId(),
      "display": obj.practitioner.display
    };

    if( this._resourceSetByLiteral ){
      if( typeof this._resourceLiteral.restriction == 'undefined' ){
        this._resourceLiteral.restriction = {};
      }
      this._resourceLiteral.restriction.recipient = [{
        "reference": "https://tean.abtram.eu/fhir/" + resourceType + "/" + id.getResourceId(),
        "display": obj.practitioner.display
      }];
    }
  }


  /**
   * 
   * 
   * return object
   */
  getRestrictionReference(){
    if( this._restrictionReference.reference !== null ){
      return this._restrictionReference.reference;
    }

    return null;
  }

  
  removeRestrictionPractitionerRole(){
    this._restrictionReference.reference = null;
    

    if( this._resourceSetByLiteral ){
      this._resourceLiteral.restriction = {};
    }

  }
  

  /**
   * Replace all communication methods via array with keys like
   * [ 
   *   'email', 
   *   'phone'
   * ]
   * 
   * @param array methods 
   */
  replaceCommunicationMethods( methods ){
    this._communicationMethods = methods;
    
    if( this._resourceSetByLiteral ){
      if( typeof this._resourceLiteral.output == 'undefined' ){
        this._resourceLiteral.output = [];
      }
      
      this.removeOutputItemsFromLiteralByCodeSystemUrl( this.communicationMethodSystemUrl );
      this._resourceLiteral.output = this._resourceLiteral.output.filter(function(){return true;});

      for( let jj in this._communicationMethods ){
        let method = this._communicationMethods[ jj ];
        
        this._resourceLiteral.output.push( {
          "type": {
            "coding": [
              {
                "system": this.communicationMethodSystemUrl,
                "code": method
              }
            ]
          },
          "valueCode": method
        } );
      }
    }
  }


  /**
   * Remove output items by coding system
   * 
   * @param void 
   */
   removeOutputItemsFromLiteralByCodeSystemUrl( url ){
    
    if( typeof this._resourceLiteral.output != 'undefined' ){
      for( let i in this._resourceLiteral.output ){
        
        let outputItem = this._resourceLiteral.output[i];

        if( 
          typeof outputItem.type != 'undefined' && 
          typeof outputItem.type.coding != 'undefined' && 
          typeof outputItem.type.coding[ 0 ] != 'undefined' && 
          typeof outputItem.type.coding[ 0 ].system != 'undefined' && 
          outputItem.type.coding[ 0 ].system == url
        ){
          delete this._resourceLiteral.output[i];
          // this._resourceLiteral.output.splice( i, 1);
        }
        
      }
    }
  }


  /**
   * 
   * 
   * @return array
   */
  getCommunicationMethods() {
    return this._communicationMethods;
  }


  /**
   * 
   * 
   * @return string
   */
  getCommunicationMethodPreferred() {
    let result = this.findValueFromInputByType( null, 'RECOMMENDED_CHANNEL', 'valueCoding' );
    
    if( result.codeFound && result.valueFound ){
      if(
        typeof result.originalValue.code
      ) {
        return result.originalValue.code.toLowerCase();
      }
    }
    
    return "";
  }


  /**
   * 
   * 
   * @return string
   */
   getWebBookingUrl() {
    let result = this.findValueFromInputByType( 'https://tean.abtram.eu/fhir/codesystem/task/parameter', 'BOOKING_WEB_URL', 'valueString' );
    if( result.codeFound && result.valueFound ){
      return result.originalValue;
    }

    return null;
  }


  /**
   * -
   * 
   * @return string
   */
  getFirstPatientNoteAsString(){
    if( typeof  this._resourceLiteral.note != 'undefined' ){
      for( let i in this._resourceLiteral.note ){
        
        let authorReference = '';
        if(
          typeof this._resourceLiteral.note[ i ].authorReference != 'undefined' && 
          typeof this._resourceLiteral.note[ i ].authorReference.reference != 'undefined'
        ){
          authorReference = this._resourceLiteral.note[ i ].authorReference.reference;
        }
        
        if( authorReference.indexOf( 'Patient/' ) > -1 ){
          return this._resourceLiteral.note[ i ].text;
        }
      }
    }

    return '';
  }


  /**
   * Overwrite previous medical worker note during 
   * update (if exists)
   * 
   * @param string content 
   * @param DomainResource prResource 
   * @param string dateTime In format "Y-m-dTH:i:s+00:00" like "2021-08-03T22:22:59+03:00" 
   * @return void
   */
   setPracticionerNoteByPracticionerRole( note, prResource, dateTime ){
    let id = prResource.getId();
    let resourceType = prResource.getResourceType();
    let obj = prResource.toObject();

    this._practicionerNote = note;
    this._practicionerNoteMakerReference.reference = {
      "reference": "https://tean.abtram.eu/fhir/" + resourceType + "/" + id.getResourceId(),
      "display": obj.practitioner.display
    }
    
    if( this._resourceSetByLiteral ){
      if( typeof this._resourceLiteral.note == 'undefined' ){
        this._resourceLiteral.note = [];
      }

      let foundIndex = -1;
      for( var i in this._resourceLiteral.note ){
        
        let authorReference = '';
        if(
          typeof this._resourceLiteral.note[ i ].authorReference != 'undefined' && 
          typeof this._resourceLiteral.note[ i ].authorReference.reference != 'undefined'
        ){
          authorReference = this._resourceLiteral.note[ i ].authorReference.reference;
        }

        if( authorReference.indexOf( 'PractitionerRole/' ) > -1 ){
          foundIndex = i;
        }
      }
      
      let noteRecord = {
        "authorReference": this._practicionerNoteMakerReference.reference,
        "time": dateTime,
        "text": note
      };
      
      if( foundIndex > -1 ){
        this._resourceLiteral.note[ foundIndex ] = noteRecord;
      } else {
        this._resourceLiteral.note.push( noteRecord );
      }
    }
  }


  /**
   * -
   * 
   * @return string
   */
  getFirstPracticionerNoteAsString(){
    if( typeof  this._resourceLiteral.note != 'undefined' ){
      for( let i in this._resourceLiteral.note ){
        let authorReference = '';
        if(
          typeof this._resourceLiteral.note[ i ].authorReference != 'undefined' && 
          typeof this._resourceLiteral.note[ i ].authorReference.reference != 'undefined'
        ){
          authorReference = this._resourceLiteral.note[ i ].authorReference.reference;
        }

        if( authorReference.indexOf( 'PractitionerRole/' ) > -1 ){
          if( typeof this._resourceLiteral.note[ i ].text != 'undefined' ){
            return this._resourceLiteral.note[ i ].text;
          }
        }
      }
    }

    return '';
  }


  /**
   * 
   * @param string dateTime In format "Y-m-dTH:i:s+00:00" like "2021-08-03T22:22:59+03:00" 
   * @return void
   */
  setModifiedDate( dateTime ){
    this._lastModified = dateTime;
    
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.lastModified = dateTime;
    }
  }


  /**
   * 
   * 
   * @return string
   */
  getModifiedDate() {
    return this._lastModified;
  }


  /**
   * 
    if( typeof .start != 'undefined' && executionPeriod.start ){
      
    }
   * 
   * @return string dateTime In format "Y-m-dTH:i:s+00:00" like "2021-08-03T22:22:59+03:00" 
   */
  getExecutionPeriodStart() {
    if( 
      typeof this._resourceLiteral != 'undefined' && 
      typeof this._resourceLiteral.executionPeriod != 'undefined' && 
      typeof this._resourceLiteral.executionPeriod.start != 'undefined'
    ){
      return this._resourceLiteral.executionPeriod.start;
    }
    return null;
  }
  
  
  /**
   * -
   * 
   * @return string
   */
  getTicketCategoryCode(){
    if( 
      typeof this._resourceLiteral.code != 'undefined' && 
      typeof this._resourceLiteral.code.coding != 'undefined'
    ){
      for( var i in this._resourceLiteral.code.coding ){
        let codeSystem = this._resourceLiteral.code.coding[ i ].system;
        if( codeSystem == "https://tean.abtram.eu/fhir/codingsystem/ticket-category" ){
          return this._resourceLiteral.code.coding[ i ].code;
        }
      }
    }

    return '';
  }
  
  
  /**
   * -
   * 
   * @return string
   */
  getTicketCategoryAsString(){
    if( 
      typeof this._resourceLiteral.code != 'undefined' && 
      typeof this._resourceLiteral.code.coding != 'undefined'
    ){
      for( var i in this._resourceLiteral.code.coding ){
        let codeSystem = this._resourceLiteral.code.coding[ i ].system;
        if( codeSystem == "https://tean.abtram.eu/fhir/codingsystem/ticket-category" ){
          return this._resourceLiteral.code.coding[ i ].display;
        }
      }
    }

    return '';
  }

  createAdditionalInfo(){
    var content = '';
    let taskCategory = this.getTicketCategoryCode();

    if( 
      typeof this._resourceLiteral.reasonCode != 'undefined' && 
      typeof this._resourceLiteral.reasonCode.coding != 'undefined'
    ){
      for( var i in this._resourceLiteral.reasonCode.coding ){
        let comma = content.length > 0 ? ', ' : '';
        if( "HEALTH-ISSUE" == taskCategory ){
          content += comma + this._resourceLiteral.reasonCode.coding[ i ].code + ' - ' + this._resourceLiteral.reasonCode.coding[ i ].display;
        } else {
          content += comma + this._resourceLiteral.reasonCode.coding[ i ].display;
        }
      }
    }

    return content ? content : '-';
  }


  /**
   * -
   * 
   * @return string
   */
  getAuthoredOnDate(){
    if( 
      typeof this._resourceLiteral.authoredOn != 'undefined' 
    ){
      return this._resourceLiteral.authoredOn;
    }

    return null;
  }


  /**
   * -
   * 
   * @return string
   */
  getExecutionPeriodEndDate(){
    if( 
      typeof this._resourceLiteral.executionPeriod != 'undefined' && 
      typeof this._resourceLiteral.executionPeriod.end != 'undefined'
    ){
      return this._resourceLiteral.executionPeriod.end;
    }

    return null;
    //return new Date().toLocaleDateString( 'de-DE' );
  }


  validatePendingUpdate( pendingUpdates ){
    
    let resultObj = {
      isError: false,
      message: ''
    }
    
    if( 
      pendingUpdates.processCommand == 'task-forward' || 
      pendingUpdates.processCommand == 'task-forward-booking' || 
      pendingUpdates.processCommand == 'task-forward-referred'
    ){

      if(
        typeof pendingUpdates.restrictionPracticionerRoleId == 'undefined'
      ) {
        if( this.getRestrictionReference() == null ){
          resultObj.isError = true;
          resultObj.message = 'Edasi suunamisel on vaja valida töötaja kellele suunatakse. ';
        }
      } else {
        if( pendingUpdates.restrictionPracticionerRoleId === null ){
          resultObj.isError = true;
          resultObj.message = 'Edasi suunamisel on vaja valida töötaja kellele suunatakse. ';
        }
      }

    }
    
    return resultObj;
  }


  /**
   * 
   * 
   */
  getPossiblePriorities() {
    let list = [];
    
    list.push( {
      'code' : 'routine',
      'label' : 'Roheline',
      'priorityNumber' : 4
    } );
  
    list.push( {
      'code' : 'urgent',
      'label' : 'Kollane',
      'priorityNumber' : 3
    } );
    
    list.push( {
      'code' : 'asap',
      'label' : 'Oranž',
      'priorityNumber' : 2
    } );
  
    list.push( {
      'code' : 'stat',
      'label' : 'Punane',
      'priorityNumber' : 1
    } );
  
    return list;
  }


  /**
   * Return numeric value of the task priority (for sorting mainly)
   * 
   * @return 
   */
  getPriorityNumber(){
    let priorityCode = this.getPriorityCode();
    let priorities = this.getPossiblePriorities();

    for( let i in this.getPossiblePriorities() ){
      if( priorityCode == priorities[ i ].code ){
        return priorities[ i ].priorityNumber;
      }
    }

    return 0;
  }


  /**
   * Overwrite parent method 
   * 
   * Set local variables based on input JSON
   * 
   * @param object json
   * @return void
   */
  setRawResourceObject( json ) {
    super.setRawResourceObject( json );
    
    if( typeof json.priority != 'undefined' ){
      this._priorityCode = json.priority;
    }

    if( typeof json.lastModified != 'undefined' ){
      this._lastModified = json.lastModified;
    }
    
    if( typeof json.output != 'undefined' ){
      for( let i in json.output ){
        
        let outputItem = json.output[i];

        if( 
          typeof outputItem.type != 'undefined' && 
          typeof outputItem.type.coding != 'undefined' && 
          typeof outputItem.type.coding[ 0 ] != 'undefined' && 
          typeof outputItem.type.coding[ 0 ].system != 'undefined' && 
          outputItem.type.coding[ 0 ].system == this.communicationMethodSystemUrl
        ){
          this._communicationMethods.push( outputItem.type.coding[ 0 ].code );
        }
        
      }
    }

    if( typeof json.statusReason != 'undefined' && typeof json.statusReason.coding != 'undefined' ){
      
      for( let i in json.statusReason.coding ){
        
        let item = json.statusReason.coding[i];

        if( 
          typeof item.system != 'undefined' && 
          typeof item.code != 'undefined'
        ){
          this._statusReasonCode    = item.code;
          this._statusReasonDisplay = item.code;
          if( typeof item.display != 'undefined' ){
            this._statusReasonDisplay = item.display;
          }
        }
        
      }
    }

    if( typeof json.owner != 'undefined' && typeof json.owner.reference != 'undefined' ){
      this._ownerReference.reference = {
        "reference": json.owner.reference,
        "display": json.owner.display
      }
    }

    if( 
      typeof json.restriction != 'undefined' && 
      typeof json.restriction.recipient != 'undefined' && 
      typeof json.restriction.recipient[0] != 'undefined'
    ){
      this._restrictionReference.reference = {
        "reference": json.restriction.recipient[0].reference,
        "display": json.restriction.recipient[0].display
      }
    }


  }
  

  /**
   * Overwrite parent method 
   * 
   * Build basic resource literal
   * This needs to be overwritten by actual resource
   * 
   * @return object
   */
  buidResourceLiteral() {
    super.buidResourceLiteral();

    //Dont build- it's already as json
    if( this._resourceSetByLiteral ){
      return;
    }
    
    console.log( 'Method buidResourceLiteral not implemented for Task resource' );
  }

}


export default Task;
