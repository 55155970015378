
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppIcon from './../AppIcon';




class SubscriptionsTable extends React.Component {

  constructor( props ) {
    super( props );
    
    this.handleSubscriptionClick = this.handleSubscriptionClick.bind( this );
  }


  componentDidMount() {
    // 
  }


  handleSubscriptionClick ( event, ticket ){
    if( event != null ){
      event.preventDefault();
    }
    
    if( typeof this.props.onTicketclick != "undefined" ){
      this.props.onTicketclick( ticket );
    }
  }


  render() {
    var that = this;

    return (
      <TableContainer component={Paper}>
        
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tickets-table-header" align="left">Selgitus</TableCell>
              <TableCell className="tickets-table-header" align="left">Prioriteedid</TableCell>
              <TableCell className="tickets-table-header" align="left">Nimistud</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.subscriptions.map( function(row, index){
              
              let debugMessage = "\n";
              

              return (
                <TableRow key={row.subscriptionId} className="tickets-table-row" onClick={(e) => that.handleSubscriptionClick( e, row )} >
                  <TableCell align="left" label={debugMessage} title={debugMessage}>{row.reasonText}</TableCell>
                  <TableCell align="left">{row.prioritydisplay}</TableCell>
                  <TableCell align="left">{row.registriesDisplay}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </TableContainer>
    );
  }

}

export default SubscriptionsTable;
