'use strict';
/**
 * 
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


class ContentLoading extends React.Component {

  constructor( props ) {
    super( props );
  }

  componentDidMount() {
    window.appEventsEmitter.emit( 'rendered', { "component" : "ContentLoading" } );
  }

  render() {

    return (
      <div className="container-loading">
        <CircularProgress />
        <div className="message">{this.props.message}</div>
      </div>
    );
  }

}

export default ContentLoading; 