'use strict';

/**
 * Value object defining contact like phone or email
 * 
 * @see: 
 *   https://www.hl7.org/fhir/datatypes.html#ContactPoint
 * 
 * @param string contactValue Like phone number or e-mail address
 * @param string system Is it phone, fax, email, pager, url, sms or other
 * @param string useCode Is it home, work, temp, old or mobile
 * @return void
 */
class ContactPoint {

  constructor(
    value : string,
    systemCode : string,
    useCode: string
  ) {
    
    if( !this._validSystemCode( systemCode ) ){
      throw new Error( 'Contact type code is not defined correctly' );
    }
    
    if( typeof useCode != 'undefined' && useCode != null ){
      if( !this._validUseCode( useCode ) ){
        throw new Error( 'Contact use code is not defined correctly' );
      }
    } else {
      useCode = null;
    }

    this.contactValue      = value;
    this.contactSystemCode = systemCode;
    this.contactUse        = useCode;
  }


  /**
   * 
   * 
   * @return string
   */
  getValue(){
    return this.contactValue;
  }


  /**
   * 
   * @return string
   */
  getSystemCode(){
    return this.contactSystemCode;
  }


  /**
   * 
   * 
   * @return string
   */
  getUseCode(){
    return this.contactUse;
  }


  /**
   * 
   * 
   * @param ContactPoint test 
   * @return boolean
   */
  equals( test : ContactPoint ){
    if(
      test.getSystemCode() == this.getSystemCode() &&
      test.getUseCode() == this.getUseCode() &&
      test.getValue() == this.getValue()
    ){
      return true;
    }
    return false;
  }


  /**
   * 
   * 
   * @return object
   */
  toObject() {
    let obj = {};

    if( this.getSystemCode() != null ){
      obj.system = this.getSystemCode();
    }

    if( this.getUseCode() != null ){
      obj.use = this.getUseCode();
    }

    if( this.getValue() != null ){
      obj.value = this.getValue();
    }

    return obj;
  }
  


  /**
   * 
   * 
   * @param string code
   * @return boolean 
   */
  _validSystemCode( code ){
    
    var validTypes = [ 'phone', 'fax', 'email', 'pager', 'url', 'sms', 'other' ];

    for( var i in validTypes ){
      if( validTypes[ i ] == code ){
        return true;
      }
    }

    return false;
  }


  /**
   * 
   * 
   * @param string code
   * @return boolean 
   */
  _validUseCode( code ){
    
    var validTypes = [ 'home', 'work', 'temp', 'old', 'mobile' ];

    for( var i in validTypes ){
      if( validTypes[ i ] == code ){
        return true;
      }
    }

    return false;
  }
  
}


export { ContactPoint };