/**
 * Base class to represent FHIR resource like 
 *   - Patient
 *   - Task
 *   - Encounter
 *   - 
 * See all possible types @ https://www.hl7.org/fhir/resourcelist.html 
 * 
 * To be used in Documental context. Defines some common methods and mandatory  
 * parameter like resourceType. 
 * 
 * @see
 *   https://www.hl7.org/fhir/resource.html
 *   https://www.hl7.org/fhir/domainresource.html
 * 
 */

import { ResourceMeta } from './ResourceMeta.js';
import { ResourceIdFactory } from './ResourceIdFactory.js';


class DomainResource {

  /**
   * 
   * @param string resourceType 
   * @return void
   */
  constructor( resourceType ) {
    this._resourceType = resourceType;
    this._id = null;
    this._status = null;
    this._resourceMeta = new ResourceMeta();
    this._resourceSetByLiteral = false;
    this._resourceLiteral = {
      "resourceType": resourceType
    };
  }

  /**
   * 
   * @param ResourceId resourceId
   * @return boolean
   */
  setId( id ) {
    this._id = id;
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.id = id.getResourceId();
    }
    return true;
  }

  /**
   * TODO: add cloning here to remove all references to _resourceLiteral
   * 
   * @param object json
   * @return boolean
   */
  setRawResourceObject( json ) {
    this._resourceLiteral = json;
    this._resourceSetByLiteral = true;

    if( typeof json.id != 'undefined' ){
      let factory = new ResourceIdFactory();
      let id = factory.createFromRawResourceObject( json );
      this._id = json.id;
    }

    if( typeof json.meta != 'undefined' ){
      let meta = new ResourceMeta();
      meta.setFromObject( json.meta );
      this._resourceMeta = meta;
    }
    
    if( typeof json.status != 'undefined' ){
      this._status = json.status;
    }
    return true;
  }

  /**
   * 
   * @return string
   */
  getId() {
    return this._id;
  }

  /**
   * 
   * @return string
   */
  getResourceId() {
    return this._id.getResourceId();
  }
  
  
  /**
   * 
   * @param string newStatus
   * @return boolean
   */
  setStatus( newStatus ) {
    this._status = newStatus;
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.status = newStatus;
    }
    return true;
  }


  /**
   * If set resource.status string is set- return it
   * 
   * @return string
   */
  getStatus() {
    return this._status;
  }

  
  /**
   * 
   * @return string
   */
  getResourceType() {
    return this._resourceType;
  }

  
  /**
   * 
   * @param ResourceMeta resourceMeta
   * @return void
   */
  setResourceMeta( resourceMeta ) {
    this._resourceMeta = resourceMeta;
    if( this._resourceSetByLiteral ){
      this._resourceLiteral.meta = resourceMeta.toObject();
    }
  }

  
  /**
   * 
   * @return ResourceMeta
   */
  getResourceMeta() {
    return this._resourceMeta;
  }

  
  /**
   * Extract subjct patient ID from resource's subject field
   * 
   * @return string
   */
  getSubjectPatientId() {
    let json = this.toObject();
    if( typeof json.subject == 'undefined' || typeof json.subject.reference == 'undefined' ){
      return '';
    }
    
    let parts = json.subject.reference.split( '/' );
    if( typeof parts[ 0 ] != 'undefined' || parts[ 0 ] == 'Patient' ){
      return parts[ 1 ];
    }
    
    return '';
  }
  
  
  /**
   * TODO: add cloning here to remove all references to _resourceLiteral
   * 
   * @return object
   */
  toObject() {
    this.buidResourceLiteral();
    return this._resourceLiteral;
  }

  
  /**
   * Build basic resource literal
   * This needs to be overwritten by actual resource
   * 
   * @return object
   */
  buidResourceLiteral() {
    //Dont build- it's already json and set by client code
    if( this._resourceSetByLiteral ){
      return;
    }

    this._resourceLiteral = {
      "resourceType": this.getResourceType(),
      "id" : this.getResourceId(),
      "meta" : this.getResourceMeta().toObject()
    };
    
    if( this.getStatus() != null ){
      this._resourceLiteral.status = this.getStatus();
    }
  }
  
}


export { DomainResource };